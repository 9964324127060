.file-upload-wrapper-materi {
    $defaultColor: #F8CA2B;
    margin-left: 3rem;
    $height: 60px;
    position: relative;
    width: 100%;
    height: $height;

    // &:after {
    //     content: attr(data-text);
    //     font-size: 18px;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     background: #fff;
    //     padding: 10px 15px;
    //     display: block;
    //     width: calc(100% - 40px);
    //     pointer-events: none;
    //     z-index: 20;
    //     height: $height - 20px;
    //     line-height: $height - 20px;
    //     color: #999;
    //     border-radius: 5px 10px 10px 5px;
    //     font-weight: 300;
    // }

    &:before {
        content: 'Upload Materi';
        position: absolute;
        top: 0;
        left: -42px;
        display: inline-block;
        height: 60px;
        width: 8.3rem;
        background: $defaultColor;
        color: #fff;
        font-weight: 700;
        z-index: 25;
        font-size: 16px;
        line-height: $height;
        padding: 0 15px;
        // text-transform: uppercase;
        pointer-events: none;
        border-radius: 5px 0px 0px 5px;
    }

    &:hover {
        &:before {
            background: darken($defaultColor, 10%);
        }
    }

    input {
        // opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // z-index: 99;
        z-index: 21;
        height: $height - 20px;
        margin: 0;
        padding: 1rem;
        display: block;
        cursor: pointer;
        width: 100%;
    }
}

.file-upload-wrapper-video {
    $defaultColor: #F8CA2B;
    margin-left: 3rem;
    $height: 60px;
    position: relative;
    width: 100%;
    height: $height;

    // &:after {
    //     content: attr(data-text);
    //     font-size: 18px;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     background: #fff;
    //     padding: 10px 15px;
    //     display: block;
    //     width: calc(100% - 40px);
    //     pointer-events: none;
    //     z-index: 20;
    //     height: $height - 20px;
    //     line-height: $height - 20px;
    //     color: #999;
    //     border-radius: 5px 10px 10px 5px;
    //     font-weight: 300;
    // }

    &:before {
        content: 'Upload Video';
        position: absolute;
        top: 0;
        left: -42px;
        display: inline-block;
        height: 60px;
        width: 8.3rem;
        background: $defaultColor;
        color: #fff;
        font-weight: 700;
        z-index: 25;
        font-size: 16px;
        line-height: $height;
        padding: 0 15px;
        // text-transform: uppercase;
        pointer-events: none;
        border-radius: 5px 0px 0px 5px;
    }

    &:hover {
        &:before {
            background: darken($defaultColor, 10%);
        }
    }

    input {
        // opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // z-index: 99;
        z-index: 21;
        height: $height - 20px;
        margin: 0;
        padding: 1rem;
        display: block;
        cursor: pointer;
        width: 100%;
    }
}