body {
  margin: 0;
  font-family: 'Baloo Chettan 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.bg-linear {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
  background-size: cover;
}

.bg-linear-footer {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
  background-size: cover;
}

.bg-linear-footer2 {
  background: linear-gradient(180deg, #ffcc00 0%, #ffcc00 100%);
  background-size: cover;
}

.bg-linear-footer-blue {
  background: linear-gradient(180deg, #83D0CF 0%, #83D0CF 100%);
  background-size: cover;
}

.bg-linear-footer-blue2 {
  background: linear-gradient(180deg, #1E7EC0 0%, #1E7EC0 100%);
  background-size: cover;
}

.bg-linear-footer-green {
  background: linear-gradient(180deg, #1EA54A 0%, #1EA54A 100%);
  background-size: cover;
}

.bg-linear-footer-purple {
  background: linear-gradient(180deg, #B954A0 0%, #B954A0 100%);
  background-size: cover;
}

.bg-linear-footer-pink {
  background: linear-gradient(180deg, #E54081 0%, #E54081 100%);
  background-size: cover;
}


/* General Container Style */

.vertical-middle {
  z-index: 3;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  flex-flow: column;
}

/* HERO SECTION */

.wave-hero-upper {
  position: absolute;
  top: 0px;
  left: 0px;
}

.wave-hero-lower {
  display: flex;
  align-self: flex-end;
}

.hero-section {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 5vh;
  position: relative;
  z-index: 1000;
  max-width: 80vw !important;
}

#hero-section-container {
  padding: 0px !important;
}

.svg-container-upper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.svg-container-lower {
  width: 100%;
  position: relative;
  z-index: 1;
}

/* ABOUT SECTION */

#about-section-container {
  padding: 0px !important;
}

.svg-container {
  width: 100%;
  position: relative;
  z-index: 3;
}

.circles-about {
  position: absolute;
  top: 0px;
  left: 0px;
}

.about-section {
  padding-top: 5rem;
  position: relative;
  z-index: 2002;
  max-width: 80vw !important;
}

.aboutcard-icon-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(180deg, #F8CA2B 50%, #FCA61F 100%);
  width: 4;
  border-radius: 15%;
  position: absolute;
  top: -3rem;
  left: 50%;
  margin-left: -47px;
  padding: 16px;
}

.aboutcard-text {
  font-family: 'Quicksand' !important;
  font-weight: 600;
  padding: 25% 10%;
  text-align: center;
  color: black;
}

.about-text_testimoni {
  font-family: 'Quicksand' !important;
  color: white;
  line-height: 2.5rem;
}

.zoom {
  transition: transform .2s;
  /* Animation */
  margin: 0 auto;
  border: 1px solid white !important;
  border-radius: 20px !important;
}

.zoom:hover {
  border: 5px solid #FCA61F !important;
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
  margin-right: 15%;
  margin-left: 15%;
}

.react-player {
  position: absolute;
  top: 0;
  left: -15%;
}

.testimoni-player {
  position: absolute;
  border-radius: 25px;
  top: 0;
  left: -15%;
  overflow: hidden;
  border: 4px solid black;
  box-shadow: 24px 20px #F8CA28, inset 16px 10px rgba(0, 0, 0, .75);
}

.react-player.pristine::before {
  content: '';
  position: absolute;
  top: 35%;
  left: 50%;
  margin-top: -15px;
  margin-left: -25px;
  height: 0;
  width: 0;
  border-left: 50px solid white;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  transition: opacity .2s ease;
}

.react-player.pristine:hover::before {
  opacity: .7;
}

.hero-carousel-wrapper {
  position: relative;
  width: 100%;
  z-index: 1000;
}

@media screen and (min-width: 320px) {
  .hero-carousel-best {
    width: 7em;
    position: absolute;
    top: 30%;
    left: -2.5%;
  }

  .hero-carousel-icon {
    width: 7em;
    position: absolute;
    top: -1.75%;
    right: 20%;
  }

  .hero-slider {
    justify-content: center;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-bottom: 10rem;
    padding-top: 1rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0.4rem;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 3px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0.3rem;
    color: #FCA61F;
  }
}

@media screen and (min-width: 720px) {
  .hero-carousel-best {
    width: 8em;
    position: absolute;
    top: 50%;
    left: -1%;
  }

  .hero-carousel-icon {
    width: 8em;
    position: absolute;
    top: -1%;
    right: 20%;
  }

  .hero-slider {
    padding: 1.2rem 1rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-35%);
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.4rem;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 10%;
    right: 25%;
    transform: translateX(70%);
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 3px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.3rem;
    color: #FCA61F;
  }
}

@media screen and (min-width: 992px) {
  .hero-carousel-best {
    width: 10em;
    position: absolute;
    top: 50%;
    left: -1%;
  }

  .hero-carousel-icon {
    width: 10em;
    position: absolute;
    top: -1%;
    right: 20%;
  }

  .hero-slider {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.4rem;
    padding-bottom: 4rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-25%);
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.7rem;
    padding: 0.8em;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 12.5%;
    right: 25%;
    left: 50%;
    transform: translateX(-25%);
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 3px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.7rem;
    padding: 0.6em;
    color: #FCA61F;
  }
}


@media screen and (min-width: 1024px) {
  .hero-carousel-best {
    width: 10em;
    position: absolute;
    top: 50%;
    left: -1%;
  }

  .hero-carousel-icon {
    width: 10em;
    position: absolute;
    top: -1%;
    right: 20%;
  }

  .hero-slider {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 100px;
    transform: translateX(-45%);
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.7rem;
    padding: 0.5em;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 100px;
    transform: translateX(60%);
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 1px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.7rem;
    padding: 0.5em;
    color: #FCA61F;
  }
}

@media screen and (min-width: 1360px) {
  .hero-carousel-best {
    width: 10em;
    position: absolute;
    top: 50%;
    left: -1%;
  }

  .hero-carousel-icon {
    width: 10em;
    position: absolute;
    top: -1%;
    right: 20%;
  }

  .hero-slider {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 250px;
    transform: translateX(-45%);
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.6em;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 250px;
    transform: translateX(60%);
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 3px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.4em;
    color: #FCA61F;
  }
}

@media screen and (min-width: 1536px) {
  .hero-carousel-best {
    width: 10em;
    position: absolute;
    top: 50%;
    left: -1%;
  }

  .hero-carousel-icon {
    width: 10em;
    position: absolute;
    top: -1%;
    right: 20%;
  }

  .hero-slider {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .button-slider-daftar {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 55%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #F8CA2B 0%, #FCA61F 100%);
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 0px;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.8em;
    color: white;
  }

  .button-slider-pelajari {
    position: absolute;
    bottom: 12.5%;
    /* left: 50%; */
    left: 57.5%;
    transform: translateX(50%);
    background: white;
    box-shadow: 0px 10px 20px -10px rgba(252, 166, 31, 0.7);
    border-radius: 32px;
    border: 3px solid #FCA61f;
    box-sizing: border-box;
    font-family: 'Baloo Chettan 2';
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.6em;
    color: #FCA61F;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

/* TESTIMONI */

.testimoni-slider-card {
  border-radius: 10px !important;
  border: 5px solid #F8CA2B !important;
  max-width: 100%;
}

.tingkat-kelulusan-card {
  background-color: #FFFFFF;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
}

@media screen and (max-width: 770px) {
  .tingkat-kelulusan-card {
    background-color: #FFFFFF;
    border-radius: 0%;
    border: 4px solid #FCA61F;
    border-radius: 20px;
  }

  .tingkat-kelulusan-card-middle {
    background-color: #FFFFFF;
    border-radius: 0%;
    border: 4px solid #FCA61F;
    border-radius: 20px;
  }
}

.tingkat-kelulusan-title {
  font-family: 'Baloo Chettan 2';
  font-size: 1.75rem;
  font-weight: 800;
}

.tingkat-kelulusan-peserta {
  font-family: 'Baloo Chettan 2';
  font-size: 1.3rem;
  font-weight: 400;
}

.tingkat-kelulusan-program {
  font-family: 'Baloo Chettan 2';
  font-size: 1.5rem;
  font-weight: 600;
}

.tingkat-kelulusan-persen {
  font-family: 'Baloo Chettan 2';
  font-size: 2.2rem;
  font-weight: 800;
}

/* KURIKULUM */

#kurikulum-title-section-container {
  position: relative;
  padding: 0px !important;
}

/* .wave-kurikulum {
  position: absolute;
  top: 0px;
  left: 0px;
} */

/* KELEBIHAN */

#kelebihan-section-container {
  position: relative;
  padding: 0px !important;
}

.kelebihan-section {
  padding-top: 5rem;
  position: relative;
  z-index: 1000;
  max-width: 80vw !important;
}

/* PENGALAMAN */

#pengalaman-section-container {
  position: relative;
  padding: 0px !important;
}

/* TESTIMONI */

#testimoni-section-container {
  position: relative;
  padding: 0px !important;
}

/* LEGALITAS */

#legalitas-section-container {
  position: relative;
  padding: 0px !important;
}

.legalitas-text {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  color: #000000 !important;
}

/* PRESTASI */

#prestasi-section-container {
  position: relative;
  padding: 0px !important;
}

#tutor-section-container {
  position: relative;
  padding: 0px !important;
}

#sistem-section-container {
  position: relative;
  padding: 0px !important;
}

.whatsapp-contact_logo {
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}

@media screen and (min-width: 1024px) {
  .row-tim-tutor {
    padding-left: 10%;
    padding-right: 10%;
  }
}


.ig-text {
  font-weight: 700;
  background: -webkit-linear-gradient(#CF45FF, #FFDF38);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yt-text {
  font-weight: 700;
  color: #FB4848;
}

.fb-text {
  font-weight: 700;
  color: #5F82FF;
}

/* HAPE */
@media screen and (max-width: 480px) {
  .iso-text {
    font-family: 'Baloo Chettan 2' !important;
    color: white;
    font-size: 1.2rem;
    line-height: 2.8rem;
  }
}

/* TABLET */
@media screen and (max-width: 720px) {
  .iso-text {
    font-family: 'Baloo Chettan 2' !important;
    color: white;
    font-size: 1.4rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 720px) {
  .iso-text {
    font-family: 'Baloo Chettan 2' !important;
    color: white;
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
}



/* ARTICLE */

.article-card {
  border: 0px !important;
  border-radius: 20px !important;
}

.article-card-img {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

/* TYPOGRAPHY */

.jm-peserta {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  background: -webkit-linear-gradient(#F8CA2B, #FCA826);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ct {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}

.jd-home,
.btn-hero,
.about,
.jd-home3,
.jd-home4,
.benefit,
.kelebihan-text,
.about-text {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  color: #FFFFFF;
}

.jd-home2,
.jd-home3,
.jd-home4 {
  font-weight: 700 !important;
}

.btn-hero {
  width: fit-content;
}

.tag-sb {
  margin-left: auto;
  margin-right: auto;
}

.tag-c {
  margin-left: auto;
  margin-right: auto;
  margin-top: -130px;
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 5rem;
  padding: 30px;

  color: #FFFFFF;
}

.tag-oren {
  margin-left: auto;
  margin-right: auto;
  margin-top: -130px;
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 92px;
  background: -webkit-linear-gradient(#F8CA2B, #FCA826);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.jd-home2 {
  text-align: center;
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
}

.jd-sub {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 104px;
  background: -webkit-linear-gradient(#F8CA2B, #FCA826);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* BUTTON STYLE */

.btn-home {
  display: inline-block;
  padding: 0px 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background: -webkit-linear-gradient(#F8CA2B, #FCA826);
  border: 5px solid black;
  border-radius: 50px;
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: 500;
}

.btn-home:hover {
  background-color: #F9C429
}

.btn-home:active {
  background-color: #F9C429;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.login-btn {
  font-family: 'Quicksand';
  font-weight: 700;
  margin-top: -20px;
  display: inline-block;
  padding: 5px 25px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #000;
  background-color: #F8CA2B;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 8px 9px #E5E5E5;
}

.login-btn:hover {
  background-color: #F8CA2B
}

.login-btn:active {
  background-color: #F8CA2B;
  box-shadow: 0 5px #E5E5E5;
  transform: translateY(7px);
}

.bt-l {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  transition: 1s;
  justify-content: center;
  align-items: center;
  background: linear-gradient(250.73deg, #BD77E9 26.33%, #FCA61F 70.83%);
  box-shadow: 0px 10px 20px -10px #FCA61F;
  border-radius: 64px 16px;
  transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  /* IE 9 */
  -webkit-transform: skewX(-10deg);
  /* Safari */
}

.bt-m {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  transition: 1s;
  justify-content: center;
  align-items: center;
  background: linear-gradient(250.73deg, #F82B2B 26.33%, #FCA61F 70.83%);
  box-shadow: 0px 10px 20px -10px #FCA61F;
  border-radius: 50px;
  transform: skewX(0deg);
}

.bt-r {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  transition: 1s;
  justify-content: center;
  align-items: center;
  background: linear-gradient(250.73deg, #18D196 26.33%, #FCA61F 70.83%);
  box-shadow: 0px 10px 20px -10px #FCA61F;
  border-radius: 16px 64px;
  transform: skewX(10deg);
  -ms-transform: skewX(10deg);
  /* IE 9 */
  -webkit-transform: skewX(10deg);
  /* Safari */
}

.bt-link {
  transform: skewX(0deg);
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
}

.bt-link-l {
  -ms-transform: skewX(15deg);
  /* IE 9 */
  -webkit-transform: skewX(15deg);
  /* Safari */
  transform: skewX(15deg);
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
}

.bt-link-r {
  -ms-transform: skewX(-15deg);
  /* IE 9 */
  -webkit-transform: skewX(-15deg);
  /* Safari */
  transform: skewX(-15deg);
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (min-width: 320px) {
  .bt-l {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    /* IE 9 */
    -webkit-transform: skewX(0deg);
    /* Safari */
    border-radius: 50px;
  }

  .bt-m {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    /* IE 9 */
    -webkit-transform: skewX(0deg);
    /* Safari */
    border-radius: 50px;
  }

  .bt-r {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    /* IE 9 */
    -webkit-transform: skewX(0deg);
    /* Safari */
    border-radius: 50px;
  }
}

@media screen and (min-width: 640px) {
  .bt-l {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    /* IE 9 */
    -webkit-transform: skewX(-10deg);
    /* Safari */
    border-radius: 64px 16px;
  }

  .bt-m {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    /* IE 9 */
    -webkit-transform: skewX(0deg);
    /* Safari */
    border-radius: 50px;
  }

  .bt-r {
    margin: 0px 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    transform: skewX(10deg);
    -ms-transform: skewX(10deg);
    /* IE 9 */
    -webkit-transform: skewX(10deg);
    /* Safari */
    border-radius: 16px 64px;
  }
}

@media screen and (min-width: 992px) {
  .bt-l {
    margin: 0px 20%;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .bt-m {
    margin: 0px 20%;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .bt-r {
    margin: 0px 20%;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .bt-link-l {
    font-size: 1.1rem
  }

  .bt-link {
    font-size: 1.1rem
  }

  .bt-link-r {
    font-size: 1.1rem
  }
}

.bt-l:focus {
  outline: none;
}

.bt-m:focus {
  outline: none;
}

.bt-r:focus {
  outline: none;
}

.bt-l:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #FBC638;
}

.bt-m:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #FBC638;
}

.bt-r:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #FBC638;
}

.bt-l span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.bt-m span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.bt-r span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}


.bt-l:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

.bt-m:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

.bt-r:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

/* span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
} */


.list-unstyled a {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  color: #000;
  position: relative;
  font-size: 14px;
  z-index: 2;
  font-weight: 700;
}

.footers-info p {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  color: #000;
  position: relative;
  font-size: 18px;
  z-index: 2;
  font-weight: 700;
}

.list-unstyled a:hover {
  color: white;
}

.content-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  justify-content: center;
  padding-bottom: 2rem !important;
}

.content-box-footer {
  width: 100%;
  padding: 0px !important;
  background-color: white !important;
  border-top: 0px !important;
}

.content-box img {
  width: 64px;
}

.button5-title {
  font-family: 'Quicksand';
  text-align: start;
  font-size: 1.125rem;
  font-weight: 600;
  color: #000;
}

.nurse-bg-orange {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%);
  padding: 0.5rem;
}

.nurse-bg-purple {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, #BC72EB 0%, #A328F2 100%);
  padding: 0.5rem;
}

.nurse-bg-green {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, #35D5A1 0%, #1CB181 100%);
  padding: 0.5rem;
}

.nurse-bg-red {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, #F07676 0%, #D31C1C 100%);
  padding: 0.5rem;
}

.program-card-container {
  min-height: 240px;
  border-top-left-radius: 12.5% !important;
}

.program-card-container-new {
  min-height: 100px;
}

.btn.button5-orange {
  height: 5rem;
  width: 100%;
  background-color: white;
  color: #FCA61F;
}

.program-card-container:hover .btn.button5-orange {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%);
  color: white;
}

.program-card-container:hover .nurse-bg-orange {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%);
  color: white;
}

.program-card-container-new:hover .btn.button5-orange {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%);
  color: white;
}

.program-card-container-new:hover .nurse-bg-orange {
  background: linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%);
  color: white;
}

.btn.button5-purple {
  height: 5rem;
  width: 100%;
  background-color: white;
  color: #A328F2;
}

.program-card-container:hover .btn.button5-purple {
  background: linear-gradient(180deg, #BC72EB 0%, #A328F2 100%);
  color: white;
}

.program-card-container:hover .nurse-bg-purple {
  background: linear-gradient(180deg, #BC72EB 0%, #A328F2 100%);
  color: white;
}

.program-card-container-new:hover .btn.button5-purple {
  background: linear-gradient(180deg, #BC72EB 0%, #A328F2 100%);
  color: white;
}

.program-card-container-new:hover .nurse-bg-purple {
  background: linear-gradient(180deg, #BC72EB 0%, #A328F2 100%);
  color: white;
}

.btn.button5-green {
  height: 5rem;
  width: 100%;
  background-color: white;
  color: #1CB181;
}

.program-card-container:hover .btn.button5-green {
  background: linear-gradient(180deg, #35D6A1 0%, #1CB181 100%);
  color: white;
}

.program-card-container:hover .nurse-bg-green {
  background: linear-gradient(180deg, #35D6A1 0%, #1CB181 100%);
  color: white;
}

.program-card-container-new:hover .btn.button5-green {
  background: linear-gradient(180deg, #35D6A1 0%, #1CB181 100%);
  color: white;
}

.program-card-container-new:hover .nurse-bg-green {
  background: linear-gradient(180deg, #35D6A1 0%, #1CB181 100%);
  color: white;
}


.btn.button5-red {
  height: 5rem;
  width: 100%;
  background-color: white;
  color: #D31E1E;
}

.program-card-container:hover .btn.button5-red {
  background: linear-gradient(180deg, #F07777 0%, #D31E1E 100%);
  color: white;
}

.program-card-container:hover .nurse-bg-red {
  background: linear-gradient(180deg, #F07777 0%, #D31E1E 100%);
  color: white;
}

.program-card-container-new:hover .btn.button5-red {
  background: linear-gradient(180deg, #F07777 0%, #D31E1E 100%);
  color: white;
}

.program-card-container-new:hover .nurse-bg-red {
  background: linear-gradient(180deg, #F07777 0%, #D31E1E 100%);
  color: white;
}

.section-di-testimoni {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10rem;
  position: relative;
  z-index: 1000;
  max-width: 80vw !important;
}

/*  Testimoni */

.testimoni-page__title {
  width: auto;
  border-radius: 20px;
  font-size: 2.5rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 4px solid black;
  box-shadow: 8px 10px #F8CA28;
}

.testimoni-page__title-purple {
  width: auto;
  border-radius: 20px;
  font-size: 2.5rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 4px solid black;
  box-shadow: 8px 10px #D15AEA;
  ;
}

.title-big {
  font-family: 'Baloo Chettan 2', cursive;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 69px;
  color: #000000;
}

.form-label {
  font-family: 'Quicksand', cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;
}

.text-regular {
  font-family: 'Quicksand', cursive;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #000000;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .bg-desktop {
    background-image: none !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-none {
    display: none !important;
  }

  .btn-hero {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }

  .legalitas-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }

  .about {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
  }

  .jd-home2 {
    font-size: 28px !important;
    line-height: 35px;
    font-weight: 700;
  }

  .jd-home3 {
    font-size: 28px !important;
    line-height: 35px;
    font-weight: 700;
  }

  .jd-home4 {
    font-size: 28px !important;
    line-height: 35px;
    font-weight: 700;
  }

  .benefit {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  .kelebihan-text {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 23px;
    text-align: center;
  }

  .about-text {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 26px;
    text-align: center;
  }

  .kelebihan-image {
    margin-left: auto;
    margin-right: auto;
  }

  .sub-judul {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 18px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 28px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub-title {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .g-title {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .card-bayar {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-desktop {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .jd-home {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }

  .legalitas-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 65px;
    text-align: center;
  }

  .about {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
  }

  .jd-home2 {
    font-size: 64px;
    line-height: 92px;
  }

  .jd-home3,
  .jd-home4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }

  .benefit {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: start;
  }

  .kelebihan-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }

  .about-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .sub-judul {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 32px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .sub-title {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .card-bayar {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-desktop {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .d-md-none {
    display: none !important;
  }

  .jd-home {
    font-size: 50px !important;
    font-weight: 500;
    line-height: 72px;
  }

  .legalitas-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 65px;
    text-align: left;
  }

  .about {
    font-weight: bold;
    font-size: 72px;
    line-height: 104px;
  }

  .jd-home2 {
    font-size: 64px;
    line-height: 92px;
  }

  .jd-home3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 69px;
    text-align: center;
  }

  .jd-home4 {
    font-weight: 600;
    font-size: 54px;
    line-height: 78px;
    text-align: center;
  }

  .benefit {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: start;
  }

  .about-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .sub-judul {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 32px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .sub-title {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .card-bayar {
    width: 50%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bg-desktop {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .d-md-none {
    display: none !important;
  }

  .jd-home {
    font-size: 50px !important;
    font-weight: 500;
    line-height: 72px;
  }

  .btn-hero {
    font-weight: 500;
    font-size: 42px;
    line-height: 60px;
  }

  .legalitas-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 65px;
    text-align: left;
  }

  .about {
    font-weight: bold;
    font-size: 72px;
    line-height: 104px;
  }

  .jd-home2 {
    font-size: 64px;
    line-height: 92px;
  }

  .jd-home3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 69px;
    text-align: center;
  }

  .jd-home4 {
    font-weight: 600;
    font-size: 54px;
    line-height: 78px;
    text-align: center;
  }

  .benefit {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: start;
  }

  .kelebihan-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }

  .about-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .sub-judul {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 32px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .sub-title {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .card-bayar {
    width: 50%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .bg-desktop {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .d-md-none {
    display: none !important;
  }

  .jd-home {
    font-size: 50px !important;
    font-weight: 500;
    line-height: 72px;
  }

  .btn-hero {
    font-weight: 500;
    font-size: 42px;
    line-height: 60px;
  }

  .legalitas-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 65px;
    text-align: left;
  }

  .about {
    font-weight: bold;
    font-size: 72px;
    line-height: 104px;
  }

  .jd-home2 {
    font-size: 64px;
    line-height: 92px;
  }

  .jd-home3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 69px;
    text-align: center;
  }

  .jd-home4 {
    font-weight: 600;
    font-size: 54px;
    line-height: 78px;
    text-align: center;
  }

  .benefit {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: start;
  }

  .kelebihan-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }

  .about-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .sub-judul {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 32px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .sub-title {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .card-bayar {
    width: 50%;
  }
}

@media (min-width: 1900px) {
  .bg-desktop {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .jd-home {
    font-size: 50px !important;
    font-weight: 500;
    line-height: 72px;
  }

  .btn-hero {
    font-weight: 500;
    font-size: 42px;
    line-height: 60px;
  }

  .legalitas-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 65px;
    text-align: left;
  }

  .about {
    font-weight: bold;
    font-size: 72px;
    line-height: 104px;
  }

  .jd-home2 {
    font-size: 64px;
    line-height: 92px;
  }

  .jd-home3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 69px;
    text-align: center;
  }

  .jd-home4 {
    font-weight: 600;
    font-size: 54px;
    line-height: 78px;
    text-align: center;
  }

  .benefit {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: start;
  }

  .kelebihan-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }

  .about-text {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .sub-judul {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.06em;
  }

  .judul-page {
    font-size: 32px !important;
  }

  .title-dashboard {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.06em;
    font-weight: bold;
    font-family: 'Quicksand';
  }

  .tentang_title {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .sub-title {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.08em;
  }
}

.sub-judul,
.title,
.tentang_title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: bold;
  color: #000000;
}

.sub {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  color: #23272A;
}

.sub-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  color: #FFF;
}

.judul-page {
  font-family: 'Baloo Chettan 2';
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: #000000;
}

.btn-tryout {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  background-color: white;
  padding: 10px;
  color: #000000;
  margin: 10px;
}

.rdt_TableHead div {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  background-color: transparent;
  color: #6B7280;
}

.sc-gsTEea {
  background-color: #F3F4F6 !important;
  border: 1px solid #BDC1C9;
  border-radius: 16px 16px 0px 0px;
}

.rdt_TableBody div {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.06em;

  color: #000000;
}

.sc-hKgJUU {
  flex-grow: 0.2 !important;
  -webkit-flex-grow: 0.2 !important;
}

.drXomb {
  width: 200px;
}

.pilih-tryout {
  background-color: #F8CA2B !important;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.06em !important;
  color: black !important;
  border-radius: 8px;
  padding: 10px;
}

.pilih-tryout-disable {
  background-color: #838383 !important;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.06em !important;
  color: black !important;
  border-radius: 8px;
  padding: 10px;
}

.my-tryout {
  /* background: #F3F4F6 !important; */
  border-radius: 8px !important;
  border: none !important;
  height: 45px;
}

.tata-cara {
  border: 4px solid #FFE692;
  border-radius: 16px;
  height: 386px;
  width: 306px !important;
}

.nomor-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 25%);
  width: -moz-fit-content;
  width: 65px;
  padding: 10px;
  border-radius: 50%;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  color: #FCA61F;
}

.txt-card {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.cardbody {
  padding: 0px !important;
}

.breadcrumbss {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6B7280;
}

.card-gede {
  background: #FFFFFF;
  border: 1px solid #BDC1C9;
  box-sizing: border-box;
  border-radius: 16px;
}

.card-head {
  background-color: #F3F4F6 !important;
  border: 1px solid #BDC1C9;
  border-radius: 16px 16px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #000000;
}

.upload {
  background: #FFFFFF;
  border: 2px solid #F8CA2B;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px;
}

.bg-orange {
  background: linear-gradient(0deg, #F8CA2B, #F8CA2B), linear-gradient(246.42deg, #F8CA2B 28.07%, #FCA61F 71.37%) !important;
  background-image: url('assets/img/new-header-bg-1.png');
}

.bg-orange-new {
  background: linear-gradient(0deg, #F8CA2B, #F8CA2B), linear-gradient(246.42deg, #F8CA2B 28.07%, #FCA61F 71.37%);
  background-image: url('assets/img/new-header-bg-2.png') !important;
  background-repeat: no-repeat;
  background-size: 1900px;
}

.plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plan {
  cursor: pointer;
  width: 100%;
}

.plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plan .plan-content img {
  margin-right: 30px;
  /* height: 72px; */
}

.plan .plan-details p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.06em;

  color: #000000;
}

.plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plan input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plan input[type="radio"]:checked+.plan-content {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.panduan {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F8CA2B;
  border: 2px solid #F8CA2B;
  box-sizing: border-box;
  border-radius: 64px;
  color: #F8CA2B;
  padding: 5px 20px;
}

td {
  padding: 10px;
}

.label-tryout {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #6B7280;
  font-family: 'Quicksand';
}

.form-tryout {
  background: #FFFFFF;
  border: 1px solid #D2D6DB;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-family: 'Quicksand';
  padding: 0px 10px;
  font-size: 16px;
  color: #000;
}

/* CHECKBOX ROUND */

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="radio"] {
  visibility: hidden;
}

.round input[type="radio"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="radio"]:checked+label:after {
  opacity: 1;
}