.swal2-popup {
  border-radius: 20px;
  background: #fff;
  color: #000;
  font-family: 'Baloo Chettan 2', cursive;
  font-size: 1rem;
}

.swal2-styled.swal2-confirm {
  border: 2px solid black;
  border-radius: 20px;
  background: initial;
  padding: 5px 30px;
  background-color: #fff;
  color: #000;
  font-size: 1em;
}

.swal2-styled.swal2-cancel {
  border: 2px solid black;
  border-radius: 20px;
  background: initial;
  padding: 5px 30px;
  background-color: #fff;
  color: #000;
  font-size: 1em;
}