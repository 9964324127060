@media only screen and (min-width: 320px) {
    .pdd10r {
        padding-top: 5rem;
    }

    .pdt {
        padding-top: 1rem;
    }

    .pdd7r {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .card-xs {
        width: 50% !important;
    }

    .ptl {
        padding-top: 3rem;
    }

    .prpl10r {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .lc {
        margin-right: auto;
    }

    .col6 {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        padding: 0.5rem;
        width: 100%;
    }

    .prpl5r {
        padding-left: 0rem;
        padding-right: 0rem;
    }


    .pdcent {
        text-align: left;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .pdct {
        text-align: left;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .pbr {
        padding-bottom: 0.5rem;
    }

    .txt32px {
        font-family: Quicksand;
        text-decoration: underline;
        font-size: 18px;
        font-weight: 700;
    }

    .tdt36px {
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .txt24px {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 500;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 18px;
    }

    .txt18px {
        font-family: Quicksand;
        font-size: 10px;
        font-weight: 400;
    }

    /* partnership section go */
    .pt5r {
        padding-top: 5rem;
    }

    .t32p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 12px;
        font-weight: 200;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 8px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        margin-top: -0.3rem;
        padding: 0rem;
        margin-left: -0.2rem;
    }

    .pdeclip {
        margin-top: -0.3rem;
        padding: 0.4rem;
        margin-left: -1rem;
    }

}

@media only screen and (min-width: 420px) {

    /* partnership section go */
    .pt5r {
        padding-top: 5rem;
    }

    .pdd7r {
        padding-top: 5rem;
        padding-bottom: 2rem;
    }

    .col4 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 80%;
        margin-bottom: 2rem;
    }

    .ptl {
        padding-top: 0.2rem;
    }

    .ptb {
        padding-top: 2rem;
    }

    .pdcent {
        text-align: left;
        padding-top: 2.5rem;
        padding-bottom: 2rem;
    }

    .pdct {
        text-align: left;
        padding-top: 2.5rem;
        padding-bottom: 2rem;
    }


    .prpl10r {
        font-family: Quicksand;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .prpl5r {
        font-family: Quicksand;
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .t32p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 200;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 16px;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 8px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        padding: 0rem;
        margin-left: -1rem;
    }

    .pdeclip {
        padding: 0.3rem;
        margin-left: -1.2rem;
    }
}

@media only screen and (min-width: 480px) {
    .pdd10r {
        padding: 0rem;
    }

    .pdd7r {
        padding-top: 8rem;
        padding-bottom: 2rem;
    }

    .col4 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 30%;
        margin-bottom: 2rem;
    }

    .prpl10r {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .prpl5r {
        padding-left: 1rem;
        padding-right: 1rem;
    }


    .pdcent {
        padding-top: 5rem;
    }

    .pbr {
        padding-bottom: 0.5rem;
    }

    .txt32px {
        font-family: Quicksand;
        text-decoration: underline;
        font-size: 18px;
        font-weight: 700;
    }

    .tdt36px {
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .txt24px {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 500;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 16px;
    }

    .txt18px {
        font-family: Quicksand;
        font-size: 10px;
        font-weight: 400;
    }

    .iconz {
        width: 8%;
    }

    /* partnership section go */
    .pt5r {
        padding-top: 5rem;
    }

    .t32p {
        font-family: Quicksand;
        font-size: 20px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 12px;
        font-weight: 200;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 8px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        padding: 0rem;
        margin-left: -1rem;
    }

    .pdeclip {
        padding: 0.3rem;
        margin-left: -1.5rem;
    }

}

@media only screen and (min-width: 768px) {
    .pdd10r {
        padding: 0rem;
        padding-top: 8rem;
    }

    .pdt {
        padding-top: 4rem;
    }

    .pdd7r {
        padding-top: 8rem;
        padding-bottom: 2rem;
    }

    .col4 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 30%;
        margin-bottom: 2rem;
    }

    .col6 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: left;
        width: 55%;
        margin-bottom: 2rem;
    }


    .prpl10r {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .prpl5r {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .pdcent {
        padding-top: 5rem;
    }

    .pbr {
        padding-bottom: 0.5rem;
    }

    .txt32px {
        font-family: Quicksand;
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .tdt36px {
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .txt24px {
        font-family: Quicksand;
        font-size: 20px;
        font-weight: 500;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 18px;
    }

    .txt18px {
        font-family: Quicksand;
        font-size: 16px;
        font-weight: 400;
    }

    .iconz {
        width: 8%;
    }

    /* partnership section go */
    .t32p {
        font-family: Quicksand;
        font-size: 26px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 200;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 12px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        padding: 0rem;
        margin-left: -3.2rem;
    }

    .pdeclip {
        padding: 0.3rem;
        margin-left: -5.7rem;
    }


}


@media only screen and (min-width: 960px) {
    .mt5r {
        margin-top: 2rem;
    }

    .mt2r {
        margin-top: 1.5rem;
    }

    .mt1r {
        margin-top: 0.5rem;
    }


    .pdd10r {
        padding: 6rem;
        padding-top: 10rem;
    }

    .pdd7r {
        padding-top: 8rem;
        padding-bottom: 2rem;
    }

    .col4 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 30%;
        margin-bottom: 2rem;
    }

    .col6 {
        border-radius: 1rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 45%;
        margin-bottom: 2rem;
    }

    .prpl10r {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .prpl5r {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .pdcent {
        padding-top: 2rem;
    }

    .pdct {
        padding-top: 3rem;
    }

    .pbr {
        padding-bottom: 0.5rem;
    }

    .txt32px {
        font-family: Quicksand;
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .tdt36px {
        text-decoration: underline;
        font-size: 36px;
        font-weight: 700;
    }

    .txt24px {
        font-family: Quicksand;
        font-size: 24px;
        font-weight: 500;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 20px;
    }

    .txt18px {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 400;
    }

    .iconz {
        width: 10%;
    }

    /* partnership section go */
    .t32p {
        font-family: Quicksand;
        font-size: 26px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 200;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 12px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        padding: 0rem;
        margin-left: -3rem;
    }

    .pdeclip {
        padding: 0.3rem;
        margin-left: -6rem;
    }

}

@media only screen and (min-width: 1025px) {
    .w75p {
        width: 75%;
    }

    .w70p {
        width: 70%;
    }

    .w65p {
        width: 65%;
    }

    .prl5r {
        padding-left: 5rem;
        padding-right: 5rem;
    }

}

@media only screen and (min-width: 1440px) {
    .mt5r {
        margin-top: 3rem;
    }

    .mmb {
        margin-bottom: -5rem;
    }

    .mt2r {
        margin-top: 2rem;
    }

    .mt1r {
        margin-top: 1rem;
    }

    .w75p {
        width: 75%;
    }

    .w70p {
        width: 70%;
    }

    .w65p {
        width: 65%;
    }

    .prl5r {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .tagselect {
        margin-left: -2rem;
    }

    .pdd10r {
        padding: 10rem;
    }

    .pdd7r {
        padding-right: 7rem;
        padding-left: 7rem;
        padding-top: 7rem;
    }

    .prpl10r {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .prpl5r {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .pdcent {
        padding-top: 2rem;
    }

    .pdct {
        padding-top: 8rem;
    }

    .pbr {
        padding-bottom: 0.5rem;
    }

    .txt32px {
        font-family: Quicksand;
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
    }

    .tdt36px {
        text-decoration: underline;
        font-size: 36px;
        font-weight: 700;
    }

    .txt24px {
        font-family: Quicksand;
        font-size: 24px;
        font-weight: 500;
    }

    .txt20px {
        font-family: Quicksand;
        font-size: 20px;
    }

    .txt18px {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 400;
    }

    .iconz {
        width: 5%;
    }


    /* partnership section go */
    .t32p {
        font-family: Quicksand;
        font-size: 32px;
        font-weight: 400;
    }

    .t24p {
        font-family: Quicksand;
        font-size: 24px;
        font-weight: 200;
    }

    .t18p {
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 200;
    }

    .pdemail {
        font-family: Quicksand;
        padding: 0rem;
        margin-left: -4rem;
    }

    .pdeclip {
        padding: 0.7rem;
        margin-left: -8rem;
    }
}

@media (min-width: 1500px) {

    .pdeclip {
        padding: 0.7rem;
        margin-left: -11rem;
    }

    .pdemail {
        padding: 0.1rem;
        margin-left: -6rem;
    }

}

section.slideshow {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

section.slideshow div.slide-holder {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

section.slideshow section.current-slide {
    width: 450px;
    height: 450px;
    margin: auto 10px;
}

section.slideshow section.previous-slide,
section.slideshow section.next-slide {
    width: 350px;
    height: 350px;
}

section.slideshow section.slide div.slide-thumbnail {
    width: 100%;
    height: 100%;
    background: #F9BA28;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    box-shadow: 5px 5px 6px #F9BA28;
}

section.slideshow div.slideshow-controller {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


section.slideshow div.slideshow-controller span {
    padding: 10px;
    width: 100px;
    border: none;
    background: #F9BA28;
    color: #f5f5f5;
    cursor: pointer;
    text-align: center;
    box-shadow: 3px 3px 5px #F9BA28;
    user-select: none;
}

section.slideshow div.slideshow-controller span:hover {
    color: #000000;
    text-shadow: 3px 3px 5px #000000;
}

section.slideshow div.slideshow-controller span:active {
    color: #FFF;
    text-shadow: 3px 3px 5px #FFF;
    background: #ffc200;
}

/* gallerysec1 */
section.slideshow-gal {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

section.slideshow-gal div.slide-holder-gal {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

section.slideshow-gal section.current-slide-gal {
    width: 786px;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 295px;
    margin: auto 10px;
}

section.slideshow-gal section.previous-slide-gal,
section.slideshow-gal section.next-slide-gal {
    width: 586px;
    height: 295px;
}

section.slideshow-gal section.slide-gal div.slide-thumbnail-gal {
    width: 100%;
    height: 100%;
    background: #F9BA28;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    box-shadow: 5px 5px 6px #F9BA28;
}

section.slideshow-gal div.slideshow-controller-gal {
    margin-top: 10px;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* gallerysec2 */
section.slideshow-gal-2 {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

section.slideshow-gal-2 div.slide-holder-gal-2 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

section.slideshow-gal-2 section.current-slide-gal-2 {
    width: 786px;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 350px;
    margin: auto 10px;
}

section.slideshow-gal-2 section.previous-slide-gal-2,
section.slideshow-gal-2 section.next-slide-gal-2 {
    width: 586px;
    height: 250px;
}

section.slideshow-gal-2 section.slide-gal-2 div.slide-thumbnail-gal-2 {
    width: 100%;
    height: 100%;
    background: #F9BA28;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    box-shadow: 5px 5px 6px #F9BA28;
}

section.slideshow-gal-2 div.slideshow-controller-gal-2 {
    margin-top: 10px;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* section.slideshow-gal div.slideshow-controller-gal span {
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background: #F9BA28;
    color: #f5f5f5;
    cursor: pointer;
    text-align: center;
    box-shadow: 3px 3px 5px #F9BA28;
    user-select: none;
} */

/* section.slideshow-gal div.slideshow-controller-gal span:hover {
    color: #000000;
    text-shadow: 3px 3px 5px #000000;
} */

/* section.slideshow-gal div.slideshow-controller-gal span:active {
    color: #FFF;
    border-radius: 50%;
    text-shadow: 3px 3px 5px #FFF;
    background: #ffc200;
} */

.Collapsible {
    background-color: #F6F6F6;
    padding: 0.7rem;
    font-weight: 800;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
}

.Collapsible p {
    font-weight: 100;
}

.balooc2 {
    font-family: Baloo Chettan 2 !important;
}

/* ::-webkit-file-upload-button {
    background: #F8CA2B;
    border-radius: 15px;
    padding: 1em;
} */

.fill-window {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}


/* TES INPUT ICON */
input[type="text-icon"] {
    background-color: #F3F4F6;
    width: 100%;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
}

/* input[type="text-icon"]:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
} */

.inputWithIcon input[type="text-icon"] {
    padding-left: 40px;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon i {
    position: absolute;
    left: 0;
    top: 13px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
}

.inputWithIcon input[type="text-icon"]:focus+i {
    color: #F8CA2B;
}

.inputWithIcon.inputIconBg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text-icon"]:focus+i {
    color: #fff;
    background-color: #F8CA2B;
}

.tesbutton {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #aaa !important;
    border-radius: 8px;
    padding: 10px;

}

.tesbuttonborder {
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border: 2px solid #aaa;
    color: #aaa !important;
    border-radius: 8px;
    padding: 10px;

}

.publish {
    background-color: #FFFF;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border: 2px solid #aaa;
    color: #aaa !important;
    border-radius: 8px;
    padding: 10px;

}

.btnlight {
    background-color: #F6F6E6 !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #F8CA2B !important;
    border-radius: 8px;
    padding: 10px;
}

.btnlight:hover {
    border: 2px solid #F8CA2B;
}


.tesbuttonborder:hover {
    border: 2px solid #F8CA2B;
    color: #F8CA2B !important;

}

.tesbutton:hover {
    color: #F8CA2B !important;

}

/* new */
.navv {
    padding: 1.5rem;
}

.duration {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.duration h2 {
    font-size: 28px;
    font-weight: 700;
    display: block;
}

.subm-tryout {
    background-color: #F8CA2B !important;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.06em !important;
    color: black !important;
    border-radius: 8px;
    padding: 10px;
}

.subm-tryout-disabled {
    background-color: #6d6d6d !important;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.06em !important;
    color: black !important;
    border-radius: 8px;
    padding: 10px;
}

.no-tryout {
    background-color: #F8CA2B !important;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 20px !important;
    width: 50px;
    letter-spacing: 0.06em !important;
    color: black !important;
    border-radius: 8px;
    padding: 10px;
    margin-top: 0.7rem;
}

.no-tryout:hover {
    /* border: 2px solid #000; */
    color: #FFF !important;
}

.btn-ragu {
    background-color: #E5E7EB !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000 !important;
    border-radius: 8px;
    padding: 10px;
}

.btn-ragu:hover {
    color: #F8CA2B !important;
}

.soal {
    width: 80%;
    font-size: 22px;
    font-weight: 400;
    line-height: 27.5px;
}