.forgetpass-container {
  padding-top: 10rem;
}

.form-control {
  font-family: 'Quicksand';
  border: 2.5px solid #212121 !important;
  border-radius: .75rem !important;
}


.form-control:focus {
  color: #212121;
  background-color: #fff;
  border-color: #F8CA2B !important;
  border-top-color: #F8CA2B;
  border-right-color: #F8CA2B;
  border-bottom-color: #F8CA2B;
  border-left-color: #F8CA2B;
  outline: 0;
  box-shadow: 0 0 0 0.12rem #F8CA2B !important;
}

.form-select {
  font-family: 'Quicksand';
  border: 2.5px solid #212121;
  background-color: #F3F4F6;
  border-radius: 7px !important;
  padding: .375rem 2.25rem .375rem .75rem !important;
}

.form-select:focus {
  color: #212121;
  background-color: #fff;
  outline: 0;
}

.bt-form-submit {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-family: 'Quicksand';
  transition: 1s;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  background: linear-gradient(270deg, #F8CA2B 0%, #FCA61F 96.65%);
  border-radius: 50px;
  min-width: 200px;
}

.bt-form-submit:focus {
  outline: none;
}

.form-check-input:checked {
  background-color: #F8CA2B !important;
  border-color: #F8CA2B !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.12rem #cca720 !important;
}

.form-label {
  font-family: 'Quicksand';
}

.form-check-label {
  font-family: 'Quicksand';
}