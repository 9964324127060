body[data-theme='dark'],
.dark-bg,
.dark-mode-texts {
    --bg: #19191b;
    --bg-2: #19191b;
    --color-headings: $white;
    --color-texts: rgba(255, 255, 255, 0.7);
    --color-texts-2: rgba(255, 255, 255, 1);
    --color-texts-3: rgba(255, 255, 255, 0.7);
    --border-color: rgba(255, 255, 255, 0.08);
    --border-color-2: rgba(255, 255, 255, 0.7);
}

body[data-theme='light'],
.light-bg,
.light-mode-texts {
    --bg: $white;
    --bg-2: #f8f9fc;
    --color-headings: #25373f;
    --color-texts: rgba(37, 55, 63, 0.7);
    --color-texts-2: #3b4656;
    --color-texts-3: rgba(38, 39, 41, 0.7);
    --border-color: rgba(128, 138, 142, 0.2);
    --border-color-2: rgba(37, 55, 63, 0.1);
}

body {
    --bg: $white;
    --bg-2: #f8f9fc;
    --color-headings: #25373f;
    --color-texts: rgba(37, 55, 63, 0.7);
    --color-texts-2: #3b4656;
    --color-texts-3: rgba(38, 39, 41, 0.7);
    --border-color: rgba(128, 138, 142, 0.2);
    --border-color-2: rgba(37, 55, 63, 0.1);
}

p {
    color: var(--color-texts);
}

.default-logo,
.default-shape {
    display: block;
}

.light-version-logo,
.light-shape {
    display: none;
}

.dark-version-logo,
.dark-shape {
    display: none;
}

.light-version-logo.default-logo {
    display: block;
}

.dark-version-logo.default-logo {
    display: block;
}

body[data-theme='dark'] .dark-version-logo,
body[data-theme='dark'] .dark-shape,
.dark-mode-texts .dark-version-logo,
.dark-mode-texts .dark-shape {
    display: block;
}

body[data-theme='dark'] .light-version-logo,
.dark-mode-texts .light-version-logo {
    display: none;
}

body[data-theme='dark'] .light-version-logo.default-logo,
.dark-mode-texts .light-version-logo.default-logo {
    display: none;
}

body[data-theme='dark'] .light-shape,
.dark-mode-texts .light-shape {
    display: none;
}

body[data-theme='dark'] .light-shape.default-shape,
.dark-mode-texts .light-shape.default-shape {
    display: none;
}

body[data-theme='dark'] .light-mode-texts .light-version-logo,
body[data-theme='dark'] .light-mode-texts .light-shape,
.dark-mode-texts .light-mode-texts .light-version-logo,
.dark-mode-texts .light-mode-texts .light-shape {
    display: block;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo,
.dark-mode-texts .light-mode-texts .dark-version-logo {
    display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo.default-logo,
.dark-mode-texts .light-mode-texts .dark-version-logo.default-logo {
    display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape,
.dark-mode-texts .light-mode-texts .dark-shape {
    display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape.default-shape,
.dark-mode-texts .light-mode-texts .dark-shape.default-shape {
    display: none;
}

body[data-theme='light'] .light-version-logo,
body[data-theme='light'] .light-shape,
.light-mode-texts .light-version-logo,
.light-mode-texts .light-shape {
    display: block;
}

body[data-theme='light'] .dark-version-logo,
.light-mode-texts .dark-version-logo {
    display: none;
}

body[data-theme='light'] .dark-version-logo.default-logo,
.light-mode-texts .dark-version-logo.default-logo {
    display: none;
}

body[data-theme='light'] .dark-shape,
.light-mode-texts .dark-shape {
    display: none;
}

body[data-theme='light'] .dark-shape.default-shape,
.light-mode-texts .dark-shape.default-shape {
    display: none;
}

body[data-theme='light'] .dark-mode-texts .dark-version-logo,
body[data-theme='light'] .dark-mode-texts .dark-shape,
.light-mode-texts .dark-mode-texts .dark-version-logo,
.light-mode-texts .dark-mode-texts .dark-shape {
    display: block;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo,
.light-mode-texts .dark-mode-texts .light-version-logo {
    display: none;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo.default-logo,
.light-mode-texts .dark-mode-texts .light-version-logo.default-logo {
    display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape,
.light-mode-texts .dark-mode-texts .light-shape {
    display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape.default-shape,
.light-mode-texts .dark-mode-texts .light-shape.default-shape {
    display: none;
}

.bg-default {
    background: var(--bg);
}

.bg-default-1 {
    background: var(--bg);
}

.bg-default-2 {
    background: var(--bg-2);
}

.bg-default-3 {
    background: var(--bg-3);
}

.bg-default-4 {
    background: var(--bg-4);
}

.bg-default-5 {
    background: var(--bg-5);
}

.bg-default-6 {
    background: var(--bg-6);
}

.text-default-color {
    color: var(--color-texts);
}

.text-default-color-2 {
    color: var(--color-texts-2);
}

.heading-default-color {
    color: var(--color-headings);
}

.border-default-color {
    border-color: var(--border-color);
}

.border-default-color-2 {
    border-color: var(--border-color-2) !important;
}

/*Preloader css*/
div#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#loading img {
    z-index: 9999;
}

.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #ffffff !important;
}

.preloader img {
    width: 200px;
}

/*Header Css StaRT*/
.site-navbar {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
}

@media (min-width: 576px) {
    .site-navbar {
        padding-top: 0px;
    }
}

@media (min-width: 992px) {
    .site-navbar {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
}

.site-navbar .menu-block-wrapper {
    padding-left: 10px;
    flex-grow: 1;
    align-items: center;
}

@media (min-width: 992px) {
    .site-navbar .menu-block-wrapper {
        display: flex !important;
        flex-basis: auto;
    }
}

.site-header--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.site-header--sticky:not(.mobile-sticky-enable) {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    background: linear-gradient(180deg, #F8CA2B -36.4%, #FCA826 100%);
}

@media (min-width: 992px) {
    .site-header--sticky:not(.mobile-sticky-enable) {
        position: fixed !important;
        transition: .4s;
    }

    .site-header--sticky:not(.mobile-sticky-enable).scrolling {
        transform: translateY(-100%);
        transition: .4s;
    }

    .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 1000;
    }
}

.site-header--sticky.mobile-sticky-enable {
    top: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    position: fixed !important;
    transition: .4s;
    background: linear-gradient(180deg, #F8CA2B -36.4%, #FCA826 100%);
}

.site-header--sticky.mobile-sticky-enable.scrolling {
    transform: translateY(-100%);
    transition: .4s;
}

.site-header--sticky.mobile-sticky-enable.reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 9999;
}

.site-header--menu-center .container {
    position: relative;
}

.site-header--menu-center .menu-block-wrapper {
    position: static;
}

@media (min-width: 992px) {
    .site-header--menu-center .menu-block {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .site-header--menu-left .container-fluid .sub-menu.megamenu {
        left: 0%;
        transform: translateX(0%) translateY(-10px);
    }
}

@media (min-width: 992px) {
    .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover>.sub-menu {
        transform: translateX(0%) translateY(-10px);
        left: 0%;
        margin-left: 20px;
    }
}

.site-header--menu-left .menu-block .site-menu-main {
    justify-content: center;
    padding-left: 0%;
}

@media (min-width: 1200px) {
    .site-header--menu-left .menu-block {
        width: 100%;
    }
}

.site-header--menu-right .menu-block-wrapper {
    position: static;
}

.site-header--menu-right .menu-block {
    margin-left: auto;
}

@media (min-width: 992px) {
    .site-header--menu-right>.container-fluid .sub-menu.megamenu {
        left: 100%;
        transform: translateX(-100%) translateY(10px);
    }
}

@media (min-width: 992px) {
    .site-header--menu-right>.container-fluid .nav-item.has-megamenu:hover>.sub-menu {
        transform: translateX(-100%) translateY(-10px);
        left: 100%;
        margin-left: -20px;
    }
}

@media (min-width: 768px) {
    .container-fluid .site-navbar {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 992px) {
    .container-fluid .site-navbar {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 480px) {
    .header-btns {
        margin-right: 30px;
    }
}

@media (min-width: 992px) {
    .header-btns {
        margin-right: 0;
        margin-left: 30px;
    }
}

.nav-item-end {
    margin-left: 20px;
}

.top-contact-menu-wraper .header-btn {
    order: 3;
}

.top-contact-menu-wraper .mobile-menu-trigger {
    order: 4;
}

@media (max-width: 480) {
    .top-contact-menu-wraper .brand-logo {
        max-width: 25vw;
        order: 1;
        padding-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .top-contact-menu-wraper .nav-link-item {
        color: #fff !important;
    }

    .top-contact-menu-wraper .site-menu-main .nav-link-item {
        padding-top: 19px !important;
        padding-bottom: 19px !important;
    }

    .top-contact-menu-wraper .navbar {
        flex-wrap: wrap;
        padding: 30px 0px 0px 0px;
    }

    .top-contact-menu-wraper .brand-logo {
        margin-right: 15px;
        max-width: 10%;
        order: 1;
        padding-bottom: 30px;
    }

    .top-contact-menu-wraper .header-btn {
        order: 4;
    }

    .top-contact-menu-wraper .menu-block-wrapper {
        min-width: calc(100% - 256px);
        order: 3;
        background: #1D263A;
        border-radius: 0px 0px 0px 5px;
    }

    .top-contact-menu-wraper .top-contact-block {
        order: 2;
        min-width: 50%;
        display: flex !important;
        justify-content: flex-end;
        padding-bottom: 8px;
    }

    .top-contact-menu-wraper .menu-block {
        margin-left: initial;
    }
}

.top-contact-block ul {
    display: flex;
    align-items: center;
}

.top-contact-block ul li {
    display: inline-flex;
    align-items: center;
}

.top-contact-block span {
    height: 30px;
    width: 1px;
    background: rgba(29, 36, 58, 0.12);
    margin: 0px 35px;
}

.top-contact-block img {
    margin-right: 12px;
}

.top-contact-block a {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    color: #1D263A;
}

@media (min-width: 992px) {
    .row-lg {
        display: flex;
    }
}

@-webkit-keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideRight {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

.site-menu-main {
    margin-bottom: 0;
    padding-left: 0;
}

@media (min-width: 992px) {
    .site-menu-main {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
    }
}

.site-menu-main li {
    list-style: none;
}

.site-menu-main ul {
    list-style: none;
    margin-left: 0;
}

.site-menu-main a {
    text-decoration: none;
}

.navbar {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (min-width: 992px) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.site-menu-main .nav-item {
    display: inherit;
}

.site-menu-main .nav-link-item {
    color: #213053;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    position: relative;
}

@media (min-width: 992px) {
    .site-menu-main .nav-link-item {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.dark-mode-texts .nav-link-item {
    color: #fff;
}

.site-menu-main .sub-menu {
    position: absolute;
    z-index: 500;
    background: white;
    box-shadow: 3px 3px 5px 5px rgb(0 0 0 / 20%);
    padding: 20px 0px;
    transition: opacity 0.5s ease, top 0.5s ease, margin-top 0.5s ease, background-color 0.5s ease;
    margin-top: 25px;
    opacity: 0;
    visibility: hidden;
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu {
        top: 100%;
        min-width: 227px;
        max-width: 227px;
    }
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu .sub-menu {
        top: 0 !important;
        left: 100%;
    }
}

.site-menu-main .sub-menu--item {
    color: var(--color-headings);
    font-size: 15px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 992px) {
    .site-menu-main .sub-menu--item {
        padding-top: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu--item {
        color: #000;
    }
}

.site-menu-main .sub-menu--item>a {
    color: inherit;
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu--item>a {
        transition: .4s;
    }
}

.site-menu-main .sub-menu--item:hover>a {
    color: #F8CA2B;
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu.megamenu {
        background-color: #fff;
    }
}

.site-menu-main .sub-menu.megamenu .dropdown-image-block {
    max-height: 336px;
}

@media (min-width: 320px) and (max-width: 992px) {
    .site-menu-main .sub-menu.megamenu {
        border: 0;
    }
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu.megamenu {
        padding: 30px;
        min-width: 925px;
        left: 50%;
        transform: translateX(-50%) translateY(-10px);
        will-change: transform;
        top: 100%;
        box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
        border: 1px solid #e7e9ed;
        border-radius: 8px;
        visibility: hidden;
        z-index: -99;
        opacity: 0;
        pointer-events: none;
    }

    .site-menu-main .sub-menu.megamenu.megadropdown-center {
        transform: translateX(-50%) translateY(1px);
    }

    .site-menu-main .sub-menu.megamenu.megadropdown-right {
        right: 0%;
        transform: translateX(0%) translateY(10px);
    }

    .site-menu-main .sub-menu.megamenu.megadropdown-left {
        left: 0%;
        transform: translateX(0%) translateY(10px);
    }
}

@media (min-width: 1200px) {
    .site-menu-main .sub-menu.megamenu {
        min-width: 1100px;
    }
}

@media (min-width: 1600px) {
    .site-menu-main .sub-menu.megamenu {
        min-width: 1300px;
    }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
    padding-top: 14px;
    padding-bottom: 14px;
    display: block;
}

.site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    font-size: 15px;
    font-weight: 600;
    transition: .4s;
    color: #000;
}

@media (min-width: 320px) and (max-width: 992px) {
    .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
        color: var(--color-headings);
        border-top: 1px solid var(--border-color-3);
        padding-left: 20px;
        padding-bottom: 13px;
        padding-top: 13px;
    }
}

@media (min-width: 992px) {
    .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
        padding-left: 0px;
        padding-right: 10px;
    }

    .site-menu-main .sub-menu.megamenu .mega-drop-menu-item:hover {
        color: #000 !important;
    }
}

@media (min-width: 320px) and (max-width: 992px) {
    .site-menu-main .sub-menu.megamenu [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }

    .site-menu-main .sub-menu.megamenu [class*="row-"] {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    li.nav-item-has-children:not(.has-megamenu) {
        position: relative;
    }
}

@media (min-width: 992px) {
    li.nav-item-has-children:hover>.sub-menu {
        top: 100%;
        margin-top: 0px;
        visibility: visible;
        opacity: 1;
        z-index: 99;
        pointer-events: visible;
        border-radius: 10px 10px 15px 15px;
    }
}

.site-menu-main>li .sub-menu>ul>li>a {
    display: inline-block;
    padding: 10px 0;
    font-size: 15px;
    color: #555;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
}

.site-menu-main>li .sub-menu.mega-menu {
    left: 50%;
    transform: translateX(-50%);
}

.mobile-menu-head,
.mobile-menu-trigger {
    display: none;
}

/*responsive*/
@media (max-width: 991px) {
    .site-header .mobile-menu-trigger {
        display: flex;
        height: 30px;
        width: 30px;
        margin-left: 15px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }

    .site-header .mobile-menu-trigger span {
        display: block;
        height: 2px;
        background-color: #000;
        width: 24px;
        position: relative;
    }

    .reveal-header .mobile-menu-trigger span {
        background-color: #fff !important;
    }

    .site-header .mobile-menu-trigger span:before,
    .site-header .mobile-menu-trigger span:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
    }

    .reveal-header .mobile-menu-trigger span:before,
    .reveal-header .mobile-menu-trigger span:after {
        background-color: #fff !important;
    }

    .dark-mode-texts .mobile-menu-trigger span {
        background-color: #fff;
    }

    .dark-mode-texts .mobile-menu-trigger span:before,
    .dark-mode-texts .mobile-menu-trigger span:after {
        background-color: #fff;
    }

    .site-header .mobile-menu-trigger span:before {
        top: -6px;
    }

    .site-header .mobile-menu-trigger span:after {
        top: 6px;
    }

    .site-header .item-right {
        align-items: center;
    }

    .site-header .menu-block {
        position: fixed;
        width: 320px;
        background-color: #ffffff;
        right: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
        transform: translate(100%);
        transition: all 0.8s ease;
        z-index: 1099;
    }

    .site-header .menu-block.active {
        transform: translate(0%);
    }

    .site-menu-main>li {
        line-height: 1;
        margin: 0;
        display: block;
    }

    .site-menu-main>li>a {
        line-height: 50px;
        height: 50px;
        padding: 0 50px 0 15px;
        display: block;
    }

    .site-menu-main>li>a i {
        position: absolute;
        height: 50px;
        width: 50px;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 50px;
        transform: rotate(-90deg);
    }

    .site-header .menu-block .mobile-menu-head {
        display: flex;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 501;
        position: -webkit-sticky;
        position: sticky;
        background-color: #ffffff;
        top: 0;
    }

    .site-header .menu-block .mobile-menu-head .go-back {
        height: 50px;
        width: 50px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        color: #000000;
        font-size: 16px;
        display: none;
    }

    .site-header .menu-block .mobile-menu-head.active .go-back {
        display: block;
    }

    .site-header .menu-block .mobile-menu-head .current-menu-title {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        visibility: hidden;
    }

    .site-header .menu-block .mobile-menu-head.active .current-menu-title {
        visibility: visible;
    }

    .site-header .menu-block .mobile-menu-head .mobile-menu-close {
        height: 50px;
        width: 50px;
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        color: #000000;
        font-size: 25px;
    }

    .site-header .menu-block .site-menu-main {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .site-menu-main>li .sub-menu.mega-menu,
    .site-menu-main>li .sub-menu {
        visibility: visible;
        opacity: 1;
        position: absolute;
        box-shadow: none;
        margin: 0;
        padding: 15px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 50px;
        max-width: none;
        min-width: auto;
        display: none;
        transform: translateX(0%);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .site-menu-main>li .sub-menu.active {
        display: block;
    }

    .site-menu-main>li .sub-menu>ul>li>a,
    .site-menu-main>li .sub-menu.mega-menu>.list-item>ul>li>a {
        display: block;
    }

    .site-menu-main>li .sub-menu.mega-menu>.list-item>ul {
        margin-bottom: 15px;
    }

    .menu-overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1098;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .menu-overlay.active {
        visibility: visible;
        opacity: 1;
    }
}

/*Custom Css for menu*/
@media (min-width: 992px) {
    .has-megamenu ul {
        margin-left: auto;
    }

    .site-menu-main a {
        display: flex;
        align-items: center;
    }

    .site-menu-main i {
        margin-left: 15px;
        font-size: 15px;
    }
}

@media (min-width: 1200px) {
    .site-header--menu-center .navbar {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .site-header--menu-center .menu-block-wrapper {
        position: absolute !important;
        left: 50%;
        transform: translateX(-50%);
    }
}

.nav-item-has-children a {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 992px) {
    .reveal-header li.nav-item-has-children:hover>.sub-menu {
        top: 100%;
    }

    .reveal-header li.nav-item-has-children:hover>.megamenu {
        top: 112%;
    }
}

.menu-block.active .nav-link-item {
    color: #000 !important;
}

@media (min-width: 480px) {
    .d-xs-inline-flex {
        display: inline-flex !important;
    }
}

ul.site-menu-main .nav-item i {
    transition: all 0.4s ease-in-out;
}

ul.site-menu-main .nav-item:hover i {
    transform: rotate(-180deg);
    color: #FFFFFF;
}

ul.site-menu-main .nav-item:hover .nav-link-item {
    color: #FFFFFF;
}

li.sub-menu--item.nav-item-has-children:hover i {
    transform: rotate(-180deg) !important;
}

li.sub-menu--item.nav-item-has-children i {
    transform: rotate(-90deg) !important;
    transition: all 0.4s ease-in-out;
}

.single-dropdown-block h3 {
    opacity: 0.5;
    color: #000;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.1px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 25px;
    display: none;
}

@media (min-width: 992px) {
    .single-dropdown-block h3 {
        display: block;
    }
}

.reveal-header {
    background: #fff !important;
}

.reveal-header .nav-link-item {
    color: #1D263A !important;
}

.reveal-header .btn {
    color: #fff !important;
}

.reveal-header .btn:hover {
    color: #fff !important;
}

.sticky-menu-bg.reveal-header {
    background: #1D263A !important;
}

.sticky-menu-bg.reveal-header .nav-link-item {
    color: #fff !important;
}

.landing-2-menu .navbar {
    border-bottom: 1px solid rgba(29, 36, 58, 0.12);
}

.landing-2-menu.reveal-header .sign-in-btn {
    color: #1D263A !important;
}

.landing-5-menu.reveal-header .l5-header-btn .btn {
    color: #1D263A !important;
}

.landing-6-menu.reveal-header .l6-header-btn .btn {
    color: #1D263A !important;
}

.landing-6-menu.reveal-header .l6-header-btn .btn:hover {
    color: #fff !important;
}

.mega-menu-image {
    padding-right: 30px;
}

.mega-menu-image img {
    border-radius: 5px;
}

.btn {
    position: relative;
    z-index: 5;
    border-radius: 6px;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    min-width: auto;
    line-height: 28px;
    letter-spacing: -0.53px;
    padding: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all .4s ease-in-out;
}

.btn:hover {
    color: #fff !important;
}

.btn:focus {
    box-shadow: 0;
}

.btn.focus {
    box-shadow: 0;
}

.btn.btn-medium {
    min-width: 160px;
    height: 48px;
    line-height: 36px;
}

.btn.btn--lg {
    min-width: 181px;
    height: 61px;
}

.btn.btn-xl {
    min-width: 180px;
    height: 60px;
    border-radius: 5px;
}

.btn.btn--xxl {
    min-width: 194px;
    height: 60px;
    border-radius: 5px;
}

.btn.with-icon i {
    margin-left: -5px;
    margin-right: 3px;
    font-size: 16px;
    margin-top: 2px;
}

.btn--reset {
    background: transparent;
    border: 0;
}

.btn--link {
    color: #1787FC;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    min-width: 88px;
    letter-spacing: 1.63px;
    line-height: normal;
    height: 22px;
    padding: 0;
    border-radius: 0;
    border-bottom: 2px solid #1787FC;
    padding: 0px 0px 15px 0px !important;
    margin-top: 40px;
}

.btn--link:hover {
    color: #1787FC !important;
}

.landing-1-menu .header-btn-l1 .btn {
    min-width: 129px;
    height: 45px;
    box-shadow: 0 16px 36px rgba(241, 139, 98, 0.3);
    border-radius: 15px;
    background-color: #FF7139;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    margin-left: 15px;
    margin-right: 5px;
}

@media (min-width: 992px) {
    .landing-1-menu .header-btn-l1 .btn {
        margin-right: 0px;
    }
}

.l4-header-btn .btn {
    text-transform: uppercase;
    background: #FFCD28;
    min-width: 165px;
    height: 60px;
    border-radius: 0;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 4px;
    color: #1D263A;
}

.l5-header-btn .btn {
    border-radius: 0;
    font-weight: 600;
    font-size: 15px;
    color: #1D263A;
}

.l6-header-btn .btn {
    min-width: 131px;
    height: 40px;
    border: 1px solid rgba(29, 36, 58, 0.4);
    border-radius: 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
}

.l7-header-btn .btn {
    background: #1787FC;
    min-width: 131px;
    height: 40px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
    margin-left: 15px;
    margin-right: 5px;
}

@media (min-width: 992px) {
    .l7-header-btn .btn {
        margin-right: 0px;
    }
}

.l8-header-btn .btn {
    background: #20BFA9;
    min-width: 210px;
    height: 50px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
    margin-left: 15px;
    margin-right: 5px;
}

@media (min-width: 992px) {
    .l8-header-btn .btn {
        margin-right: 0px;
    }
}

.l9-header-btn .btn {
    background: #2D947A;
    min-width: 256px;
    height: 60px;
    border-radius: 0px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
}

@media (min-width: 992px) {
    .l9-header-btn .btn {
        border-radius: 0px 0px 5px 0px;
    }
}

.check-input-control {
    margin-bottom: 20px;
}

.check-input-control input:checked~.checkbox {
    background: #1787FC !important;
    border-color: #1787FC;
}

.check-input-control input:checked~.checkbox::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    font-weight: 700;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
}

.check-input-control .checkbox {
    position: relative;
    line-height: 1;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 50%;
    background: #9ea1ab;
    display: inline-block;
    cursor: pointer;
}

.check-input-control .checkbox-2 {
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    background: #fff;
}

/*Checkout page radio button*/
.gr-radio-input input:checked~label .round-indicator {
    border: 1px solid #473bf0;
    background: #fff;
}

.gr-radio-input input:checked~label .round-indicator:after {
    opacity: 1;
}

.gr-radio-input input:checked~label .round-indicator {
    border: 1px solid #1787FC;
    background: #fff;
}

.gr-radio-input input:checked~label .round-indicator:after {
    opacity: 1;
}

.round-indicator {
    position: relative;
    display: inline-block;
    border: 1px solid #E5E5E5;
    background-color: #E5E5E5;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 500px;
}

.round-indicator:after {
    content: "";
    background-color: #473bf0;
    min-width: 12px;
    max-width: 12px;
    min-height: 12px;
    max-height: 12px;
    border-radius: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
}

/*Checkout page radio button*/
.form-control::-moz-placeholder {
    color: #000;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #000;
    opacity: 1;
}

.form-control::placeholder {
    color: #000;
    opacity: 1;
}

.form-control:focus {
    box-shadow: none !important;
}

/*Section heading style-1*/
.section__heading h2 {
    color: #213053;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 44px;
    margin-bottom: 25px;
}

@media (min-width: 480px) {
    .section__heading h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .section__heading h2 {
        font-size: 48px;
        line-height: 58px;
    }
}

.section__heading p {
    opacity: 0.7;
    color: #213053;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 0;
}

/*Section heading style-2*/
.section__heading-2 h2 {
    color: #1D263A;
    font-size: 34px !important;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 42px;
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .section__heading-2 h2 {
        font-size: 38px !important;
        line-height: 45px;
    }
}

@media (min-width: 768px) {
    .section__heading-2 h2 {
        font-size: 48px;
        line-height: 55px;
    }
}

@media (min-width: 992px) {
    .section__heading-2 h2 {
        font-size: 60px !important;
        line-height: 66px;
    }
}

.section__heading-2 p {
    opacity: 0.8;
    color: #1D263A;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 0;
}

/*Section heading style-3*/
.section__heading-3 h2 {
    color: #1D263A;
    font-size: 34px !important;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 42px;
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .section__heading-3 h2 {
        font-size: 38px !important;
        line-height: 45px;
    }
}

@media (min-width: 768px) {
    .section__heading-3 h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.section__heading-3 p {
    opacity: 0.7;
    color: #1D263A;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
    margin-bottom: 0;
}

/*Section heading style-4*/
.section-heading-4 h2 {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -3px;
    line-height: 44px;
    margin-bottom: 35px;
}

@media (min-width: 480px) {
    .section-heading-4 h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .section-heading-4 h2 {
        font-size: 48px;
        line-height: 60px;
    }
}

.section-heading-4 p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #333333;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
    margin-bottom: 0;
}

/*Section heading style-5*/
.section-heading-5 h2 {
    font-family: Gothic A1;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: -2.52632px;
    line-height: 44px;
    margin-bottom: 35px;
}

@media (min-width: 480px) {
    .section-heading-5 h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .section-heading-5 h2 {
        font-size: 48px;
        line-height: 64px;
    }
}

.section-heading-5 p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #333333;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
    margin-bottom: 0;
}

/*Section heading style-6*/
.section-heading-6 h2 {
    font-family: Work Sans;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -2.53px;
    line-height: 44px;
    margin-bottom: 20px;
}

@media (min-width: 480px) {
    .section-heading-6 h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .section-heading-6 h2 {
        font-size: 48px;
        line-height: 56px;
    }
}

.section-heading-6 p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #333333;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
    margin-bottom: 0;
}

/*Section heading style-7*/
.section-heading-7 h2 {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -5px;
    line-height: 38px;
    margin-bottom: 35px;
}

@media (min-width: 480px) {
    .section-heading-7 h2 {
        font-size: 38px;
        line-height: 42px;
    }
}

@media (min-width: 768px) {
    .section-heading-7 h2 {
        font-size: 48px;
        line-height: 52px;
    }
}

@media (min-width: 992px) {
    .section-heading-7 h2 {
        font-size: 60px;
        line-height: 64px;
    }
}

.section-heading-7 p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.3px;
    color: #666666;
    margin-bottom: 0;
}

/*Section heading style-8*/
.section-heading-8 h2 {
    font-family: Rubik;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 46px;
    margin-bottom: 35px;
}

@media (min-width: 480px) {
    .section-heading-8 h2 {
        font-size: 38px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .section-heading-8 h2 {
        letter-spacing: -3px;
        font-size: 48px;
        line-height: 62px;
    }
}

@media (min-width: 992px) {
    .section-heading-8 h2 {
        letter-spacing: -5px;
        font-size: 60px;
        line-height: 74px;
    }
}

.section-heading-8 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.3px;
    color: #666666;
    margin-bottom: 0;
}

/*Section heading style-9*/
.section-heading-9 h2 {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -2.52632px;
    line-height: 40px;
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .section-heading-9 h2 {
        font-size: 38px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .section-heading-9 h2 {
        font-size: 48px;
        line-height: 56px;
    }
}

.section-heading-9 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #E4E4E4;
    margin-bottom: 0;
}

/*Section heading style-10*/
.section-heading-10 h2 {
    font-family: Inter;
    color: #000;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.345px;
    line-height: 44px;
    margin-bottom: 25px;
}

@media (min-width: 480px) {
    .section-heading-10 h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .section-heading-10 h2 {
        font-size: 46px;
        line-height: 56px;
    }
}

.section-heading-10 p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #323232;
    margin-bottom: 0;
}

/*Section heading style-11*/
.section-heading-11 h2 {
    font-family: Inter;
    color: #000;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 46px;
    margin-bottom: 25px;
}

@media (min-width: 480px) {
    .section-heading-11 h2 {
        font-size: 38px;
        line-height: 50px;
    }
}

.section-heading-11 p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #323232;
    margin-bottom: 0;
}

/*Section heading style-12*/
.section-heading-12 h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #5029DE;
    margin-bottom: 15px;
}

.section-heading-12 h2 {
    font-family: Inter;
    color: #000;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 46px;
    margin-bottom: 25px;
}

@media (min-width: 480px) {
    .section-heading-12 h2 {
        font-size: 38px;
        line-height: 50px;
    }
}

.section-heading-12 p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #535659;
    margin-bottom: 0;
}

/*Section heading style-13*/
.section-heading-13 h2 {
    font-family: Nunito;
    color: #2F4B6E;
    font-weight: 800;
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .section-heading-13 h2 {
        font-size: 42px;
        line-height: 52px;
    }
}

.section-heading-13 p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #2F4B6E;
    margin-bottom: 0;
}

/*Section heading style-14*/
.section-heading-14 h2 {
    color: #1D263A;
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .section-heading-14 h2 {
        font-size: 38px;
        line-height: 44px;
    }
}

@media (min-width: 768px) {
    .section-heading-14 h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .section-heading-14 h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.section-heading-14 p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l1 {
    padding-top: 100px;
    padding-bottom: 50px;
}

@media (min-width: 576px) {
    .hero-area-l1 {
        padding-top: 125px;
    }
}

@media (min-width: 768px) {
    .hero-area-l1 {
        padding-top: 140px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .hero-area-l1 {
        padding-top: 200px;
        padding-bottom: 130px;
    }
}

.hero-area-l1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l1 .content {
        padding-top: 0;
    }
}

.hero-area-l1 .content h2 {
    color: #213053;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 35px;
}

@media (min-width: 576px) {
    .hero-area-l1 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l1 .content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l1 .content h2 {
        font-size: 70px;
        line-height: 76px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l1 .content h2 {
        font-size: 80px;
        line-height: 86px;
    }
}

.hero-area-l1 .content p {
    opacity: 0.8;
    color: #213053;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
}

.hero-area-l1 .content span {
    opacity: 0.8;
    color: #213053;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    display: inline-block;
    line-height: 28px;
    margin-top: 23px;
}

.l1-create-acc-btn i {
    margin-left: 30px;
}

.l1-create-acc-btn .btn {
    min-width: 268px;
    height: 69px;
    border-radius: 20px;
    background-color: #FF7139;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    box-shadow: 0 26px 46px rgba(241, 139, 98, 0.5);
    margin-top: 22px;
}

.hero-image-group-l1 {
    position: relative;
}

.hero-image-group-l1 .image-2 {
    position: absolute;
    top: 30%;
    left: 0%;
}

.hero-image-group-l1 .image-3 {
    position: absolute;
    top: 5%;
    right: 0%;
}

.hero-image-group-l1 .image-4 {
    position: absolute;
    bottom: 15%;
    right: -3%;
}

@media (min-width: 1200px) {
    .hero-image-group-l1 .image-4 {
        right: -10%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Features Area-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l1 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-area-l1 {
        padding-top: 75px;
        padding-bottom: 87px;
    }
}

@media (min-width: 992px) {
    .feature-area-l1 {
        padding-top: 110px;
        padding-bottom: 110px;
    }
}

.feature-l1-items {
    margin-bottom: -30px;
}

.feature-l1-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 1200px) {
    .feature-l1-items .content {
        padding-right: 70px;
    }
}

.feature-l1-items .content img {
    margin-bottom: 52px;
}

.feature-l1-items .content h5 {
    color: #213053;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 32px;
    margin-bottom: 30px;
}

.feature-l1-items .content p {
    opacity: 0.8;
    color: #213053;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
}

.feature-border-l1 {
    width: 100%;
    height: 1px;
    border: 1px solid #E6EAF4;
    margin-top: 30px;
    margin-bottom: 50px;
}

@media (min-width: 768px) {
    .feature-border-l1 {
        margin-top: 50px;
        margin-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .feature-border-l1 {
        margin-top: 80px;
        margin-bottom: 100px;
    }
}

.user-rating-box-area {
    padding-top: 45px;
    margin-bottom: -30px;
}

@media (min-width: 992px) {
    .user-rating-box-area {
        padding-top: 0;
    }
}

.user-rating-box-area .col-sm-6 {
    margin-bottom: 30px;
}

.user-rating-box-area .rate-box {
    box-shadow: 0 6px 0 rgba(23, 135, 252, 0.65);
    border-radius: 25px;
    padding: 30px 40px 35px 40px;
}

.user-rating-box-area .rate-box h3 {
    color: #fff;
    font-size: 60px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 66px;
    margin-bottom: 30px;
}

.user-rating-box-area .rate-box p {
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 0;
}

.user-rating-box-area .rate-box ul {
    margin: 0px -3px 7px -3px;
}

.user-rating-box-area .rate-box ul li {
    margin: 0px 3px 0px 3px;
}

.user-rating-box-area .rate-box ul li i {
    color: #FF7139;
    font-size: 18px;
}

.user-rating-box-area .rate-box-2 {
    box-shadow: 0 6px 0 rgba(33, 48, 83, 0.65);
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-1-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-are-l1-1 {
    position: relative;
    z-index: 1;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-are-l1-1 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-are-l1-1 {
        padding-top: 130px;
    }
}

.curve-image-l1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    margin-top: -14px;
}

@media (min-width: 768px) {
    .curve-image-l1 {
        margin-top: -16px;
    }
}

@media (min-width: 992px) {
    .curve-image-l1 {
        margin-top: -11px;
    }
}

@media (min-width: 1200px) {
    .curve-image-l1 {
        margin-top: -30px;
    }
}

.content-box-l1-1 {
    position: relative;
    box-shadow: 0 72px 54px rgba(23, 135, 252, 0.12);
    border-radius: 50px;
    background-color: #fff;
    padding: 30px;
}

@media (min-width: 480px) {
    .content-box-l1-1 {
        padding: 40px 40px 50px 40px;
    }
}

@media (min-width: 768px) {
    .content-box-l1-1 {
        padding: 50px 60px 60px 60px;
    }
}

@media (min-width: 992px) {
    .content-box-l1-1 {
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .content-box-l1-1 {
        padding: 60px 65px 70px 65px;
    }
}

.content-box-l1-1 .cap-image {
    position: absolute;
    right: 0;
    bottom: 12%;
    margin-right: -80px;
}

.l1-get-start-btn .btn {
    width: 247px;
    height: 69px;
    box-shadow: 0 26px 46px rgba(241, 139, 98, 0.5);
    border-radius: 20px;
    background-color: #FF7139;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-top: 40px;
}

.l1-get-start-btn i {
    margin-left: 40px;
}

.content-area-l1-image-group {
    position: relative;
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l1-image-group {
        padding-top: 0;
    }
}

.content-area-l1-image-group .image-2 {
    position: absolute;
    top: 7%;
    right: 25%;
}

.content-area-l1-image-group .image-3 {
    position: absolute;
    bottom: 0;
    left: -15%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-2-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-2-l1 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-2-l1 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-2-l1 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.number-content-box-l1 {
    margin-top: 30px;
    margin-bottom: -60px;
}

@media (min-width: 768px) {
    .number-content-box-l1 {
        margin-top: 50px;
    }
}

@media (min-width: 992px) {
    .number-content-box-l1 {
        margin-top: 70px;
    }
}

.number-content-box-l1 li {
    margin-bottom: 60px;
}

.number-content-box-l1 .number {
    width: 100px;
    height: 66px;
    line-height: 66px;
    box-shadow: 0 5px 0 rgba(23, 135, 252, 0.65);
    border-radius: 20px;
    background-color: #1787FC;
    text-align: center;
}

.number-content-box-l1 .number span {
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.4px;
}

.number-content-box-l1 .number--2 {
    box-shadow: 0 5px 0 rgba(255, 113, 57, 0.65);
    background-color: #FF7139;
}

.number-content-box-l1 .content {
    margin-left: 30px;
}

.number-content-box-l1 .content h4 {
    color: #213053;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 32px;
}

.number-content-box-l1 .content p {
    opacity: 0.8;
    color: #213053;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
}

.content-2-l1-image-group {
    position: relative;
}

.content-2-l1-image-group .image-2 {
    position: absolute;
    top: 17%;
    left: 0%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Testimonial Area-1-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l1 {
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .testimonial-area-l1 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l1 {
        padding-top: 147px;
        padding-bottom: 124px;
    }
}

.testimonial-area-l1-items {
    padding-top: 40px;
    margin-bottom: -30px;
}

.testimonial-area-l1-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .testimonial-area-l1-items {
        padding-top: 60px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l1-items {
        padding-top: 80px;
    }
}

.testimonial-area-l1-items .content {
    padding: 0px 0px;
}

@media (min-width: 1200px) {
    .testimonial-area-l1-items .content {
        padding: 0px 20px;
    }
}

@media (min-width: 1600px) {
    .testimonial-area-l1-items .content {
        padding: 0px 50px;
    }
}

.testimonial-area-l1-items .content p {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0px;
    color: #213053;
    margin-top: 28px;
    margin-bottom: 30px;
}

.testimonial-area-l1-items .content p span {
    border-bottom: 4px solid #FF7139;
}

.testimonial-area-l1-items .content h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    color: #213053;
    margin-bottom: 0;
}

.testimonial-area-l1-items .content small {
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #213053;
    opacity: 0.7;
}

.testimonial-area-l-image-group .image-1 {
    position: absolute;
    top: 10%;
    left: 0%;
}

@media (min-width: 992px) {
    .testimonial-area-l-image-group .image-1 {
        top: 30%;
        left: 10%;
    }
}

.testimonial-area-l-image-group .image-2 {
    position: absolute;
    bottom: 10%;
    right: 10%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Contact form Area-1-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-form-area-l1 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .contact-form-area-l1 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .contact-form-area-l1 {
        padding-top: 115px;
        padding-bottom: 130px;
    }
}

.contact-form-area-l1 .section__heading h2 {
    color: #fff;
}

.contact-form-area-l1 .section__heading p {
    color: #fff;
    opacity: 0.65;
}

.contact-form-l1 {
    margin-top: 10px;
}

@media (min-width: 768px) {
    .contact-form-l1 {
        margin-top: 20px;
    }
}

@media (min-width: 992px) {
    .contact-form-l1 {
        margin-top: 40px;
    }
}

.contact-form-l1 label {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 20px 0px;
}

.contact-form-l1 .form-control {
    background: #FFFFFF;
    border: 1px solid #E6EAF4;
    border-radius: 15px;
    height: 60px;
    font-size: 17px;
    line-height: 30px;
    color: #213053;
    mix-blend-mode: normal;
    opacity: 1;
    padding-left: 22px;
}

.contact-form-l1 .form-control::-moz-placeholder {
    color: #213053;
    opacity: 0.7;
}

.contact-form-l1 .form-control:-ms-input-placeholder {
    color: #213053;
    opacity: 0.7;
}

.contact-form-l1 .form-control::placeholder {
    color: #213053;
    opacity: 0.7;
}

.contact-form-l1 .nice-select {
    color: #213053;
    font-size: 17px;
    height: 60px;
    line-height: 60px;
    padding-left: 22px;
    width: 100%;
    border-radius: 20px;
}

.contact-form-l1 .nice-select .list {
    width: 100%;
}

.contact-form-l1 .nice-select::after {
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    width: 0;
    height: 0;
    transform: rotate(0deg);
    transform-box: view-box;
    right: 30px;
    margin-top: 0;
}

.contact-form-l1 .nice-select.open:after {
    transform: rotate(-180deg);
}

.get-free-demo-btn {
    background: #FF7139;
    height: 70px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 20px;
    margin-top: 30px;
}

.contact-area-l-image-group .image-1 {
    position: absolute;
    top: 15%;
    left: 0%;
}

.contact-area-l-image-group .image-2 {
    position: absolute;
    top: 50%;
    right: 25%;
    transform: translate(-50%, -50%);
}

.contact-area-l-image-group .image-3 {
    position: absolute;
    bottom: 10%;
    right: 0%;
}

@media (min-width: 992px) {
    .contact-area-l-image-group .image-3 {
        right: 20%;
    }
}

.contact-area-l-image-group .image-4 {
    position: absolute;
    bottom: 0%;
    left: 0%;
}

@media (min-width: 992px) {
    .contact-area-l-image-group .image-4 {
        bottom: 0%;
        left: 5%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Footer Area-1-L1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area {
    padding-top: 48px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-area {
        padding-top: 68px;
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-area {
        padding-top: 105px;
        padding-bottom: 90px;
    }
}

.footer-l1-area-items h3 {
    font-size: 15px;
    line-height: 28px;
    color: #213053;
    mix-blend-mode: normal;
    opacity: 0.7;
    margin-bottom: 25px;
}

.footer-l1-area-items ul li a {
    font-weight: 600;
    font-size: 15px;
    line-height: 40px;
    color: #213053;
    text-decoration: none;
}

.subscribe-newsletter {
    padding: 22px 30px 10px 30px;
    border-radius: 25px;
    margin-top: 35px;
    margin-bottom: 35px;
}

@media (min-width: 768px) {
    .subscribe-newsletter {
        margin-bottom: 45px;
    }
}

@media (min-width: 992px) {
    .subscribe-newsletter {
        margin-bottom: 0;
    }
}

.subscribe-newsletter h4 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #213053;
    margin-bottom: 18px;
}

.subscribe-newsletter .form-control {
    font-size: 15px;
    font-weight: 400;
    color: #213053;
    font-style: normal;
    line-height: 28px;
    border: 0;
    height: 60px;
    border-radius: 15px;
    background-color: #fff;
    padding: 0px 18px 0px 18px;
}

@media (min-width: 576px) {
    .subscribe-newsletter .form-control {
        padding: 0px 150px 0px 18px;
    }
}

.subscribe-newsletter .form-control::-moz-placeholder {
    color: #213053;
    opacity: 0.8;
}

.subscribe-newsletter .form-control:-ms-input-placeholder {
    color: #213053;
    opacity: 0.8;
}

.subscribe-newsletter .form-control::placeholder {
    color: #213053;
    opacity: 0.8;
}

.subscribe-newsletter .btn {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    width: 104px;
    height: 50px;
    border-radius: 15px;
    background-color: #FF7139;
    margin-left: 0px;
    margin-top: 9px;
}

@media (min-width: 576px) {
    .subscribe-newsletter .btn {
        height: 43px;
        margin-left: -140px;
    }
}

.subscribe-newsletter p {
    font-size: 15px;
    line-height: 28px;
    color: #213053;
    opacity: 0.7;
    margin-top: 12px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l2 {
    padding-top: 95px;
    padding-bottom: 50px;
}

@media (min-width: 480px) {
    .hero-area-l2 {
        padding-top: 125px;
    }
}

@media (min-width: 768px) {
    .hero-area-l2 {
        padding-top: 145px;
        padding-bottom: 66px;
    }
}

@media (min-width: 992px) {
    .hero-area-l2 {
        padding-bottom: 0;
    }
}

.hero-area-l2 .content {
    padding-top: 45px;
    position: relative;
    z-index: 5;
}

@media (min-width: 992px) {
    .hero-area-l2 .content {
        padding-top: 0;
    }
}

.hero-area-l2 .content h2 {
    color: #213053;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 35px;
}

@media (min-width: 576px) {
    .hero-area-l2 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l2 .content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l2 .content h2 {
        font-size: 70px;
        line-height: 76px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l2 .content h2 {
        font-size: 80px;
        line-height: 86px;
    }
}

.hero-area-l2 .content p {
    opacity: 0.7;
    color: #213053;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    padding-right: 0px;
}

@media (min-width: 1200px) {
    .hero-area-l2 .content p {
        padding-right: 110px;
    }
}

.header-btn-l2 .sign-in-btn {
    font-weight: 600;
    font-size: 17px;
    color: #1D263A;
    min-width: initial;
    padding: 0;
    margin-right: 40px;
}

.header-btn-l2 .register-btn {
    font-weight: 600;
    border-radius: 10px;
    font-size: 17px;
    background: #213053;
    color: #fff;
    min-width: 130px;
    height: 60px;
}

.hero-image-group-l2 {
    position: relative;
}

@media (min-width: 992px) {
    .hero-image-group-l2 {
        margin-left: -80px;
    }
}

.hero-image-group-l2 .image-1 img {
    width: 100%;
}

@media (min-width: 1200px) {
    .hero-image-group-l2 .image-1 img {
        width: 110%;
    }
}

.hero-image-group-l2 .image-2 {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.hero-image-group-l2 .image-2 img {
    width: 300px;
}

@media (min-width: 768px) {
    .hero-image-group-l2 .image-2 img {
        width: 586px;
    }
}

.popular-search-area h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    opacity: 1;
    color: #1D263A;
    padding-top: 32px;
}

.popular-search-area h6 span {
    opacity: 0.7;
    color: #213053;
}

.location-search-area {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .location-search-area {
        margin-top: 55px;
    }
}

.location-search-area label {
    color: #1D263A;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.location-search-area__search-form {
    width: 100%;
    box-shadow: 0px 44px 64px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background-color: #fff;
    padding: 20px 15px 20px 15px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
}

@media (min-width: 768px) {
    .location-search-area__search-form {
        padding: 15px 15px 15px 20px;
        border-radius: 15px;
        width: 100%;
        height: 90px;
    }
}

@media (min-width: 992px) {
    .location-search-area__search-form {
        width: 746px;
    }
}

.location-search-area__search-form .form-control {
    padding-left: 0;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.5;
}

.location-search-area__dropdown {
    width: 100%;
    display: inline-block;
}

@media (min-width: 576px) {
    .location-search-area__dropdown {
        width: 235px;
    }
}

.location-search-area__dropdown .nice-select {
    padding-left: 0;
    height: 25px;
}

.location-search-area__dropdown .nice-select .list {
    width: 200px;
}

.location-search-area__dropdown .nice-select:after {
    border-bottom: 2px solid #FF7139;
    border-right: 2px solid #FF7139;
    height: 7px;
    margin-top: -4px;
    right: 12px;
    top: 70%;
    width: 7px;
}

.location-search-area__dropdown .current {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.5;
}

.location-search-area__dropdown ul li {
    color: #213053;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.location-search-area__search-btn {
    position: relative;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .location-search-area__search-btn {
        margin-top: -4px;
    }
}

.location-search-area__search-btn .btn {
    min-width: 100%;
    height: 50px;
    box-shadow: 21px 15px 23px rgba(65, 111, 244, 0.11);
    border-radius: 10px;
    background-color: #0A043C;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

@media (min-width: 768px) {
    .location-search-area__search-btn .btn {
        min-width: 160px;
        height: 60px;
    }
}

.location-search-area .location-type input {
    min-width: 100%;
}

@media (min-width: 576px) {
    .location-search-area .location-type input {
        margin-top: 0;
        min-width: 260px;
    }
}

@media (min-width: 992px) {
    .location-search-area .location-type input {
        min-width: 315px;
    }
}

.location-search-area .location-type__input {
    margin-top: 20px;
}

@media (min-width: 576px) {
    .location-search-area .location-type__input {
        margin-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Brand Area-L2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l1 {
    padding: 60px 0px;
}

@media (min-width: 768px) {
    .brand-area-l1 {
        padding: 80px 0px;
    }
}

@media (min-width: 992px) {
    .brand-area-l1 {
        padding: 90px 0px;
    }
}

.brand-area-l1 .brand-logos {
    margin: 0px -30px -30px -30px;
}

.brand-area-l1 .brand-logos .single-brand {
    margin: 0px 30px 30px 30px;
}

.brand-bottom-border {
    border-bottom: 1px solid #DFDFDF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Features Area-L2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l2 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-area-l2 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-area-l2 {
        padding-top: 125px;
        padding-bottom: 130px;
    }
}

.feature-l2-items {
    padding-top: 25px;
    margin-bottom: -25px;
}

@media (min-width: 768px) {
    .feature-l2-items {
        padding-top: 35px;
    }
}

@media (min-width: 992px) {
    .feature-l2-items {
        padding-top: 45px;
    }
}

.feature-l2-items .col-xl-3 {
    margin-bottom: 25px;
}

.feature-box-l2 {
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    padding: 30px 15px 7px 25px;
    transition: all 0.4s ease-in-out;
}

.feature-box-l2:hover {
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
}

.feature-box-l2 .color-box {
    height: 24px;
    width: 24px;
}

.feature-box-l2 .content-box {
    margin-left: 25px;
}

.feature-box-l2 .content-box h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #213053;
    margin-top: -6px;
}

.feature-box-l2 .content-box p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Featured Jobs Area-L2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-job-area {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-job-area {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-job-area {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.feature-job-items {
    padding-top: 30px;
    margin-bottom: -25px;
}

@media (min-width: 768px) {
    .feature-job-items {
        padding-top: 50px;
    }
}

@media (min-width: 992px) {
    .feature-job-items {
        padding-top: 87px;
    }
}

.feature-job-items .col-xl-4 {
    margin-bottom: 25px;
}

.feature-job-box {
    background: #FFFFFF;
    border: 1px solid rgba(223, 223, 223, 0.7);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 20px 30px 30px 30px;
    transition: all 0.4s ease-in-out;
}

.feature-job-box:hover {
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
}

.feature-job-box .location {
    margin-bottom: 25px;
}

.feature-job-box .location i {
    color: #7F828A;
    font: 13px;
    margin-right: 12px;
}

.feature-job-box .location span {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
    display: inline-block;
}

.feature-job-box h4 {
    color: #1D263A;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
}

.feature-job-box p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.feature-job-box p span {
    color: #FF7139;
}

.feature-job-box .time-apply-area {
    padding-top: 12px;
}

.feature-job-box .time-apply-area i {
    color: #7F828A;
    font: 13px;
    margin-right: 12px;
}

.feature-job-box .time-apply-area span {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    display: inline-block;
    margin-top: 3px;
}

.view-all-job-btn-l1 .btn {
    min-width: 151px;
    height: 60px;
    background: rgba(255, 135, 57, 0.2);
    border-radius: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FF7139;
    margin-top: 30px;
}

@media (min-width: 992px) {
    .view-all-job-btn-l1 .btn {
        margin-top: 0;
    }
}

.apply-now-btn-fj .btn {
    border-radius: 10px;
    width: 100%;
    height: 50px;
    background: #20BFA9;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
    margin-top: 30px;
}

@media (min-width: 480px) {
    .apply-now-btn-fj .btn {
        min-width: 122px;
        margin-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content Area-L2-1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-are-l2-1 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-are-l2-1 {
        padding-top: 73px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-are-l2-1 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-are-l2-1 .content {
    margin-bottom: 45px;
}

@media (min-width: 1200px) {
    .content-are-l2-1 .content {
        margin-bottom: 0;
    }
}

.content-are-l2-1 .content h2 {
    color: #0A043C;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 44px;
    margin-bottom: 25px;
}

@media (min-width: 480px) {
    .content-are-l2-1 .content h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 768px) {
    .content-are-l2-1 .content h2 {
        font-size: 48px;
        line-height: 58px;
    }
}

@media (min-width: 992px) {
    .content-are-l2-1 .content h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.content-are-l2-1 .content p {
    opacity: 0.7;
    color: #1D263A;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 0;
}

.content-are-l2-1 img {
    border-radius: 10px;
}

.check-lis-btn-l2 .btn {
    background: #FF7139;
    color: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    min-width: 170px;
    height: 60px;
    margin-top: 45px;
}

.content-l2-2-image-1 {
    position: relative;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .content-l2-2-image-1 {
        margin-bottom: 0px;
    }
}

.content-l2-2-image-1 .logo-1 {
    position: absolute;
    top: 10px;
    left: 10px;
}

.content-l2-2-image-2 {
    position: relative;
}

.content-l2-2-image-2 .logo-2 {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.content-l2-2-image-3 {
    position: relative;
    margin-top: 25px;
}

.content-l2-2-image-3 .logo-3 {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Testimonial Area-L2-1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l2 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .testimonial-area-l2 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l2 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.testimonial-slider-l2-1 {
    padding-top: 10px;
}

@media (min-width: 768px) {
    .testimonial-slider-l2-1 {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .testimonial-slider-l2-1 {
        padding-top: 48px;
    }
}

.testimonial-l2-single-card {
    background: #fff;
    border: 1px solid rgba(223, 223, 223, 0.7);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 30px 25px 30px 25px;
}

@media (min-width: 480px) {
    .testimonial-l2-single-card {
        height: 100%;
    }
}

@media (min-width: 768px) {
    .testimonial-l2-single-card .content {
        min-height: 250px;
    }
}

@media (min-width: 992px) {
    .testimonial-l2-single-card .content {
        min-height: 170px;
    }
}

.testimonial-l2-single-card .content p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
}

.testimonial-l2-single-card .user-identity {
    background: rgba(214, 227, 235, 0.3);
    padding: 15px 20px;
    border-radius: 15px;
    margin-top: 40px;
}

.testimonial-l2-single-card .user-identity .user-image img {
    margin: auto;
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .testimonial-l2-single-card .user-identity .user-image img {
        margin-bottom: 0;
        margin-right: 25px;
    }
}

.testimonial-l2-single-card .user-identity .user-details h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.testimonial-l2-single-card .user-identity .user-details p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

.testimonial-slider-l2-1 {
    margin: 0px -15px;
}

.testimonial-slider-l2-1 .single-item {
    margin: 0px 15px;
}

.testimonial-slider-l2-1 .slick-list {
    display: grid;
}

.l2-slider-arrow-1 {
    margin: 0px -5px;
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .l2-slider-arrow-1 {
        margin-bottom: 0px;
    }
}

.l2-slider-arrow-1 .fas {
    margin: 0px 5px;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 25px;
    color: #213053;
    background: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 10px;
}

.l2-slider-arrow-1 .slick-active {
    background: #FF7139;
    color: #fff;
    border: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Footer Area-L2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l2 {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .footer-area-l2 {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .footer-area-l2 {
        padding-top: 120px;
    }
}

.footer-l2-area-items {
    padding-top: 30px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .footer-l2-area-items {
        padding-top: 50px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .footer-l2-area-items {
        padding-top: 92px;
        padding-bottom: 105px;
    }
}

.subscribe-newsletter-l2 {
    position: relative;
    margin-top: 40px;
    width: 100%;
}

@media (min-width: 576px) {
    .subscribe-newsletter-l2 {
        background-color: #fff;
        border-radius: 10px;
        height: 80px;
    }
}

@media (min-width: 768px) {
    .subscribe-newsletter-l2 {
        margin-top: 50px;
        width: 670px;
    }
}

@media (min-width: 992px) {
    .subscribe-newsletter-l2 {
        margin-top: 70px;
    }
}

.subscribe-newsletter-l2 .form-control {
    font-size: 16px;
    font-weight: 400;
    color: #1D263A;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
    border: 0;
    height: 70px;
    border-radius: 10px;
    background-color: #fff;
    padding: 0px 30px 0px 30px;
}

@media (min-width: 576px) {
    .subscribe-newsletter-l2 .form-control {
        height: 80px;
        padding: 0px 187px 0px 30px;
    }
}

.subscribe-newsletter-l2 .form-control::-moz-placeholder {
    color: #1D263A;
    opacity: 0.3;
}

.subscribe-newsletter-l2 .form-control:-ms-input-placeholder {
    color: #1D263A;
    opacity: 0.3;
}

.subscribe-newsletter-l2 .form-control::placeholder {
    color: #1D263A;
    opacity: 0.3;
}

.subscribe-newsletter-l2 .btn {
    color: #fff;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 17px;
    border-radius: 10px;
    background-color: #1D263A;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .subscribe-newsletter-l2 .btn {
        width: 160px;
        margin-top: 0;
        position: absolute;
        right: 10px;
    }
}

.footer-menu-item-l2 {
    margin: 0px -20px;
}

.footer-menu-item-l2 li {
    display: inline-flex;
    flex-wrap: wrap;
}

.footer-menu-item-l2 li a {
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin: 0px 20px;
}

.footer-social-icon-l2 {
    margin: 25px -20px 0px -20px;
}

@media (min-width: 992px) {
    .footer-social-icon-l2 {
        margin: 0px 20px 0px 20px;
    }
}

.footer-social-icon-l2 li {
    display: inline-flex;
    flex-wrap: wrap;
}

.footer-social-icon-l2 li a i {
    font-size: 20px;
    color: #1D263A;
    margin: 0px 20px;
}

.footer-logo-l2 img {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-logo-l2 img {
        margin-bottom: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l3 {
    position: relative;
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 50px;
    overflow: hidden;
}

.hero-area-l3::after {
    position: absolute;
    content: "";
    background: rgba(12, 20, 38, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero-area-l3.background-property {
    background-position: left top !important;
    background-attachment: fixed !important;
}

@media (min-width: 576px) {
    .hero-area-l3 {
        padding-top: 100px;
        padding-bottom: 60px;
    }
}

@media (min-width: 768px) {
    .hero-area-l3 {
        padding-top: 120px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .hero-area-l3 {
        padding-top: 245px;
        padding-bottom: 200px;
    }
}

@media (min-width: 992px) {
    .hero-area-l3 .content {
        padding-top: 0;
    }
}

.hero-area-l3 .content h2 {
    color: #fff;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .hero-area-l3 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l3 .content h2 {
        font-size: 48px;
        line-height: 54px;
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .hero-area-l3 .content h2 {
        font-size: 70px;
        line-height: 77px;
    }
}

.hero-area-l3 .content p {
    opacity: 0.8;
    color: #fff;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
}

.hero-area-l3 .banner-content {
    position: relative;
    z-index: 2;
}

.hero-area-l3 .banner-content .hero-l3-shape-2 {
    position: absolute;
    top: -20%;
    right: 0%;
    z-index: -1;
}

@media (min-width: 768px) {
    .hero-area-l3 .banner-content .hero-l3-shape-2 {
        right: -16%;
    }
}

@media (min-width: 992px) {
    .hero-area-l3 .banner-content .hero-l3-shape-2 {
        right: 0%;
    }
}

@media (min-width: 1200px) {
    .hero-area-l3 .banner-content .hero-l3-shape-2 {
        right: -16%;
    }
}

@media (min-width: 1600px) {
    .hero-area-l3 .banner-content .hero-l3-shape-2 {
        right: -21%;
    }
}

.hero-l3-shape-1 {
    position: absolute;
    bottom: -10%;
    left: 0;
}

.hero-l3-btn-group {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .hero-l3-btn-group {
        margin-top: 50px;
    }
}

.lets-talk-btn {
    background: #20BFA9;
    min-width: 180px;
    height: 60px;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    border-radius: 0;
    color: #fff;
}

@media (min-width: 768px) {
    .lets-talk-btn {
        min-width: 218px;
        height: 85px;
    }
}

.view-works-btn {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
    margin-top: 20px;
}

@media (min-width: 480px) {
    .view-works-btn {
        margin-top: 0;
        margin-left: 30px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Feature Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l3 {
    overflow: hidden;
    border-bottom: 1px solid #DFDFDF;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .feature-area-l3 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .feature-area-l3 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.feature-l3-1-items {
    margin-bottom: -25px;
}

.feature-l3-1-items .col-lg-4 {
    margin-bottom: 25px;
}

@media (min-width: 992px) {
    .feature-l3-1-items .col-lg-4:nth-child(1) {
        border-right: 1px solid #DFDFDF;
    }
}

@media (min-width: 992px) {
    .feature-l3-1-items .col-lg-4:nth-child(2) {
        border-right: 1px solid #DFDFDF;
    }
}

@media (min-width: 992px) {
    .feature-l3-1-items .feature-card {
        padding: 65px 0px 68px 10px;
    }
}

.feature-l3-1-items .feature-card .number span {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #20BFA9;
    opacity: 0.3;
    margin-right: 40px;
}

.feature-l3-1-items .feature-card .content h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: #1D263A;
}

.feature-l3-1-items .feature-card .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Content Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-are-l3-1 {
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .content-are-l3-1 {
        padding-top: 75px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .content-are-l3-1 {
        padding-top: 130px;
        padding-bottom: 120px;
    }
}

.content-l3-1-image {
    position: relative;
    padding-top: 45px;
}

@media (min-width: 768px) {
    .content-l3-1-image {
        padding-top: 50px;
    }
}

@media (min-width: 992px) {
    .content-l3-1-image {
        padding-top: 0px;
    }
}

.content-l3-1-image .counter-box-l3 {
    background: #FF7139;
    padding: 45px 30px 45px 30px;
    box-shadow: 0px 54px 100px -10px rgba(255, 121, 77, 0.3);
}

@media (min-width: 576px) {
    .content-l3-1-image .counter-box-l3 {
        padding: 65px 30px 70px 30px;
    }
}

@media (min-width: 768px) {
    .content-l3-1-image .counter-box-l3 {
        padding: 65px 65px 70px 65px;
        position: absolute;
        top: 50%;
        left: 4%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 992px) {
    .content-l3-1-image .counter-box-l3 {
        left: -10%;
    }
}

.content-l3-1-image .counter-box-l3 .counter-items {
    margin-bottom: -45px;
}

@media (min-width: 576px) {
    .content-l3-1-image .counter-box-l3 .counter-items {
        margin-bottom: -70px;
    }
}

.content-l3-1-image .counter-box-l3 .counter-items .counter-content {
    margin-bottom: 45px;
}

@media (min-width: 576px) {
    .content-l3-1-image .counter-box-l3 .counter-items .counter-content {
        margin-bottom: 70px;
    }
}

.content-l3-1-image .counter-box-l3 h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: -1px;
    color: #fff;
}

@media (min-width: 576px) {
    .content-l3-1-image .counter-box-l3 h2 {
        font-size: 48px;
        line-height: 58px;
    }
}

.content-l3-1-image .counter-box-l3 p {
    font-size: 21px;
    line-height: 32px;
    color: #fff;
    opacity: 0.8;
    margin-top: 10px;
}

.content-are-l3-1-content {
    padding-right: 0px;
}

@media (min-width: 1600px) {
    .content-are-l3-1-content {
        padding-right: 75px;
    }
}

.content-are-l3-1-content .lets-talk-btn {
    margin-top: 48px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Case Study Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.case-study-area-l3 {
    padding-bottom: 10px;
}

@media (min-width: 768px) {
    .case-study-area-l3 {
        padding-bottom: 10px;
    }
}

@media (min-width: 992px) {
    .case-study-area-l3 {
        padding-top: 0;
        padding-bottom: 60px;
    }
}

.case-study-items-l3 {
    margin-bottom: -30px;
    padding-top: 30px;
}

@media (min-width: 992px) {
    .case-study-items-l3 {
        padding-top: 52px;
    }
}

.case-study-items-l3 .col-lg-5 {
    margin-bottom: 30px;
}

.case-study-items-l3 .col-lg-6 {
    margin-bottom: 30px;
}

.case-study-items-l3 .image-content-box .content {
    padding-top: 35px;
    padding-bottom: 25px;
}

@media (min-width: 768px) {
    .case-study-items-l3 .image-content-box .content {
        padding-top: 50px;
        padding-bottom: 45px;
    }
}

.case-study-items-l3 .image-content-box .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
}

.case-study-items-l3 .image-content-box .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Services Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-area-l3 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .service-area-l3 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .service-area-l3 {
        padding-top: 120px;
        padding-bottom: 105px;
    }
}

.service-l3-content h2 {
    color: #000;
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .service-l3-content h2 {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .service-l3-content h2 {
        margin-bottom: 80px;
    }
}

.service-card-area {
    margin-bottom: -20px;
}

@media (min-width: 768px) {
    .service-card-area {
        margin-bottom: -45px;
    }
}

.service-card-area .service-single-card {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .service-card-area .service-single-card {
        margin-bottom: 45px;
    }
}

.service-card-area .service-single-card .icon-box {
    position: relative;
    z-index: 1;
}

.service-card-area .service-single-card .icon-box .icon-bg {
    position: absolute;
    top: -20px;
    left: -15px;
    z-index: -1;
}

@media (min-width: 576px) {
    .service-card-area .service-single-card .icon-box .icon-bg {
        left: -35px;
    }
}

.service-card-area .service-single-card .icon-box .icon-bg-2 {
    position: absolute;
    top: 0;
    left: -10px;
    z-index: -1;
}

@media (min-width: 576px) {
    .service-card-area .service-single-card .icon-box .icon-bg-2 {
        left: -20px;
    }
}

.service-card-area .service-single-card .content-box {
    margin-left: 30px;
}

.service-card-area .service-single-card .content-box h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 20px;
}

.service-card-area .service-single-card .content-box p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.service-l3-card-right {
    position: relative;
    background: #fff;
    padding: 30px 30px 25px 30px;
    margin-top: 45px;
}

@media (min-width: 576px) {
    .service-l3-card-right {
        padding: 40px 30px 25px 30px;
    }
}

@media (min-width: 768px) {
    .service-l3-card-right {
        padding: 50px 65px 45px 65px;
    }
}

@media (min-width: 992px) {
    .service-l3-card-right {
        margin-top: 0;
    }
}

.service-l3-card-right h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 20px;
}

.service-l3-card-right p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.service-l3-card-right .btn {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FF7139;
    padding: 0;
    border: 0;
}

.service-l3-card-right .btn:hover {
    color: #FF7139 !important;
}

.service-l3-card-right .btn i {
    margin-left: 25px;
}

.service-l3-card-right .ser-card-right-shape {
    position: absolute;
    top: -35%;
    right: 2%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Contact form Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-form-l3-area {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .contact-form-l3-area {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .contact-form-l3-area {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.contact-form-l3-area .section__heading h2 {
    color: #fff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .contact-form-l3-area .section__heading h2 {
        margin-bottom: 37px;
    }
}

@media (min-width: 992px) {
    .contact-form-l3-area .section__heading h2 {
        margin-bottom: 57px;
    }
}

@media (min-width: 992px) {
    .contact-form-box {
        margin: 0px 50px;
    }
}

.contact-form-l3 {
    background: #fff;
    padding: 30px;
}

@media (min-width: 576px) {
    .contact-form-l3 {
        padding: 30px 45px 40px 45px;
    }
}

.contact-form-l3 .form-group {
    margin-bottom: 30px;
}

.contact-form-l3 label {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 5px;
}

.contact-form-l3 .form-control {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.5;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(10, 4, 60, 0.15);
    border-radius: 0;
    padding-left: 0;
}

.contact-form-l3 .form-control::-moz-placeholder {
    color: #1D263A;
    opacity: 0.5;
}

.contact-form-l3 .form-control:-ms-input-placeholder {
    color: #1D263A;
    opacity: 0.5;
}

.contact-form-l3 .form-control::placeholder {
    color: #1D263A;
    opacity: 0.5;
}

.contact-form-l3 textarea {
    height: 100px;
}

@media (min-width: 768px) {
    .contact-form-l3 textarea {
        height: 184px;
    }
}

.contact-form-l3 .btn {
    background: #20BFA9;
    border-radius: 0;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
}

@media (min-width: 768px) {
    .contact-form-l3 .btn {
        height: 85px;
    }
}

.contact-l3-image-group .image-1 {
    position: absolute;
    top: 10%;
    left: -12%;
}

@media (min-width: 992px) {
    .contact-l3-image-group .image-1 {
        left: 0%;
    }
}

.contact-l3-image-group .image-2 {
    position: absolute;
    top: 45%;
    left: 27%;
    transform: translate(-50%, -50%);
}

.contact-l3-image-group .image-3 {
    position: absolute;
    top: 24%;
    right: 28%;
    transform: translate(-50%, -50%);
}

.contact-l3-image-group .image-4 {
    position: absolute;
    bottom: 30%;
    right: -6%;
}

@media (min-width: 992px) {
    .contact-l3-image-group .image-4 {
        right: 0%;
    }
}

.contact-l3-image-group .image-5 {
    position: absolute;
    bottom: 18%;
    right: 20%;
    transform: translate(-50%, -50%);
}

.contact-l3-image-group .image-6 {
    position: absolute;
    bottom: -13%;
    left: 10%;
}

@media (min-width: 992px) {
    .contact-l3-image-group .image-6 {
        bottom: -5%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l3 {
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .footer-area-l3 {
        padding-top: 80px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .footer-area-l3 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.footer-l3-area-items {
    margin-bottom: -30px;
}

.footer-l3-area-items .col-xl-4 {
    margin-bottom: 30px;
}

.footer-l3-area-items .col-xl-2 {
    margin-bottom: 30px;
}

.footer-l3-area-items .footer-content-l3 img {
    margin-bottom: 50px;
}

.footer-l3-area-items .footer-content-l3 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.footer-l3-area-items h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
    opacity: 0.6;
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-l3-area-items h3 {
        margin-bottom: 43px;
    }
}

.footer-l3-area-items ul {
    margin-bottom: -20px;
}

.footer-l3-area-items ul li {
    margin-bottom: 20px;
}

.footer-l3-area-items ul li a {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L4
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l4 {
    position: relative;
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 35px;
}

.hero-area-l4.background-property {
    background-position: 75% 0% !important;
}

@media (min-width: 1600px) {
    .hero-area-l4.background-property {
        background-position: 100% 0% !important;
    }
}

@media (min-width: 480px) {
    .hero-area-l4 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .hero-area-l4 {
        padding-top: 150px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .hero-area-l4 {
        padding-top: 245px;
        padding-bottom: 200px;
    }
}

@media (min-width: 992px) {
    .hero-area-l4 .content {
        padding-top: 0;
    }
}

.hero-area-l4 .content h2 {
    color: #fff;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .hero-area-l4 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l4 .content h2 {
        font-size: 48px;
        line-height: 54px;
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .hero-area-l4 .content h2 {
        font-size: 80px;
        line-height: 86px;
        margin-bottom: 55px;
    }
}

.hero-area-l4 .content p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
    margin-top: 40px;
}

.hero-l4-btn .btn {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 4px;
    border-radius: 0;
    color: #1D263A;
    width: 100%;
    height: 60px;
    background: #FFCD28;
}

@media (min-width: 480px) {
    .hero-l4-btn .btn {
        font-size: 17px;
    }
}

@media (min-width: 576px) {
    .hero-l4-btn .btn {
        width: 416px;
        height: 70px;
    }
}

@media (min-width: 768px) {
    .hero-l4-btn .btn {
        width: 416px;
        height: 90px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Counter Area-L4
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.counter-area-l4 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .counter-area-l4 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .counter-area-l4 {
        padding-top: 127px;
        padding-bottom: 130px;
    }
}

.counter-area-l4-items {
    margin-bottom: -30px;
}

.counter-area-l4-items .col-lg-3 {
    margin-bottom: 30px;
}

.counter-area-l4-items .counter-wrapper {
    padding-bottom: 10px;
    border-bottom: 3px solid rgba(29, 36, 58, 0.1);
    transition: all 0.4s ease-in-out;
}

@media (min-width: 768px) {
    .counter-area-l4-items .counter-wrapper {
        margin-right: 30px;
        padding-bottom: 30px;
    }
}

@media (min-width: 1600px) {
    .counter-area-l4-items .counter-wrapper {
        margin-right: 60px;
    }
}

.counter-area-l4-items .counter-wrapper:hover {
    border-bottom: 3px solid #FFCD28;
}

.counter-area-l4-items .count-number h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #1D263A;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .counter-area-l4-items .count-number h2 {
        font-size: 48px;
        line-height: 42px;
        margin-bottom: 40px;
    }
}

.counter-area-l4-items .count-content p {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content Area-L4 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l4-1 {
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .content-area-l4-1 {
        padding-bottom: 67px;
    }
}

@media (min-width: 992px) {
    .content-area-l4-1 {
        padding-bottom: 120px;
    }
}

@media (min-width: 992px) {
    .content-l4-image-1 {
        padding-right: 55px;
    }
}

.content-l4-image-2 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-l4-image-2 {
        padding-top: 0;
    }
}

.content-l4-content-1 {
    padding-top: 45px;
}

@media (min-width: 768px) {
    .content-l4-content-1 {
        padding-top: 0;
    }
}

@media (min-width: 992px) {
    .content-l4-content-1 {
        margin-left: -145px;
    }
}

@media (min-width: 1200px) {
    .content-l4-content-1 {
        padding-right: 15px;
    }
}

@media (min-width: 1600px) {
    .content-l4-content-1 {
        padding-right: 75px;
    }
}

.con-1-l4-learn-btn .btn {
    text-transform: uppercase;
    background: #FFCD28;
    width: 241px;
    height: 76px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 4px;
    border-radius: 0;
    color: #1D263A;
    margin-top: 40px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Servic Area-L4 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-area-l4-1 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .service-area-l4-1 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .service-area-l4-1 {
        padding-bottom: 130px;
    }
}

@media (min-width: 1200px) {
    .service-l4-head-content {
        margin-left: -30px;
    }
}

.service-l4-card-items {
    padding-top: 50px;
    margin-bottom: -30px;
}

@media (min-width: 768px) {
    .service-l4-card-items {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .service-l4-card-items {
        padding-top: 105px;
    }
}

.service-l4-card-items .col-lg-4 {
    margin-bottom: 30px;
}

.service-l4-card {
    padding: 45px 0px 0px 50px;
    background: #EBF5FF;
    transition: all 0.4s ease-in-out;
}

.service-l4-card .content {
    padding-right: 30px;
}

.service-l4-card h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.service-l4-card p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    padding: 25px 0px 25px 0px;
}

.service-l4-card .number-icon-area .number h1 {
    font-weight: 600;
    font-size: 96px;
    line-height: 65px;
    letter-spacing: -1px;
    color: #000;
    opacity: 0.05;
    margin-bottom: 0;
    margin-right: -4px;
    transition: all 0.4s ease-in-out;
}

.service-l4-card:hover {
    background: #FFCD28;
}

.service-l4-card:hover .number h1 {
    opacity: 1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Testimonial Area-L4
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.tastimonial-area-l4 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .tastimonial-area-l4 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .tastimonial-area-l4 {
        padding-bottom: 130px;
    }
}

.testimonial-l4-content .quote-icon img {
    margin-right: 40px;
}

.testimonial-l4-content .content-box h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #222831;
}

.testimonial-l4-content .user-identity {
    margin-top: 55px;
}

.testimonial-l4-content .user-identity img {
    margin-right: 20px;
}

.testimonial-l4-content .user-identity h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1D263A;
    margin-bottom: 4px;
}

.testimonial-l4-content .user-identity span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content Area-L4 2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l4-12 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l4-12 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l4-12 {
        padding-bottom: 100px;
    }
}

.content-area-l4-12 .col-lg-6 {
    padding: 0;
}

.content-area-l4-12 .container {
    padding: 0px 25px;
}

.contant-area-2-l4-content {
    height: 100%;
    padding: 45px 30px 55px 30px;
}

@media (min-width: 480px) {
    .contant-area-2-l4-content {
        padding: 45px 55px 55px 55px;
    }
}

@media (min-width: 992px) {
    .contant-area-2-l4-content {
        padding: 50px 25px 40px 25px;
    }
}

@media (min-width: 1200px) {
    .contant-area-2-l4-content {
        padding: 72px 50px 50px 50px;
    }
}

@media (min-width: 1600px) {
    .contant-area-2-l4-content {
        padding: 125px 95px 125px 95px;
    }
}

.contant-area-2-l4-content h2 {
    color: #fff;
    margin-bottom: 35px;
    font-size: 36px;
}

@media (min-width: 992px) {
    .contant-area-2-l4-content h2 {
        font-size: 38px;
        margin-bottom: 25px;
    }
}

@media (min-width: 1200px) {
    .contant-area-2-l4-content h2 {
        margin-bottom: 35px;
        font-size: 48px;
    }
}

.contant-area-2-l4-content p {
    color: #fff;
    opacity: 0.8;
}

.l4-learn-btn .btn {
    text-transform: uppercase;
    background: #FFCD28;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    line-height: 26px;
    letter-spacing: 4px;
    color: #1D263A;
    margin-top: 50px;
}

@media (min-width: 480px) {
    .l4-learn-btn .btn {
        width: 242px;
        height: 76px;
    }
}

@media (min-width: 992px) {
    .l4-learn-btn .btn {
        height: 60px;
    }
}

@media (min-width: 1200px) {
    .l4-learn-btn .btn {
        height: 76px;
    }
}

.cta-l4-items {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .cta-l4-items {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .cta-l4-items {
        padding-top: 120px;
    }
}

.cta-l4-btn .btn {
    text-transform: uppercase;
    background: #FFCD28;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    line-height: 26px;
    letter-spacing: 4px;
    color: #1D263A;
    margin-top: 45px;
}

@media (min-width: 480px) {
    .cta-l4-btn .btn {
        width: 294px;
        height: 76px;
    }
}

@media (min-width: 992px) {
    .cta-l4-btn .btn {
        margin-top: 0;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .cta-l4-btn .btn {
        width: 294px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L4 
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l4 {
    padding-top: 55px;
    padding-bottom: 35px;
}

@media (min-width: 768px) {
    .footer-area-l4 {
        padding-top: 75px;
        padding-bottom: 45px;
    }
}

@media (min-width: 992px) {
    .footer-area-l4 {
        padding-top: 125px;
    }
}

.footer-l4-area-items h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 3px;
    color: #fff;
    opacity: 0.5;
    margin-bottom: 35px;
}

@media (min-width: 768px) {
    .footer-l4-area-items h3 {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .footer-l4-area-items h3 {
        margin-bottom: 45px;
    }
}

.footer-l4-area-items p {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
}

.contact-form-l4 .name-email-area {
    margin: 0px -10px 0px -10px;
}

.contact-form-l4 .name-email-area .form-group {
    margin: 0px 10px 0px 10px;
}

.contact-form-l4 .form-group {
    margin-bottom: 20px !important;
}

@media (min-width: 576px) {
    .contact-form-l4 .form-group {
        width: 100%;
    }
}

.contact-form-l4 .form-control {
    background: transparent;
    border: 1px solid #828282;
    height: 65px;
    font-size: 15px;
    line-height: 28px;
    color: #828282;
    border-radius: 0;
    padding-left: 25px;
}

.contact-form-l4 .form-control::-moz-placeholder {
    color: #828282;
}

.contact-form-l4 .form-control:-ms-input-placeholder {
    color: #828282;
}

.contact-form-l4 .form-control::placeholder {
    color: #828282;
}

.contact-form-l4 .send-btn .btn {
    text-transform: uppercase;
    background: #FFCD28;
    width: 197px;
    height: 65px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    line-height: 26px;
    letter-spacing: 3px;
    color: #1D263A;
}

.footer-social-icon-l4 {
    margin: 35px -15px 0px -15px;
    margin-bottom: 45px;
}

@media (min-width: 992px) {
    .footer-social-icon-l4 {
        margin-bottom: 0;
    }
}

.footer-social-icon-l4 li {
    display: inline-flex;
    margin: 0px 15px 0px 15px;
}

.footer-social-icon-l4 li a {
    color: #fff;
    font-size: 20px;
}

.copyright-area-l4 p {
    font-size: 15px;
    line-height: 28px;
    color: #fff;
    opacity: 0.7;
    margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l5 {
    background: rgba(250, 229, 218, 0.4);
    position: relative;
    z-index: 1;
    padding-top: 43px;
    padding-bottom: 55px;
    margin: 70px 0px 0px 0px;
}

@media (min-width: 480px) {
    .hero-area-l5 {
        margin: 80px 0px 0px 0px;
    }
}

@media (min-width: 768px) {
    .hero-area-l5 {
        padding-top: 68px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .hero-area-l5 {
        margin: 90px 0px 0px 0px;
    }
}

@media (min-width: 1366px) {
    .hero-area-l5 {
        border-radius: 30px;
        margin: 80px 60px 0px 60px;
        padding-bottom: 90px;
    }
}

.hero-area-l5 .hero-l5-content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l5 .hero-l5-content {
        padding-top: 0;
    }
}

.hero-area-l5 .hero-l5-content h4 {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 6px;
    color: #FF794D;
    margin-bottom: 30px;
}

.hero-area-l5 .hero-l5-content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .hero-area-l5 .hero-l5-content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l5 .hero-l5-content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l5 .hero-l5-content h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.hero-area-l5 .hero-l5-content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .hero-area-l5 .hero-l5-content p {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .hero-area-l5 .hero-l5-content p {
        margin-bottom: 60px;
    }
}

.btn-l5 a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 4px;
    color: #1D263A;
}

.btn-l5 a span {
    display: inline-block;
    width: 40px;
    height: 4px;
    background: #FF794D;
    margin-right: 25px;
}

.hero-l5-image {
    z-index: 1;
}

.hero-l5-image .hero-shape-1 {
    position: absolute;
    top: 8%;
    right: -3%;
    z-index: -1;
}

@media (min-width: 480px) {
    .hero-l5-image .hero-shape-1 {
        right: 0%;
    }
}

@media (min-width: 992px) {
    .hero-l5-image .hero-shape-1 {
        top: -8%;
        left: -30px;
    }
}

@media (min-width: 1200px) {
    .hero-l5-image .hero-shape-1 {
        top: 8%;
        left: 22%;
    }
}

@media (min-width: 1600px) {
    .hero-l5-image .hero-shape-1 {
        left: 39%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l5 {
    padding-top: 55px;
}

@media (min-width: 768px) {
    .feature-area-l5 {
        padding-top: 75px;
    }
}

@media (min-width: 992px) {
    .feature-area-l5 {
        padding-top: 100px;
    }
}

.feature-area-l5-items {
    margin-bottom: -30px;
}

.feature-area-l5-items .col-lg-4 {
    margin-bottom: 30px;
}

.feature-card-l5 .icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    text-align: center;
    background: rgba(214, 227, 235, 0.4);
    display: inline-table;
}

.feature-card-l5 .icon img {
    width: 32px;
    height: 32px;
}

.feature-card-l5 .icon.icon-2 {
    background: rgba(250, 229, 218, 0.4);
}

.feature-card-l5 .icon.icon-3 {
    background: #EBF5FF;
}

.feature-card-l5 .content {
    margin-left: 35px;
}

.feature-card-l5 .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 15px;
}

.feature-card-l5 .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Product Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.product-area-l5 {
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .product-area-l5 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .product-area-l5 {
        padding-bottom: 125px;
    }
}

.product-area-l5 h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .product-area-l5 h2 {
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .product-area-l5 h2 {
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 55px;
    }
}

.product-area-l5 .border-area-l4 {
    border-bottom: 2px dashed rgba(29, 36, 58, 0.17);
    margin: 42px 0px 53px 0px;
}

@media (min-width: 768px) {
    .product-area-l5 .border-area-l4 {
        margin: 62px 0px 73px 0px;
    }
}

@media (min-width: 992px) {
    .product-area-l5 .border-area-l4 {
        margin: 90px 0px 100px 0px;
    }
}

.l5-product-slider {
    margin: 0px -13px 30px -13px;
}

@media (min-width: 768px) {
    .l5-product-slider {
        margin: 0px -13px 0px -13px;
    }
}

.l5-product-slider i {
    font-size: 20px;
    color: #ddd;
    margin: 0px 13px;
    cursor: pointer;
}

.l5-product-slider .slick-active {
    color: #1D263A;
}

.product-slider-l5 {
    margin: 0px -15px;
}

.product-slider-l5 .single-item {
    margin: 0px 15px;
}

.product-card-l5 .product-content-card {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 100%);
    padding-left: 30px;
    padding-right: 30px;
    transition: .4s;
}

.product-card-l5 .product-content-card .content {
    background: #fff;
    box-shadow: 0px 34px 50px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 25px 30px 25px 30px;
}

.product-card-l5 .product-content-card .content h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #FF794D;
    margin-bottom: 13px;
}

.product-card-l5 .product-content-card .content a {
    color: #1D263A;
}

.product-card-l5 .product-content-card .content p {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #1D263A;
    margin-bottom: 0;
}

.product-card-l5:hover .product-content-card {
    transform: translate(-50%, -25%);
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-1 {
    padding-bottom: 10px;
}

@media (min-width: 768px) {
    .content-area-l5-1 {
        padding-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .content-area-l5-1 {
        padding-bottom: 25px;
    }
}

.content-area-l5-1 .section-heading {
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .content-area-l5-1 .section-heading {
        padding-bottom: 35px;
    }
}

@media (min-width: 992px) {
    .content-area-l5-1 .section-heading {
        padding-bottom: 65px;
    }
}

.content-area-l5-1 .section-heading h2 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 4px;
    color: #1D263A;
    opacity: 0.7;
}

.content-process-area-l5 {
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .content-process-area-l5 {
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .content-process-area-l5 {
        padding-bottom: 75px;
    }
}

.content-process-area-l5 span {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: #20BFA9;
}

.content-process-area-l5 h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-top: 20px;
}

@media (min-width: 992px) {
    .content-process-area-l5 h2 {
        margin-top: 0;
    }
}

.content-process-area-l5 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.6;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-2 Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-2 {
    background: rgba(29, 36, 58, 0.02);
    padding-top: 60px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .content-area-l5-2 {
        padding-top: 80px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .content-area-l5-2 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-l5-2-content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-l5-2-content {
        padding-top: 0;
    }
}

.content-l5-2-content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 32px;
}

@media (min-width: 768px) {
    .content-l5-2-content h2 {
        font-size: 38px;
        line-height: 46px;
    }
}

@media (min-width: 992px) {
    .content-l5-2-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.content-l5-2-content p {
    font-size: 17px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #1D263A;
    opacity: 0.6;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .content-l5-2-content p {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .content-l5-2-content p {
        margin-bottom: 48px;
    }
}

.content-l5-2-image {
    position: relative;
}

.content-l5-2-image .shape-1 {
    position: absolute;
    bottom: 10%;
    left: -15%;
}

@media (min-width: 992px) {
    .content-l5-2-image .shape-1 {
        bottom: -15%;
        right: 22%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-3 Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-3 .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}

.content-l5-3-content {
    padding: 50px 30px 50px 30px;
}

@media (min-width: 768px) {
    .content-l5-3-content {
        padding: 85px 100px 70px 100px;
    }
}

@media (min-width: 992px) {
    .content-l5-3-content {
        padding: 85px 30px 70px 30px;
    }
}

@media (min-width: 1200px) {
    .content-l5-3-content {
        padding: 200px 100px 200px 60px;
    }
}

@media (min-width: 1600px) {
    .content-l5-3-content {
        padding: 200px 100px 200px 315px;
    }
}

.content-l5-3-content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #FFFFFF;
}

@media (min-width: 768px) {
    .content-l5-3-content h2 {
        font-size: 38px;
        line-height: 46px;
    }
}

@media (min-width: 992px) {
    .content-l5-3-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.content-l5-3-content .identity {
    padding-top: 20px;
}

@media (min-width: 768px) {
    .content-l5-3-content .identity {
        padding-top: 30px;
    }
}

@media (min-width: 992px) {
    .content-l5-3-content .identity {
        padding-top: 57px;
    }
}

.content-l5-3-content .identity .identity-left-border span {
    display: inline-block;
    width: 40px;
    height: 4px;
    background: #fff;
    margin-right: 25px;
}

.content-l5-3-content .identity .content h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
}

.content-l5-3-content .identity .content span {
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    opacity: 0.8;
}

.video-l5-bg {
    height: 450px !important;
}

@media (min-width: 992px) {
    .video-l5-bg {
        height: 100% !important;
    }
}

.video-area-l5 a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-area-l5 a i {
    color: #fff;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    line-height: 92px;
    text-align: center;
    background: transparent;
    border: 3px solid #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Newsletter Area-L5
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.newsletter-area-l5 {
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .newsletter-area-l5 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .newsletter-area-l5 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.newsletter-content-l5 h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    opacity: 0.9;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .newsletter-content-l5 h2 {
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 42px;
    }
}

@media (min-width: 992px) {
    .newsletter-content-l5 h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.newsletter-content-l5 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

.newsletter-form-l5 {
    padding-top: 40px;
    padding-bottom: 32px;
}

.newsletter-form-l5 .form-control {
    height: 70px;
    border: 1px solid rgba(223, 223, 223, 0.7);
    border-radius: 10px;
    font-size: 17px;
    line-height: 30px;
    padding-left: 28px;
}

@media (min-width: 576px) {
    .newsletter-form-l5 .form-control {
        border-radius: 10px 0px 0px 10px;
    }
}

.newsletter-form-l5 .form-control::-moz-placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.newsletter-form-l5 .form-control:-ms-input-placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.newsletter-form-l5 .form-control::placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.newsletter-form-l5 .btn {
    background: #20BFA9;
    width: 100%;
    height: 65px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 10px;
    margin-top: 15px;
}

@media (min-width: 576px) {
    .newsletter-form-l5 .btn {
        margin-top: 0;
        min-width: 200px;
        height: 70px;
        border-radius: 0px 10px 10px 0px;
    }
}

.news-ltr-l5-bottom .icon {
    margin-right: 12px;
}

.news-ltr-l5-bottom .content span {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l5 {
    background: rgba(250, 229, 218, 0.4);
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .footer-area-l5 {
        padding-top: 75px;
        padding-bottom: 70px;
        margin: 0px;
    }
}

@media (min-width: 1366px) {
    .footer-area-l5 {
        border-radius: 30px;
        padding-top: 125px;
        padding-bottom: 120px;
        margin: 0px 60px 60px 60px;
    }
}

.footer-l5-area-items {
    margin-bottom: -30px;
}

.footer-l5-area-items .col-xl-3 {
    margin-bottom: 30px;
}

.footer-l5-area-items .col-xl-2 {
    margin-bottom: 30px;
}

.footer-l5-area-items .footer-content-l5 img {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .footer-l5-area-items .footer-content-l5 img {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .footer-l5-area-items .footer-content-l5 img {
        margin-bottom: 80px;
    }
}

.footer-l5-area-items .footer-content-l5 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

.footer-l5-area-items .copyright-text-l5 {
    margin-top: 20px;
}

@media (min-width: 992px) {
    .footer-l5-area-items .copyright-text-l5 {
        margin-top: 40px;
    }
}

.footer-l5-area-items h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 4px;
    color: #1D263A;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-l5-area-items h3 {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-l5-area-items h3 {
        margin-bottom: 75px;
    }
}

.footer-l5-area-items ul {
    margin-bottom: -20px;
}

.footer-l5-area-items ul li {
    margin-bottom: 20px;
}

.footer-l5-area-items ul li a {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L6
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l6 {
    background: linear-gradient(180deg, rgba(23, 135, 252, 0.26) 0%, rgba(32, 191, 169, 0.16) 55.21%, rgba(199, 210, 240, 0) 100%);
    position: relative;
    z-index: 1;
    padding-top: 43px;
}

@media (min-width: 480px) {
    .hero-area-l6 {
        padding-top: 60px;
    }
}

@media (min-width: 768px) {
    .hero-area-l6 {
        padding-top: 60px;
    }
}

@media (min-width: 992px) {
    .hero-area-l6 {
        padding-top: 187px;
    }
}

.hero-area-l6 .hero-l6-content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l6 .hero-l6-content {
        padding-top: 0;
    }
}

.hero-area-l6 .hero-l6-content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .hero-area-l6 .hero-l6-content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l6 .hero-l6-content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l6 .hero-l6-content h2 {
        font-size: 80px;
        line-height: 86px;
    }
}

.hero-btn-l6 {
    margin-top: 35px;
}

@media (min-width: 768px) {
    .hero-btn-l6 {
        margin-top: 45px;
    }
}

@media (min-width: 992px) {
    .hero-btn-l6 {
        margin-top: 55px;
    }
}

.hero-btn-l6 .btn {
    background: #1D263A;
    border-radius: 10px;
    width: 258px;
    height: 55px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
}

.hero-l6-content-image {
    margin-top: 60px;
}

@media (min-width: 768px) {
    .hero-l6-content-image {
        margin-top: 80px;
    }
}

@media (min-width: 992px) {
    .hero-l6-content-image {
        margin-top: 130px;
    }
}

.hero-l6-content-image img {
    box-shadow: 0px 204px 150px -30px rgba(10, 4, 60, 0.05);
    border-radius: 0px 0px 30px 30px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content Area-L6 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l6-1 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l6-1 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l6-1 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-box-l6-1 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-box-l6-1 {
        padding-top: 0;
    }
}

.content-image-group-l6-1 .bg-image img {
    width: 100%;
}

@media (min-width: 1200px) {
    .content-image-group-l6-1 .bg-image img {
        width: initial;
    }
}

.content-image-group-l6-1 .main-image-group .image-1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
    .content-image-group-l6-1 .main-image-group .image-1 {
        top: 65%;
    }
}

.content-image-group-l6-1 .main-image-group .image-1 img {
    box-shadow: 0px -10px 104px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content Area-L6 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l6-2 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l6-2 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l6-2 {
        padding-bottom: 130px;
    }
}

.content-box-l6-2 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-box-l6-2 {
        padding-top: 0;
    }
}

.content-image-group-l6-2 .bg-image img {
    width: 100%;
}

@media (min-width: 1200px) {
    .content-image-group-l6-2 .bg-image img {
        width: initial;
    }
}

.content-image-group-l6-2 .main-image-group .image-1 {
    position: absolute;
    left: 0%;
    top: 15%;
}

@media (min-width: 992px) {
    .content-image-group-l6-2 .main-image-group .image-1 {
        left: -25%;
    }
}

@media (min-width: 1200px) {
    .content-image-group-l6-2 .main-image-group .image-1 {
        left: -8%;
    }
}

@media (min-width: 1600px) {
    .content-image-group-l6-2 .main-image-group .image-1 {
        left: 0%;
    }
}

.content-image-group-l6-2 .main-image-group .image-1 img {
    border-radius: 0px 20px 20px 0px;
}

.content-image-group-l6-2 .main-image-group .image-2 {
    position: absolute;
    left: 3%;
    right: inherit;
    top: 0%;
}

@media (min-width: 480px) {
    .content-image-group-l6-2 .main-image-group .image-2 {
        left: 18%;
    }
}

@media (min-width: 576px) {
    .content-image-group-l6-2 .main-image-group .image-2 {
        left: inherit;
        right: -4%;
        top: 30%;
    }
}

@media (min-width: 992px) {
    .content-image-group-l6-2 .main-image-group .image-2 {
        right: -5%;
    }
}

@media (min-width: 1200px) {
    .content-image-group-l6-2 .main-image-group .image-2 {
        right: -10%;
    }
}

@media (min-width: 1600px) {
    .content-image-group-l6-2 .main-image-group .image-2 {
        right: -3%;
    }
}

.content-image-group-l6-2 .main-image-group .image-2 img {
    box-shadow: 0px -10px 104px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.content-image-group-l6-2 .main-image-group .image-3 {
    position: absolute;
    left: 6%;
    bottom: 2%;
}

@media (min-width: 992px) {
    .content-image-group-l6-2 .main-image-group .image-3 {
        left: 0%;
    }
}

@media (min-width: 1200px) {
    .content-image-group-l6-2 .main-image-group .image-3 {
        left: 5%;
    }
}

@media (min-width: 1600px) {
    .content-image-group-l6-2 .main-image-group .image-3 {
        left: 10%;
    }
}

.content-image-group-l6-2 .main-image-group .image-3 img {
    border-radius: 0px 20px 0px 0px;
}

@media (min-width: 992px) {
    .content-image-group-l6-2 .main-image-group img {
        width: 80%;
    }
}

@media (min-width: 1200px) {
    .content-image-group-l6-2 .main-image-group img {
        width: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content Area-L6 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l6-3 {
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .content-area-l6-3 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .content-area-l6-3 {
        padding-bottom: 155px;
    }
}

.content-box-l6-3 {
    padding-top: 90px;
}

@media (min-width: 480px) {
    .content-box-l6-3 {
        padding-top: 45px;
    }
}

@media (min-width: 992px) {
    .content-box-l6-3 {
        padding-top: 0;
    }
}

.content-image-group-l6-3 .bg-image img {
    width: 100%;
}

@media (min-width: 1200px) {
    .content-image-group-l6-3 .bg-image img {
        width: initial;
    }
}

.content-image-group-l6-3 .main-image-group .image-1 {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
    .content-image-group-l6-3 .main-image-group .image-1 {
        left: 70%;
    }
}

.content-image-group-l6-3 .main-image-group .image-1 img {
    box-shadow: 0px -10px 104px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    width: 300px;
}

@media (min-width: 576px) {
    .content-image-group-l6-3 .main-image-group .image-1 img {
        width: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Pricing Area-L6 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l6 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .pricing-area-l6 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l6 {
        padding-bottom: 130px;
    }
}

.pricing-area-l6 .section__heading {
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .pricing-area-l6 .section__heading {
        padding-bottom: 40px;
    }
}

.price-area-items-l6 {
    margin-bottom: -30px;
}

.price-area-items-l6 .col-xl-4 {
    margin-bottom: 30px;
}

.price-card-l6 {
    padding: 40px 30px 30px 40px;
    border: 1px solid rgba(29, 36, 58, 0.12);
    border-radius: 20px;
}

@media (min-width: 576px) {
    .price-card-l6 {
        padding: 50px 60px 50px 60px;
    }
}

.price-card-l6 i {
    color: #20BFA9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    line-height: 80px;
    text-align: center;
    background: #D6EBE4;
}

.price-card-l6 h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin: 40px 0px 15px 0px;
}

.price-card-l6 p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
}

.price-l6-btn {
    margin-top: 43px;
}

.price-l6-btn .btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
    min-width: 182px;
    height: 55px;
    border-radius: 10px;
    background: #FF794D;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   CTA Area-L6 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l6-bg {
    padding: 50px 30px 60px 30px;
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.08);
    border-radius: 20px;
}

@media (min-width: 768px) {
    .cta-area-l6-bg {
        padding: 70px 30px 80px 30px;
    }
}

@media (min-width: 992px) {
    .cta-area-l6-bg {
        padding: 105px 50px 110px 50px;
    }
}

.cta-area-l6-content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 35px;
}

@media (min-width: 576px) {
    .cta-area-l6-content h2 {
        font-size: 38px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .cta-area-l6-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.google-chrome-btn .btn {
    min-width: 250px;
    height: 55px;
    background: #2E2E36;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Footer Area-L6
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l6 {
    padding-top: 80px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .footer-area-l6 {
        padding-top: 100px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .footer-area-l6 {
        padding-top: 140px;
        padding-bottom: 100px;
    }
}

.footer-content-l6 img {
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .footer-content-l6 img {
        margin-bottom: 0;
        margin-right: 50px;
    }
}

.copyright-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: -7px;
}

.footer-menu-item-l6 {
    margin: 30px -20px 0px -20px;
}

@media (min-width: 992px) {
    .footer-menu-item-l6 {
        margin: 0px -20px 0px -20px;
    }
}

.footer-menu-item-l6 li {
    display: inline-flex;
    flex-wrap: wrap;
}

.footer-menu-item-l6 li a {
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    margin: 0px 20px 0px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l7 {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 20px;
    margin: 70px 0px 0px 0px;
}

@media (min-width: 480px) {
    .hero-area-l7 {
        margin: 80px 0px 0px 0px;
    }
}

@media (min-width: 768px) {
    .hero-area-l7 {
        padding-top: 80px;
        padding-bottom: 32px;
    }
}

@media (min-width: 992px) {
    .hero-area-l7 {
        margin: 90px 0px 0px 0px;
        padding-top: 150px;
        padding-bottom: 150px;
    }
}

@media (min-width: 1366px) {
    .hero-area-l7 {
        border-radius: 15px;
        margin: 90px 20px 0px 20px;
    }
}

.hero-area-l7 .hero-l7-content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l7 .hero-l7-content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l7 .hero-l7-content {
        padding-right: 35px;
    }
}

.hero-area-l7 .hero-l7-content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #fff;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .hero-area-l7 .hero-l7-content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l7 .hero-l7-content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l7 .hero-l7-content h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.hero-area-l7 .hero-l7-content p {
    font-size: 17px;
    line-height: 30px;
    color: #fff;
    opacity: 0.8;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .hero-area-l7 .hero-l7-content p {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .hero-area-l7 .hero-l7-content p {
        margin-bottom: 60px;
    }
}

.hero-slider-single-item .image {
    border-radius: 15px 15px 0px 0px !important;
}

.hero-slider-single-item .image img {
    border-radius: 15px 15px 0px 0px;
}

.hero-slider-single-item .content-box {
    background: #fff;
    border-radius: 0px 0px 15px 15px;
    padding: 25px;
}

.hero-slider-single-item .content-box .item {
    margin: 0px 8px;
}

.hero-slider-single-item .content-box p {
    font-size: 13px;
    line-height: 22px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 3px;
}

.hero-slider-single-item .content-box span {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #20BFA9;
}

.view-property-btn {
    margin-top: 20px !important;
}

@media (min-width: 480px) {
    .view-property-btn {
        margin-top: 0 !important;
    }
}

.view-property-btn .btn {
    background: #1787FC;
    border-radius: 5px;
    min-width: 132px;
    height: 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
}

.hero-l7-slider .slick-dots {
    margin-top: 60px;
    justify-content: left;
}

.hero-l7-slider .slick-dots li {
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px;
    transition: 0.4s;
    cursor: pointer;
}

.hero-l7-slider .slick-dots li button {
    background-color: transparent;
    border: none;
    color: transparent;
}

.hero-l7-slider .slick-dots {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    justify-content: center;
    border-radius: 4px;
}

.hero-l7-slider .slick-dots li.slick-active {
    background-color: #20BFA9;
}

.hero-l7-slider .slick-dots li button:focus {
    outline: none;
    box-shadow: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l7 {
    padding-top: 55px;
}

@media (min-width: 768px) {
    .feature-area-l7 {
        padding-top: 75px;
    }
}

@media (min-width: 992px) {
    .feature-area-l7 {
        padding-top: 100px;
    }
}

.feature-area-l7-items {
    margin-bottom: -30px;
}

.feature-area-l7-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .feature-card-l7 {
        padding-right: 45px;
    }
}

@media (min-width: 992px) {
    .feature-card-l7 {
        padding-right: 5px;
    }
}

@media (min-width: 1200px) {
    .feature-card-l7 {
        padding-right: 65px;
    }
}

@media (min-width: 1600px) {
    .feature-card-l7 {
        padding-right: 125px;
    }
}

.feature-card-l7 .icon {
    margin-bottom: 45px;
}

.feature-card-l7 .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 15px;
}

.feature-card-l7 .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area 1-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l7-1 {
    background: #D6EBE4;
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 40px 0px 0px 0px;
}

@media (min-width: 768px) {
    .content-area-l7-1 {
        margin: 57px 0px 0px 0px;
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 1366px) {
    .content-area-l7-1 {
        border-radius: 15px;
        margin: 80px 20px 0px 20px;
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-area-l7-1 .content-l7-1-content-box {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l7-1 .content-l7-1-content-box {
        padding-top: 0;
    }
}

@media (min-width: 1600px) {
    .content-area-l7-1 .content-l7-1-content-box {
        padding-right: 75px;
    }
}

.content-1-l7-service {
    padding-top: 30px;
}

@media (min-width: 768px) {
    .content-1-l7-service {
        padding-top: 43px;
    }
}

@media (min-width: 992px) {
    .content-1-l7-service {
        padding-top: 63px;
    }
}

.content-1-l7-service .content-box {
    margin-bottom: 30px;
}

.content-1-l7-service .content-box .item {
    width: 20%;
}

.content-1-l7-service .content-box p {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
    margin-bottom: 3px;
}

.content-1-l7-service .content-box span {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
}

.content-1-l7-image img {
    border: 8px solid #fff;
    border-radius: 15px;
}

.content-1-l7-btn-group {
    margin-top: 55px;
}

@media (min-width: 480px) {
    .content-1-l7-btn-group {
        margin: 55px -5px 0px -5px;
    }

    .content-1-l7-btn-group a {
        margin: 0px 5px;
    }
}

.l7-view-pro-btn {
    background: #fff;
    min-width: 100%;
    height: 55px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    border-radius: 3px;
    color: #1D263A;
}

@media (min-width: 480px) {
    .l7-view-pro-btn {
        min-width: 200px;
    }
}

.l7-book-pro-btn {
    background: #20BFA9;
    min-width: 100%;
    height: 55px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    border-radius: 3px;
    color: #fff;
    margin-top: 20px !important;
}

@media (min-width: 480px) {
    .l7-book-pro-btn {
        min-width: 200px;
        margin-top: 0 !important;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area 2-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l7-2 {
    padding-top: 50px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .content-area-l7-2 {
        padding-top: 70px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .content-area-l7-2 {
        padding-top: 120px;
        padding-bottom: 125px;
    }
}

@media (min-width: 1200px) {
    .content-area-l7-2 .section__heading-3 h2 {
        padding-right: 86px;
    }
}

@media (min-width: 1600px) {
    .content-area-l7-2 .section__heading-3 h2 {
        padding-right: 86px;
    }
}

.content-area-l7-2-heading {
    padding-bottom: 45px;
}

@media (min-width: 768px) {
    .content-area-l7-2-heading {
        padding-bottom: 45px;
    }
}

@media (min-width: 992px) {
    .content-area-l7-2-heading {
        padding-bottom: 45px;
    }
}

.content-l7-2-content-box {
    margin-top: 45px;
    margin-bottom: -20px;
}

@media (min-width: 768px) {
    .content-l7-2-content-box {
        margin-bottom: -25px;
    }
}

@media (min-width: 992px) {
    .content-l7-2-content-box {
        margin-top: 0;
    }
}

.content-l7-2-content-box .item {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .content-l7-2-content-box .item {
        margin-bottom: 25px;
    }
}

.content-l7-2-content-box .item .item-icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    border: 1px solid #E1ECF0;
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .content-l7-2-content-box .item .item-icon {
        margin-right: 30px;
    }
}

.content-l7-2-content-box .item .item-icon span {
    color: #1D263A;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.73px;
    line-height: normal;
}

.content-l7-2-content-box .item .item-content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.content-l7-2-content-box .item .item-content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 0;
}

.content-2-l7-image img {
    border-radius: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Testimonial-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l7 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .testimonial-area-l7 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l7 {
        padding-bottom: 130px;
    }
}

.testimonial-area-l7-left-side .video-card {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 25px 20px 25px;
    width: calc(100% - 40px);
}

.testimonial-area-l7-left-side .video-card .content h6 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.testimonial-area-l7-left-side .video-card .content span {
    font-size: 13px;
    line-height: 22px;
    color: #1D263A;
    opacity: 0.6;
}

.testimonial-area-l7-right-side .section__heading-3 {
    margin-top: 30px;
}

.video-icon img {
    border: 2px solid #FF794D;
    border-radius: 50%;
    padding: 12px;
    margin-right: 12px;
}

.testimonial-content-l7 {
    margin-top: 25px;
    background: #FF794D;
    border-radius: 15px;
    padding: 30px;
}

@media (min-width: 480px) {
    .testimonial-content-l7 {
        padding: 50px;
    }
}

.testimonial-content-l7 .user img {
    border-radius: 50%;
}

.testimonial-content-l7 .content {
    margin-top: 40px;
}

.testimonial-content-l7 .content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 10px;
}

.testimonial-content-l7 .content p {
    font-size: 15px;
    line-height: 28px;
    color: #fff;
    opacity: 0.9;
    margin-bottom: 0;
}

.testimonial-right-content-image-l7 {
    margin-top: 45px;
}

@media (min-width: 992px) {
    .testimonial-right-content-image-l7 {
        margin-top: 30px;
        position: absolute;
        bottom: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l7 {
    background: rgba(250, 229, 218, 0.25);
    padding-top: 53px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .footer-area-l7 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .footer-area-l7 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (min-width: 1366px) {
    .footer-area-l7 {
        border-radius: 15px;
        margin: 0px 20px 20px 20px;
    }
}

.cta-area-l7 .cta-l7-content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #1D263A;
}

@media (min-width: 768px) {
    .cta-area-l7 .cta-l7-content h2 {
        font-size: 38px;
        line-height: 48px;
    }
}

@media (min-width: 992px) {
    .cta-area-l7 .cta-l7-content h2 {
        font-size: 48px;
        line-height: 58px;
    }
}

.cta-area-l7 .cta-l7-content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
}

.cta-border-l7 {
    border-bottom: 1px solid rgba(181, 181, 181, 0.13);
    margin: 60px 0px 60px 0px;
}

@media (min-width: 768px) {
    .cta-border-l7 {
        margin: 50px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .cta-border-l7 {
        margin: 70px 0px 130px 0px;
    }
}

.cta-l7-btn {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .cta-l7-btn {
        margin-top: 0;
    }
}

.cta-l7-btn .btn {
    background: #20BFA9;
    border-radius: 3px;
    min-width: 263px;
    height: 55px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
}

.footer-l7-area-items {
    margin-bottom: -30px;
}

.footer-l7-area-items .col-xl-4 {
    margin-bottom: 30px;
}

.footer-l7-area-items .col-xl-2 {
    margin-bottom: 30px;
}

.footer-l7-area-items .footer-content-l7 img {
    margin-bottom: 50px;
}

.footer-l7-area-items .footer-content-l7 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.footer-l7-area-items h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
    opacity: 0.6;
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-l7-area-items h3 {
        margin-bottom: 43px;
    }
}

.footer-l7-area-items ul {
    margin-bottom: -20px;
}

.footer-l7-area-items ul li {
    margin-bottom: 20px;
}

.footer-l7-area-items ul li a {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L8
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l8 {
    position: relative;
    z-index: 1;
    padding-top: 55px;
    padding-bottom: 60px;
    margin: 70px 0px 0px 0px;
}

.hero-area-l8:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: -1;
}

@media (min-width: 1200px) {
    .hero-area-l8:after {
        display: none;
    }
}

.hero-area-l8.background-property {
    background-position: 75% 0% !important;
}

@media (min-width: 1600px) {
    .hero-area-l8.background-property {
        background-position: 100% 0% !important;
    }
}

@media (min-width: 480px) {
    .hero-area-l8 {
        padding-top: 55px;
        margin: 90px 0px 0px 0px;
    }
}

@media (min-width: 768px) {
    .hero-area-l8 {
        padding-top: 75px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .hero-area-l8 {
        padding-top: 210px;
        padding-bottom: 220px;
    }
}

@media (min-width: 992px) {
    .hero-area-l8 .content {
        padding-top: 0;
    }
}

.hero-area-l8 .content h2 {
    color: #1D263A;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 35px;
}

@media (min-width: 576px) {
    .hero-area-l8 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l8 .content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l8 .content h2 {
        font-size: 70px;
        line-height: 77px;
    }
}

.hero-area-l8 .content p {
    color: #1D263A;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 35px;
}

.hero-l8-btn .btn {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    border-radius: 30px;
    color: #fff;
    width: 100%;
    height: 60px;
    background: #20BFA9;
}

@media (min-width: 576px) {
    .hero-l8-btn .btn {
        width: 210px;
        height: 60px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     CTA Area-L8
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l8 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.video-content-l8 img {
    border: 2px solid #20BFA9;
    border-radius: 50%;
    padding: 8px;
    margin-right: 12px;
}

.video-content-l8 a {
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        Feature Area-L8
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l8 {
    padding-top: 55px;
}

@media (min-width: 768px) {
    .feature-area-l8 {
        padding-top: 75px;
    }
}

@media (min-width: 992px) {
    .feature-area-l8 {
        padding-top: 90px;
    }
}

.feature-area-l8-items {
    margin-bottom: -30px;
    padding-top: 40px;
}

.feature-area-l8-items .col-lg-4 {
    margin-bottom: 30px;
}

.feature-card-l8 {
    border: 1px solid rgba(29, 36, 58, 0.12);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 35px 35px 10px 35px;
}

.feature-card-l8 .icon {
    margin-bottom: 35px;
}

.feature-card-l8 .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 30px;
}

.feature-card-l8 .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        Counter Area-L8
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.counter-area-l8 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .counter-area-l8 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .counter-area-l8 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.counter-area-l8-items {
    background: #FF794D;
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.08);
    border-radius: 15px;
    margin: 0px;
    padding: 70px 0px 25px 0px;
}

.counter-area-l8-items .col-xl-3 {
    margin-bottom: 30px;
}

.counter-area-l8-items .count-number {
    width: 50%;
}

.counter-area-l8-items .count-number h2 {
    margin-right: 35px;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #FFFFFF;
}

@media (min-width: 768px) {
    .counter-area-l8-items .count-number h2 {
        font-size: 48px;
        line-height: 55px;
    }
}

@media (min-width: 992px) {
    .counter-area-l8-items .count-number h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

@media (min-width: 576px) {
    .counter-area-l8-items .count-number {
        width: 40%;
    }
}

@media (min-width: 992px) {
    .counter-area-l8-items .count-number {
        width: initial;
    }
}

.counter-area-l8-items .count-content {
    width: 50%;
}

.counter-area-l8-items .count-content p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: left;
}

@media (min-width: 576px) {
    .counter-area-l8-items .count-content {
        width: 40%;
    }
}

@media (min-width: 992px) {
    .counter-area-l8-items .count-content {
        width: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        Content Area-L8-1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l8-1 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l8-1 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l8-1 {
        padding-bottom: 130px;
    }
}

.content-box-l8-1 {
    background: #FFFFFF;
    box-shadow: 0px 24px 60px -20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

@media (min-width: 1600px) {
    .content-box-l8-1 {
        margin-left: -20px;
    }
}

.content-box-l8-1 .text-block {
    padding: 25px 30px 30px 30px;
}

@media (min-width: 480px) {
    .content-box-l8-1 .text-block {
        padding: 45px 50px 50px 50px;
    }
}

.content-box-l8-1 .text-block h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 30px;
}

.content-box-l8-1 .text-block p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 30px;
}

.content-box-l8-1 .text-block .btn {
    width: 100%;
    height: 60px;
    background: #20BFA9;
    border-radius: 40px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
}

@media (min-width: 480px) {
    .content-box-l8-1 .text-block .btn {
        min-width: 330px;
    }
}

.content-box-l8-2 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-box-l8-2 {
        padding-top: 0;
    }
}

.content-box-l8-2-list {
    padding-top: 65px;
}

.content-box-l8-2-list ul {
    margin-bottom: -20px;
}

.content-box-l8-2-list ul li {
    color: #1D263A;
    font-size: 21px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 20px;
}

.content-box-l8-2-list ul li i {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-right: 25px;
    border-radius: 50%;
    color: #1D263A;
    background: #D6EBE4;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        Content Area-L8-2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l8-2 {
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .content-area-l8-2 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .content-area-l8-2 {
        padding-bottom: 130px;
    }
}

.content-box-l8-3 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-box-l8-3 {
        padding-top: 0;
    }
}

@media (min-width: 1600px) {
    .content-box-l8-3 {
        margin-left: -20px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        Clients Area-L8
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.clients-area-l8 {
    background: #F9FAFB;
    padding-top: 55px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .clients-area-l8 {
        padding-top: 75px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .clients-area-l8 {
        padding-top: 125px;
        padding-bottom: 130px;
    }
}

.clients-l8-items {
    margin-bottom: -30px;
    padding-top: 20px;
}

@media (min-width: 768px) {
    .clients-l8-items {
        padding-top: 30px;
    }
}

@media (min-width: 992px) {
    .clients-l8-items {
        padding-top: 60px;
    }
}

.clients-l8-items .col-lg-4 {
    margin-bottom: 30px;
}

.client-l8-card {
    background: #FFFFFF;
    border: 1px solid #E1ECF0;
    border-radius: 10px;
    padding: 40px 40px 35px 40px;
    height: 100%;
}

.client-l8-card .image img {
    width: 179px;
    height: 179px;
    border-radius: 10px;
}

.client-l8-card .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    padding: 40px 0px 12px 0px;
}

.client-l8-card .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.client-l8-card .identity h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.client-l8-card .identity span {
    font-size: 13px;
    line-height: 22px;
    color: #1D263A;
    opacity: 0.6;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                        CTA Area-L8-2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l8-2 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l8-2 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l8-2 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

@media (min-width: 1200px) {
    .cta-area-l8-2 .section__heading {
        padding-right: 35px;
    }
}

@media (min-width: 1366px) {
    .cta-area-l8-2 .section__heading {
        padding-right: 30px;
    }
}

@media (min-width: 1600px) {
    .cta-area-l8-2 .section__heading {
        padding-right: 105px;
    }
}

.cta-area-l8-2 .section__heading h2 {
    color: #fff;
}

.cta-area-l8-2 .section__heading p {
    color: #FFFFFF;
    opacity: 0.8;
}

.cta-area-l8-2-list {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .cta-area-l8-2-list {
        padding-top: 0;
        position: absolute;
        bottom: 0px;
        width: inherit;
    }
}

.cta-area-l8-2-list ul {
    margin-bottom: -20px;
}

.cta-area-l8-2-list ul li {
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 20px;
}

.cta-area-l8-2-list ul li i {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-right: 15px;
    border-radius: 50%;
    color: #1D263A;
    background: #20BFA9;
}

.contact-form-l8 {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    margin-top: 45px;
}

@media (min-width: 576px) {
    .contact-form-l8 {
        padding: 35px 45px 45px 45px;
    }
}

@media (min-width: 992px) {
    .contact-form-l8 {
        margin-top: 0;
    }
}

.contact-form-l8 sup {
    color: #1D263A;
}

.contact-form-l8 .form-group {
    margin-bottom: 30px;
}

.contact-form-l8 label {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.contact-form-l8 .form-control {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.5;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border: none;
    border-radius: 0;
    padding-left: 0;
    background: #E1ECF0;
    opacity: 0.5;
    border-radius: 10px;
    height: 50px;
    padding-left: 25px;
}

.contact-form-l8 .form-control::-moz-placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.contact-form-l8 .form-control:-ms-input-placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.contact-form-l8 .form-control::placeholder {
    color: #1D263A;
    opacity: 0.6;
}

.contact-form-l8 textarea {
    height: 100px !important;
}

@media (min-width: 768px) {
    .contact-form-l8 textarea {
        height: 180px !important;
    }
}

.contact-form-l8 .btn {
    background: #20BFA9;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l8 {
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .footer-area-l8 {
        padding-top: 80px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .footer-area-l8 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.footer-l8-area-items {
    margin-bottom: -30px;
}

.footer-l8-area-items .col-xl-4 {
    margin-bottom: 30px;
}

.footer-l8-area-items .col-xl-2 {
    margin-bottom: 30px;
}

.footer-l8-area-items .footer-content-l8 img {
    margin-bottom: 50px;
}

.footer-l8-area-items .footer-content-l8 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.footer-l8-area-items h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
    opacity: 0.6;
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-l8-area-items h3 {
        margin-bottom: 43px;
    }
}

.footer-l8-area-items ul {
    margin-bottom: -20px;
}

.footer-l8-area-items ul li {
    margin-bottom: 20px;
}

.footer-l8-area-items ul li a {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l9 {
    position: relative;
    z-index: 1;
    padding-top: 55px;
    padding-bottom: 60px;
    margin: 70px 0px 0px 0px;
}

.hero-area-l9:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: -1;
}

@media (min-width: 1200px) {
    .hero-area-l9:after {
        display: none;
    }
}

@media (min-width: 480px) {
    .hero-area-l9 {
        padding-top: 55px;
        margin: 100px 0px 0px 0px;
    }
}

@media (min-width: 768px) {
    .hero-area-l9 {
        padding-top: 75px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .hero-area-l9 {
        margin: 85px 0px 0px 0px;
        padding-top: 260px;
        padding-bottom: 265px;
    }
}

@media (min-width: 992px) {
    .hero-area-l9 .content {
        padding-top: 0;
    }
}

.hero-area-l9 .content h4 {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 5px;
    color: #FF794D;
    margin-bottom: 35px;
}

.hero-area-l9 .content h2 {
    color: #1D263A;
    font-size: 34px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 40px;
    margin-bottom: 35px;
}

@media (min-width: 576px) {
    .hero-area-l9 .content h2 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l9 .content h2 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 992px) {
    .hero-area-l9 .content h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.hero-area-l9 .content p {
    color: #1D263A;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    margin-bottom: 35px;
}

.hero-l9-btn .btn {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    border-radius: 3px;
    color: #fff;
    width: 100%;
    height: 55px;
    background: #2D947A;
}

@media (min-width: 576px) {
    .hero-l9-btn .btn {
        width: 225px;
    }
}

.hero-curve-shape-l9 {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
}

.hero-curve-shape-l9 img {
    width: 100%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                 Content-Area 01-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l9-1 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l9-1 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l9-1 {
        padding-top: 120px;
        padding-bottom: 110px;
    }
}

.content-area-l9-1 .content-area-l9-1-items {
    margin-bottom: -30px;
}

.content-area-l9-1 .content-area-l9-1-items .col-lg-4 {
    margin-bottom: 30px;
}

.content-area-l9-1 .content h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
}

.content-area-l9-1 .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                 Content-Area 02-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l9-2 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l9-2 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l9-2 {
        padding-top: 145px;
        padding-bottom: 130px;
    }
}

.content-area-l9-2-shape {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
}

.content-area-l9-2-shape img {
    width: 100%;
}

.content-area-l9-2-image {
    margin-bottom: 45px;
}

@media (min-width: 992px) {
    .content-area-l9-2-image {
        margin-bottom: 0;
    }
}

.content-area-l9-2-image .shape-image {
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
}

@media (min-width: 768px) {
    .content-area-l9-2-image .shape-image {
        left: -60px;
    }
}

@media (min-width: 992px) {
    .content-area-l9-2-image .shape-image {
        left: -35px;
    }
}

@media (min-width: 1200px) {
    .content-area-l9-2-image .shape-image {
        left: -85px;
    }
}

.book-app-l9-btn {
    margin-top: 45px;
}

.book-app-l9-btn .btn {
    width: 225px;
    height: 55px;
    border: 2px solid #2D947A;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #2D947A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area 2-L7
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l9-3 {
    padding-top: 50px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .content-area-l9-3 {
        padding-top: 75px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .content-area-l9-3 {
        padding-top: 120px;
        padding-bottom: 125px;
    }
}

@media (min-width: 1200px) {
    .content-area-l9-3 .section__heading-3 h2 {
        padding-right: 86px;
    }
}

@media (min-width: 1600px) {
    .content-area-l9-3 .section__heading-3 h2 {
        padding-right: 86px;
    }
}

.content-area-l9-3-heading {
    padding-bottom: 45px;
}

@media (min-width: 768px) {
    .content-area-l9-3-heading {
        padding-bottom: 45px;
    }
}

@media (min-width: 992px) {
    .content-area-l9-3-heading {
        padding-bottom: 45px;
    }
}

.content-l9-3-content-box {
    margin-bottom: -30px;
}

.content-l9-3-content-box .item {
    margin-bottom: 30px;
}

.content-l9-3-content-box .item .item-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background: #2D947A;
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .content-l9-3-content-box .item .item-icon {
        margin-right: 30px;
    }
}

.content-l9-3-content-box .item .item-icon span {
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
}

.content-l9-3-content-box .item .item-content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.content-l9-3-content-box .item .item-content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 0;
}

.content-3-l9-image {
    margin-bottom: 45px;
}

@media (min-width: 992px) {
    .content-3-l9-image {
        margin-bottom: 0;
    }
}

.content-3-l9-image .shape-image {
    position: absolute;
    top: -95px;
    right: 0;
}

@media (min-width: 768px) {
    .content-3-l9-image .shape-image {
        top: -95px;
    }
}

@media (min-width: 992px) {
    .content-3-l9-image .shape-image {
        top: -125px;
    }
}

.content-3-l9-image img {
    border-radius: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    CTA  Area-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l9 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l9 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l9 {
        padding-top: 110px;
        padding-bottom: 150px;
    }
}

.cta-area-l9.background-property {
    background-position: 75% 0% !important;
}

@media (min-width: 768px) {
    .cta-area-l9.background-property {
        background-position: 20% 0% !important;
    }
}

@media (min-width: 992px) {
    .cta-area-l9.background-property {
        background-position: 40% 0% !important;
    }
}

@media (min-width: 1600px) {
    .cta-area-l9.background-property {
        background-position: 100% 0% !important;
    }
}

.cta-form-area-l9 {
    background: #FF794D;
    box-shadow: 0px 44px 44px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 50px 40px 50px 40px;
}

.cta-form-area-l9 .icon-area {
    width: 76px;
    height: 76px;
    line-height: 76px;
    text-align: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    margin: auto;
}

.cta-form-area-l9 .icon-area img {
    width: 40%;
}

.cta-form-area-l9 .cta-form-l9 {
    margin-top: 40px;
}

.cta-form-area-l9 .content {
    margin-top: 32px;
}

.cta-form-area-l9 .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.cta-form-area-l9 .content p {
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.cta-form-area-l9 .form-group {
    margin-bottom: 20px;
}

.cta-form-area-l9 .form-control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #fff;
    border-radius: 0px 0px 4px 4px;
    padding-left: 0;
    padding-bottom: 12px;
    background: transparent;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
}

.cta-form-area-l9 .form-control::-moz-placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.cta-form-area-l9 .form-control:-ms-input-placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.cta-form-area-l9 .form-control::placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.cta-form-area-l9 .btn {
    margin-top: 10px;
    background: #FFFFFF;
    width: 100%;
    height: 45px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Blog  Area-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-area-bg {
    background: #F5F5F5;
}

@media (min-width: 992px) {
    .blog-area-bg {
        padding-top: 100px;
    }
}

.blog-area-l9 {
    position: relative;
    z-index: 2;
    background: #fff;
    padding-top: 50px;
    padding-bottom: 35px;
}

@media (min-width: 768px) {
    .blog-area-l9 {
        padding-top: 70px;
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .blog-area-l9 {
        padding-top: 115px;
        padding-bottom: 105px;
    }
}

@media (min-width: 1366px) {
    .blog-area-l9 {
        box-shadow: 0px 55px 114px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 0px 80px 0px 80px;
    }
}

.read-all-btn-l9 .btn {
    width: 100%;
    height: 55px;
    border: 2px solid #2D947A;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #2D947A;
    margin-top: 30px;
}

@media (min-width: 576px) {
    .read-all-btn-l9 .btn {
        width: initial;
        min-width: 200px;
    }
}

@media (min-width: 768px) {
    .read-all-btn-l9 .btn {
        text-align: right;
        margin-top: 0;
        width: 100%;
    }
}

.blog-items-l9 {
    margin-top: 40px;
    margin-bottom: -30px;
}

@media (min-width: 768px) {
    .blog-items-l9 {
        margin-top: 60px;
    }
}

@media (min-width: 992px) {
    .blog-items-l9 {
        margin-top: 83px;
    }
}

.blog-items-l9 .col-lg-4 {
    margin-bottom: 30px;
}

.blog-content-area .content {
    padding: 35px 15px 0px 15px;
}

.blog-content-area .content h4 {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FF794D;
    margin-bottom: 20px;
}

.blog-content-area .content p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L9
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l9 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .footer-area-l9 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .footer-area-l9 {
        padding-top: 355px;
        z-index: 1;
        margin-top: -260px;
    }
}

.subscribe-form-l9 .form-group {
    margin-bottom: 20px;
}

.subscribe-form-l9 .form-control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #fff;
    border-radius: 0px 0px 4px 4px;
    padding-left: 0;
    padding-bottom: 12px;
    background: transparent;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
}

.subscribe-form-l9 .form-control::-moz-placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.subscribe-form-l9 .form-control:-ms-input-placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.subscribe-form-l9 .form-control::placeholder {
    color: #FFFFFF;
    opacity: 0.7;
}

.foot-sub-btn-l9 .btn {
    margin-top: 10px;
    background: #FFFFFF;
    width: 100%;
    height: 45px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
}

.footer-l9-area-items .footer-content-l9 {
    margin-bottom: 45px;
}

@media (min-width: 992px) {
    .footer-l9-area-items .footer-content-l9 {
        margin-bottom: 0;
    }
}

.footer-l9-area-items .footer-content-l9 img {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .footer-l9-area-items .footer-content-l9 img {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .footer-l9-area-items .footer-content-l9 img {
        margin-bottom: 50px;
    }
}

.footer-l9-area-items .footer-content-l9 p {
    font-size: 15px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 0;
}

.footer-l9-area-items h3 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-l9-area-items h3 {
        margin-bottom: 43px;
    }
}

.footer-l9-area-items ul {
    margin-bottom: -20px;
}

.footer-l9-area-items ul li {
    margin-bottom: 20px;
}

.footer-l9-area-items ul li a {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #fff;
}

.footer-border-l9 {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 40px;
}

@media (min-width: 768px) {
    .footer-border-l9 {
        margin-top: 60px;
    }
}

@media (min-width: 992px) {
    .footer-border-l9 {
        margin-top: 90px;
    }
}

.copyright-text-l9 {
    padding: 30px 0px;
}

.copyright-text-l9 p {
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.footer-curve-shape-l9 {
    position: absolute;
    left: 0;
    top: -1px;
    z-index: -1;
    width: 100%;
}

.footer-curve-shape-l9 img {
    width: 100%;
}

.subs-form-l9-head {
    margin-top: 25px;
}

@media (min-width: 576px) {
    .subs-form-l9-head {
        margin-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L10
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-10-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-10-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-10-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.hero-area-l0 {
    background: #F8F8F8;
    padding-top: 95px;
    padding-bottom: 25px;
}

@media (min-width: 480px) {
    .hero-area-l0 {
        padding-top: 120px;
    }
}

@media (min-width: 768px) {
    .hero-area-l0 {
        padding-top: 145px;
        padding-bottom: 45px;
    }
}

@media (min-width: 992px) {
    .hero-area-l0 {
        padding-top: 240px;
        padding-bottom: 130px;
    }
}

.hero-area-l0 .content {
    padding-top: 45px;
    position: relative;
    z-index: 5;
}

@media (min-width: 992px) {
    .hero-area-l0 .content {
        padding-top: 0;
    }
}

.hero-area-l0 .content h1 {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -4px;
    line-height: 40px;
    margin-bottom: 28px;
}

@media (min-width: 576px) {
    .hero-area-l0 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l0 .content h1 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l0 .content h1 {
        font-size: 60px;
        line-height: 65px;
    }
}

.hero-area-l0 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #222F3F;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
}

.hero-area-l0 .banner-sign-text {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 36px;
    letter-spacing: -0.32381px;
    color: #878B90;
    margin-top: 7px;
}

.hero-area-l0 .banner-sign-text a {
    color: #258AFF;
}

.header-btn-l10 .sign-in-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #666666;
    min-width: initial;
    padding: 0;
    margin-right: 40px;
}

.header-btn-l10 .get-start-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    letter-spacing: -1px;
    color: #FFFFFF;
    border-radius: 3px;
    background: #1290A4;
    min-width: 137px;
    height: 40px;
}

.landing-10-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.subscription-form-1 .form-control {
    max-width: 100%;
    height: 60px;
    background: #EEF4F5;
    border-radius: 3px;
    padding-left: 25px;
    border: 0;
    margin-top: 20px;
}

@media (min-width: 480px) {
    .subscription-form-1 .form-control {
        max-width: 275px;
    }
}

.subscription-form-1 .form-control::-moz-placeholder {
    color: #878B90;
}

.subscription-form-1 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.subscription-form-1 .form-control::placeholder {
    color: #878B90;
}

.subscription-form-1 .btn {
    min-width: 100%;
    height: 55px;
    border-radius: 3px;
    color: #fff;
    padding: 0px 15px;
    background: #1290A4;
    margin-top: 15px;
}

@media (min-width: 480px) {
    .subscription-form-1 .btn {
        margin-top: 0;
        min-width: 176px;
        height: 60px;
        margin-top: -6px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Feature Area-L10
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l0 {
    padding-top: 70px;
    padding-bottom: 60px;
    background: #EEF4F5;
}

@media (min-width: 768px) {
    .feature-area-l0 {
        padding-top: 90px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-area-l0 {
        padding-top: 200px;
        padding-bottom: 130px;
    }
}

.feature-area-l0 .section-heading-4 {
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .feature-area-l0 .section-heading-4 {
        margin-bottom: 40px;
    }
}

.single-features-l10-items {
    margin-bottom: -25px;
}

.single-features-l10-items .col-xl-4 {
    margin-bottom: 25px;
}

.single-features-l10-items .single-features {
    padding: 35px 40px 25px 40px;
    background: #FFFFFF;
    border: 1px solid #D9E4E6;
    border-radius: 5px;
}

.single-features-l10-items .single-features .feature-icon {
    display: inline-block;
    margin-bottom: 32px;
    background: rgba(244, 111, 124, 0.2);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
}

.single-features-l10-items .single-features .feature-icon svg {
    height: 35px;
    width: 35px;
}

.single-features-l10-items .single-features h4 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -2px;
    color: #1F1F1F;
    margin-bottom: 15px;
}

.single-features-l10-items .single-features p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-bottom: 20px;
}

.single-features-l10-items .single-features .btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #1290A4;
    padding: 0;
    min-width: initial !important;
}

.single-features-l10-items .single-features .btn i {
    margin-left: 20px;
}

.single-features-l10-items .single-features .btn:hover {
    color: #1290A4 !important;
}

.shape-bottom-right {
    position: absolute;
    top: -7px;
}

@media (min-width: 480px) {
    .shape-bottom-right {
        top: -2px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L10 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l10-1 {
    padding-top: 60px;
    padding-bottom: 45px;
}

@media (min-width: 768px) {
    .content-area-l10-1 {
        padding-top: 80px;
        padding-bottom: 63px;
    }
}

@media (min-width: 992px) {
    .content-area-l10-1 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-area-l10-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l10-1 .content {
        padding-top: 0;
    }
}

.content-area-l10-1 .content h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -3px;
    color: #1F1F1F;
    margin-bottom: 20px;
}

.content-area-l10-1 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #333333;
    margin-bottom: 35px;
}

.content-area-l10-1 .content .btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #1290A4;
    padding: 0;
    min-width: initial !important;
}

.content-area-l10-1 .content .btn i {
    margin-left: 20px;
}

.content-area-l10-1 .content .btn:hover {
    color: #1290A4 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L10 2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l10-2 {
    padding-top: 60px;
    background: #f8f8f8;
}

@media (min-width: 768px) {
    .content-area-l10-2 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l10-2 {
        padding-top: 130px;
    }
}

.content-area-l10-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l10-2 .content {
        padding-top: 0;
    }
}

.content-area-l10-2 .content h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */
    letter-spacing: -3px;
    color: #1F1F1F;
    margin-bottom: 20px;
}

.content-area-l10-2 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #333333;
}

.content-area-l10-2 .content .btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #1290A4;
    padding: 0;
    min-width: initial !important;
}

.content-area-l10-2 .content .btn i {
    margin-left: 20px;
}

.content-area-l10-2 .content .btn:hover {
    color: #1290A4 !important;
}

.content-area-l10-2 ul {
    margin-top: 50px;
    margin-bottom: -20px;
}

.content-area-l10-2 ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: -1.4px;
    color: #000000;
    margin-bottom: 20px;
}

.content-area-l10-2 ul li i {
    color: #258AFF;
    margin-right: 20px;
    font-size: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L10 3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l10-3 {
    padding-top: 35px;
    padding-bottom: 60px;
    background: #FEFDFD;
}

@media (min-width: 768px) {
    .content-area-l10-3 {
        padding-top: 65px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l10-3 {
        padding-top: 65px;
        padding-bottom: 130px;
    }
}

.content-area-l10-3 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l10-3 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .content-area-l10-3 .content {
        padding-right: 35px;
    }
}

@media (min-width: 1600px) {
    .content-area-l10-3 .content {
        padding-right: 95px;
    }
}

.content-area-l10-3 .content h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -3px;
    color: #1F1F1F;
    margin-bottom: 20px;
}

.content-area-l10-3 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #333333;
}

.content-area-l10-3 .content .btn {
    background: #F46F7C;
    border-radius: 3px;
    min-width: 170px;
    height: 50px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -1.0625px;
    color: #FFFFFF;
    margin-top: 28px;
}

.content-area-l10-3 .content .btn:hover {
    color: #fff !important;
}

.content-area-l10-3 .content .border-top {
    border-top: 1px solid #E5E5E5 !important;
    margin: 20px 0px 20px 0px;
}

.content-area-l10-3 .content .double-content-c3 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.content-area-l10-3 ul {
    margin-top: 40px;
    margin-bottom: -20px;
}

.content-area-l10-3 ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: -1.4px;
    color: #000000;
    margin-bottom: 20px;
}

.content-area-l10-3 ul li i {
    color: #258AFF;
    margin-right: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Pricing Area L-10
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.price-l10-shape {
    background: #F8F8F8;
}

.price-area-l10-items {
    margin-bottom: -25px;
}

.price-area-l10-items .col-md-6 {
    margin-bottom: 25px;
}

.pricing-area-l10 {
    background: #30393B;
    padding-top: 35px;
    padding-bottom: 40px;
    margin-top: -2px;
}

@media (min-width: 768px) {
    .pricing-area-l10 {
        padding-top: 45px;
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l10 {
        padding-top: 65px;
        padding-bottom: 110px;
    }
}

.pricing-area-l10 .section-heading-4 h2 {
    color: #fff;
}

.pricing-area-l10 .section-heading-4 p {
    color: #878B90;
}

.price-l10-bottom {
    padding-top: 35px;
}

.price-l10-bottom p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.32381px;
}

.price-l10-bottom p a {
    color: #FFFFFF;
}

.price-l10-bottom span {
    color: #878B90;
}

.flex-y-center {
    display: flex;
    align-items: center;
}

.pricing-area-l10 .single-price {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 30px 45px 15px 45px;
}

.pricing-area-l10 .single-price del {
    color: #8E8E8E;
}

.pricing-area-l10 .single-price .price-top {
    padding-bottom: 18px;
}

.pricing-area-l10 .single-price .price-top span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.433333px;
    text-transform: uppercase;
    color: #666666;
}

.pricing-area-l10 .single-price .price-top .popular-price {
    min-width: 83px;
    height: 26px;
    background: #F46F7C;
    border-radius: 13px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.514286px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-area-l10 .single-price .main-price span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -1.2px;
    color: #1F1F1F;
}

.pricing-area-l10 .single-price .main-price h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -2px;
    color: #1F1F1F;
    margin-bottom: 0;
    margin-left: 25px;
}

.pricing-area-l10 .single-price .main-price p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.4px;
    color: #666666;
    margin: 12px 0px 28px 0px;
}

.pricing-area-l10 .single-price .main-price .dollar-pos {
    position: absolute;
    top: 5px;
    left: 0;
}

.pricing-area-l10 .single-price ul {
    margin-bottom: -10px;
}

.pricing-area-l10 .single-price ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: -0.425px;
    color: #1F1F1F;
    margin-bottom: 10px;
}

.pricing-area-l10 .single-price ul li i {
    margin-right: 12px;
}

.pricing-area-l10 .single-price .price-btn .btn {
    min-width: 190px;
    height: 50px;
    background: #1290A4;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -1.06px;
    color: #FFFFFF;
    margin-top: 35px;
}

.pricing-area-l10 .single-price .price-btn p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-top: 7px;
}

.pricing-area-l10 label {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #FFFFFF;
}

.pricing-area-l10 .toggle-btn {
    padding: 55px 0px 45px;
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-yearly);
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
    background: #1290a4;
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
    left: calc(100% - 24px);
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
    background: #1290a4;
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
    left: calc(100% - 52%);
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
    color: #000 !important;
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
    color: #000 !important;
}

.pricing-area-l10 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-monthly);
}

.pricing-area-l10 .btn-toggle-2[data-pricing-trigger].active:before {
    color: #000 !important;
}

.pricing-area-l10 .btn-toggle-2[data-pricing-trigger].active:after {
    color: #000 !important;
}

.pricing-area-l10 .dynamic-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l10 .static-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l10 .btn-toggle {
    width: 48px;
    height: 24px;
    border-radius: 17px;
    background-color: #f8f8f8 !important;
    position: relative;
    margin: 0 15px;
}

.pricing-area-l10 .btn-toggle span {
    width: 16px;
    height: 16px;
    background-color: #1290a4;
    position: absolute;
    left: 5px;
    top: 4px;
    transition: 0.4s;
    border-radius: 500px;
    pointer-events: none;
}

.pricing-area-l10 .btn-toggle-2 {
    width: 220px;
    height: 42px;
    border-radius: 30px;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    position: relative;
    margin: 0 15px;
    z-index: 1;
}

.pricing-area-l10 .btn-toggle-2:before {
    position: absolute;
    content: "Monthly";
    width: 46%;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    height: 34px;
    background: transparent;
    left: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l10 .btn-toggle-2:after {
    position: absolute;
    content: "Yearly";
    width: 46%;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    height: 34px;
    background: transparent;
    right: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l10 .btn-toggle-2 .round {
    width: 50%;
    /* left: 0 !important; */
    height: 34px;
    top: 3px;
    left: 4px;
    background: blue;
    display: block;
    z-index: -1;
}

.pricing-area-l10 .btn-toggle-2 span {
    display: none;
}

.pricing-area-l10 .btn-toggle[data-value-active="yearly"] {
    background-color: #1290a4 !important;
}

.pricing-area-l10 .btn-toggle[data-value-active="yearly"] span {
    left: calc(100% - 25px) !important;
}

.pricing-area-l10 .btn-toggle-square {
    height: 65px;
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 5px;
    align-items: center;
}

.pricing-area-l10 .btn-toggle-square.bg-whisper-3:hover,
.pricing-area-l10 .btn-toggle-square.bg-whisper-3:focus {
    background: #8e8e8e !important;
}

.pricing-area-l10 .btn-toggle-square span {
    min-width: 160px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.pricing-area-l10 .btn-toggle-square span:first-child {
    border-radius: 10px 0 0 10px;
}

.pricing-area-l10 .btn-toggle-square span:nth-child(2) {
    border-radius: 0px 10px 10px 0;
}

.pricing-area-l10 .btn-toggle-square span.active {
    color: #fff;
    background: #1290a4;
}

.pricing-area-l10 .popular-pricing:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 4px;
    background: #f46f7c;
    border-radius: 6px;
}

.pricing-area-l10 .popular-pricing.popular-pricing-2:before {
    height: 3px;
    top: -3px;
    background: #14ee80;
}

.pricing-area-l10 .popular-pricing.popular-pricing-3:before {
    height: 3px;
    top: -3px;
    background: #50e3c2;
}

.pricing-area-l10 .active-border {
    position: relative;
}

.pricing-area-l10 .active-border:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 5px;
    background: #5029de;
    border-radius: 5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Newslater areaL-10
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.newsletter-area-l10 {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .newsletter-area-l10 {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .newsletter-area-l10 {
        padding-top: 115px;
    }
}

.subscription-form-2 {
    margin-top: 28px;
    margin-bottom: 15px;
}

.subscription-form-2 .form-control {
    max-width: 100%;
    height: 60px;
    background: #EEF4F5;
    border-radius: 3px;
    padding-left: 25px;
    border: 0;
    margin-top: 20px;
}

@media (min-width: 480px) {
    .subscription-form-2 .form-control {
        max-width: 275px;
    }
}

.subscription-form-2 .form-control::-moz-placeholder {
    color: #878B90;
}

.subscription-form-2 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.subscription-form-2 .form-control::placeholder {
    color: #878B90;
}

.subscription-form-2 .btn {
    min-width: 100%;
    height: 55px;
    border-radius: 3px;
    color: #fff;
    padding: 0px 15px;
    background: #1290A4;
    margin-top: 15px;
}

@media (min-width: 480px) {
    .subscription-form-2 .btn {
        margin-top: 0;
        margin-top: -6px;
        min-width: 176px;
        height: 60px;
    }
}

.newsletter-form-l10-bottom {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.428571px;
    color: #666666;
}

.newsletter-form-l10-bottom img {
    margin-right: 7px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Footer Area L-10
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-l10 {
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-l10 {
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-l10 {
        padding-bottom: 85px;
    }
}

.footer-l10 .footer-border-top {
    margin: 40px 0px 60px 0px;
    border-top: 1px solid #E5E5E5;
}

@media (min-width: 768px) {
    .footer-l10 .footer-border-top {
        margin: 60px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .footer-l10 .footer-border-top {
        margin: 103px 0px 120px 0px;
    }
}

.footer-l10 .content {
    margin: 35px 0px 33px 0px;
}

.footer-l10 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.footer-l10 .footer-widget p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-bottom: 35px;
}

.footer-l10 .footer-widget ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: -0.4px;
    color: #1F1F1F;
}

.footer-l10 .social-icons {
    margin-bottom: 50px;
}

@media (min-width: 992px) {
    .footer-l10 .social-icons {
        margin-bottom: 0;
    }
}

.footer-l10 .social-icons ul {
    margin: 0px -9px 0px -9px;
}

.footer-l10 .social-icons ul li {
    margin: 0px 9px 0px 9px;
}

.footer-l10 .social-icons ul li i {
    font-size: 16px;
    color: #1f1f1f !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-11
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-11-menu .site-menu-main .nav-link-item {
    font-family: 'Quicksand', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height */


    color: #000000;
}

.landing-11-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-11-menu .site-menu-main .sub-menu--item {
    font-family: 'Quicksand', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-11-menu.reveal-header .log-in-btn {
    color: #666666 !important;
}

/* .hero-area-l11 {
    padding-top: 100px;
    background-image: url('assets/img/bg-beranda.png');
    background-color: white;
    padding-bottom: 10px;
} */

@media (min-width: 480px) {
    .hero-area-l11 {
        padding-top: 115px;
    }
}

@media (min-width: 768px) {
    .hero-area-l11 {
        padding-top: 130px;
        padding-bottom: 76px;
    }
}

@media (min-width: 992px) {
    .hero-area-l11 {
        padding-top: 180px;
        padding-bottom: 50px;
    }
}

.hero-area-l11 .content {
    padding-top: 20px;
    position: relative;
    z-index: 5;
}

@media (min-width: 992px) {
    .hero-area-l11 .content {
        padding-top: 0;
    }
}

.hero-area-l11 .content h1 {
    font-family: 'Gothic A1', sans-serif;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: -3px;
    line-height: 40px;
    margin-bottom: 38px;
}

@media (min-width: 576px) {
    .hero-area-l11 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l11 .content h1 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l11 .content h1 {
        font-size: 70px;
        line-height: 86px;
    }
}

.hero-area-l11 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #222F3F;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
}

@media (min-width: 1200px) {
    .hero-area-l11 .banner-main-content-l-11 {
        padding-right: 65px;
    }
}

@media (min-width: 1600px) {
    .hero-area-l11 .banner-main-content-l-11 {
        padding-right: 130px;
    }
}

.hero-area-l11 .banner-sign-text {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 36px;
    letter-spacing: -0.32381px;
    color: #878B90;
    margin-top: 7px;
}

.hero-area-l11 .banner-sign-text a {
    color: #258AFF;
}

.hero-area-l11 .compatible-icon {
    margin: 0px -11px 0px -11px;
}

.hero-area-l11 .compatible-icon a {
    margin: 0px 11px 0px 11px;
}

.hero-area-l11 .compitable-text {
    margin-top: 25px;
}

@media (min-width: 768px) {
    .hero-area-l11 .compitable-text {
        margin-top: 8px;
    }
}

.hero-area-l11 .compitable-text p {
    padding-top: 20px;
}

.header-btn-l11 .log-in-btn {
    font-family: 'Quicksand', cursive;
    font-weight: 500;
    width: auto;
    height: 30px;
    display: inline-block;
    padding: 3px 25px;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #000;
    background-color: #F8CA2B;
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 3px 5px #E5E5E5;
    line-height: 22px;
}

@media (max-width: 992px) {
    .header-btn-l11 .log-in-btn {
        font-size: 14px;
        padding: 1px 15px;
    }
}

.log-in-btn:hover {
    background-color: #F8CA2B;
}

.log-in-btn:active {
    background-color: #F8CA2B;
    box-shadow: 0 5px #E5E5E5;
    transform: translateY(7px);
}

.header-btn-l11 .download-trail-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    letter-spacing: -1px;
    color: #FFFFFF;
    background: #258AFF;
    border-radius: 3px;
    min-width: 137px;
    height: 40px;
}

.l-11-hero-btn {
    min-width: 205px;
    height: 60px;
    background: #258AFF;
    border-radius: 3px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #FFFFFF;
}

.hero-shape-l11-1 {
    position: absolute;
    left: 0;
    top: 30%;
}

@media (min-width: 768px) {
    .hero-shape-l11-1 {
        left: -5%;
    }
}

@media (min-width: 992px) {
    .hero-shape-l11-1 {
        left: 0;
        top: 30%;
    }
}

.hero-shape-l11-2 {
    position: absolute;
    right: 0;
    top: 40%;
}

@media (min-width: 992px) {
    .hero-l11-main-image {
        margin-top: 90px;
    }
}

.img-grayscale {
    filter: grayscale(100%);
}

.banner-l-11-bottom-content {
    padding-left: 30px;
}

.line-left-content {
    position: absolute;
    top: 0;
    left: -30px;
    width: 7px;
    height: 55px;
    background: #E8F1FC;
    border-radius: 4.5px;
}


/* .wave-top {
    background: url(../assets/img/background/wave-top.png);
    position: absolute;
    z-index: -1;
    top: 90px;
    width: 1064px;
    height: 200px;

} */

/* .wave-5 {
    background: url(../assets/img/background/wave-5.png);
    position: absolute;
    background-size: cover;
    z-index: -1;
    top: 0px;
    width: 100%;
    height: 130px;

} */

/* .wave-bot {
    background: url(../assets/img/background/wave-bot.png);
    background-size: cover;
    position: absolute;
    z-index: -1;
    width: 100%;
    bottom: 0px;
    height: 29%;

} */

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Brand Area L-11
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l11 .brand-logos {
    margin: 0px -20px -45px -20px;
}

.brand-area-l11 .brand-logos .single-brand {
    margin: 0px 20px 45px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area-1 L-11-1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-11-1 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-area-l-11-1 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-11-1 {
        padding-top: 130px;
    }
}

.content-area-l-11-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-11-1 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .content-area-l-11-1 .content {
        padding-right: 55px;
    }
}

@media (min-width: 1600px) {
    .content-area-l-11-1 .content {
        padding-right: 50px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area-1 L-11-2
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-11-2 {
    padding-top: 50px;
    padding-bottom: 42px;
}

@media (min-width: 768px) {
    .content-area-l-11-2 {
        padding-top: 70px;
        padding-bottom: 62px;
    }
}

@media (min-width: 992px) {
    .content-area-l-11-2 {
        padding-top: 125px;
        padding-bottom: 110px;
    }
}

.content-area-l-11-2 .section-heading-5 h2 {
    margin-bottom: 40px;
}

@media (min-width: 992px) {
    .content-area-l-11-2 .section-heading-5 h2 {
        margin-bottom: 60px;
    }
}

.content-area-l-11-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-11-2 .content {
        padding-top: 0;
    }
}

.content-area-l-11-2 .content h5 {
    font-family: "Gothic A1";
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    color: #000000;
}

.content-area-l-11-2 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.4px;
    color: #333333;
}

.content-area-l-11-2 .content .content-l-11-3-card {
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px;
    padding: 20px 35px 20px 35px;
    margin-bottom: 15px;
    align-items: end;
}

.content-area-l-11-2 .content .content-l-11-3-card svg {
    margin-right: 25px;
}

.content-area-l-11-2 .content .content-l-11-3-card img {
    margin-right: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L-11 3
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-11-3 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l-11-3 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-11-3 {
        padding-bottom: 130px;
    }
}

.content-area-l-11-3 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-11-3 .content {
        padding-top: 0;
    }
}

.content-area-l-11-3 ul {
    margin-top: 50px;
    margin-bottom: -20px;
}

.content-area-l-11-3 ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1.4px;
    color: #000000;
    margin-bottom: 20px;
}

.content-area-l-11-3 ul li i {
    color: #258AFF;
    margin-right: 20px;
    font-size: 18px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Feature Area-L-11 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-l-11 {
    background: #192F48;
    padding-top: 55px;
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .feature-l-11 {
        padding-top: 75px;
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .feature-l-11 {
        padding-top: 125px;
        padding-bottom: 110px;
    }
}

.feature-l-11 .section-heading-5 {
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .feature-l-11 .section-heading-5 {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .feature-l-11 .section-heading-5 {
        padding-bottom: 80px;
    }
}

.feature-l-11 .section-heading-5 h2 {
    color: #fff;
}

.feature-l-11 .section-heading-5 p {
    color: rgba(255, 255, 255, 0.9);
}

.feature-l-11-items {
    margin-bottom: -37px;
}

.feature-l-11-items .col-md-6 {
    margin-bottom: 37px;
}

.feature-l-11-items .icon-box {
    margin-right: 25px;
}

.feature-l-11-items .icon-box i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #258AFF;
    border-radius: 5px;
    color: #fff;
    font-size: 30px;
}

@media (min-width: 1600px) {
    .feature-l-11-items .content-body {
        padding-right: 60px;
    }
}

.feature-l-11-items .content-body h5 {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 26px;
    letter-spacing: -1px;
    color: #FFFFFF;
}

.feature-l-11-items .content-body p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #BBC6D2;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Testimonial Area-L-11 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-11 {
    padding-top: 55px;
    padding-bottom: 70px;
}

@media (min-width: 768px) {
    .testimonial-area-l-11 {
        padding-top: 75px;
        padding-bottom: 95px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-11 {
        padding-top: 125px;
        padding-bottom: 170px;
    }
}

.testimonial-area-l-11 .section-heading-5 {
    padding-bottom: 40px;
}

@media (min-width: 992px) {
    .testimonial-area-l-11 .section-heading-5 {
        padding-bottom: 60px;
    }
}

.testimonial-slider-l-11 {
    padding: 0px 50px;
}

.testimonial-slider-l-11 .l-11-slide-btn {
    cursor: pointer;
}

.testimonial-slider-l-11 .l-11-slide-btn.slick-prev {
    position: absolute;
    top: 50%;
    left: 0%;
}

.testimonial-slider-l-11 .l-11-slide-btn.slick-next {
    position: absolute;
    top: 50%;
    right: 0;
}

@media (min-width: 992px) {
    .testimonial-slider-l-11 .testimonial-card {
        padding: 0px 41px;
    }
}

@media (min-width: 1600px) {
    .testimonial-slider-l-11 .testimonial-card {
        padding: 0px 50px;
    }
}

.testimonial-slider-l-11 .testimonial-card p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #333333;
}

.testimonial-slider-l-11 .testimonial-card .user-details {
    margin-top: 30px;
}

@media (min-width: 992px) {
    .testimonial-slider-l-11 .testimonial-card .user-details {
        margin-top: 60px;
    }
}

.testimonial-slider-l-11 .testimonial-card .user-details img {
    margin-right: 22px;
}

.testimonial-slider-l-11 .testimonial-card .user-details h5 {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
    color: #000000;
}

.testimonial-slider-l-11 .testimonial-card .user-details span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.642857px;
    color: #666666;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Newsletter-area-L-11 1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.newsletter-l-11 .news-l-11-main-bg {
    background: #000;
    border-radius: 5px;
    z-index: 1;
    padding: 60px 0px 50px 0px;
}

@media (min-width: 768px) {
    .newsletter-l-11 .news-l-11-main-bg {
        padding: 80px 0px 70px 0px;
    }
}

@media (min-width: 992px) {
    .newsletter-l-11 .news-l-11-main-bg {
        padding: 115px 0px 112px 0px;
    }
}

.newsletter-l-11 .news-l-11-second-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #258aff !important;
    transform: rotate(-4deg) !important;
    border-radius: 5px;
    z-index: -1;
}

.newsletter-l-11 .content h5 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2.625px;
    text-transform: uppercase;
    color: #ECF2FA;
    margin-bottom: 30px;
}

.newsletter-l-11 .content h2 {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -2.53px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .newsletter-l-11 .content h2 {
        margin-bottom: 35px;
        font-size: 60px;
        line-height: 48px;
    }
}

.newsletter-l-11 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #ECF2FA;
}

.newsletter-l-11 .content span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ECF2FA;
    display: inline-block;
    margin-top: 15px;
}

.newsletter-l-11 .content .btn {
    min-width: 226px;
    height: 60px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1px;
    color: #FFFFFF;
    background: #258AFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .newsletter-l-11 .content .btn {
        margin-top: 30px;
    }
}

.newsletter-l-11 .content .btn i {
    margin-right: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Footer-area-L-11 
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-l-11 {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .footer-l-11 {
        padding-top: 75px;
    }
}

.footer-widget-l-11 {
    margin: 30px 0px;
}

@media (min-width: 768px) {
    .footer-widget-l-11 {
        margin: 0px 0px;
    }
}

.footer-widget-l-11 ul {
    margin: 0px -15px 0px -15px;
}

.footer-widget-l-11 ul li {
    margin: 0px 15px 0px 15px;
}

.footer-widget-l-11 ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.642857px;
    color: #000000;
}

.footer-widget-l-11 ul li a:hover {
    color: #FFFFFF;
}

.social-icons-l-11 ul {
    margin: 0px -15px 0px -15px;
}

.social-icons-l-11 ul li {
    margin: 0px 15px 0px 15px;
}

.social-icons-l-11 ul li a i {
    color: #000;
    font-size: 16px;
}

.copyright-area-l-11 {
    padding-top: 40px;
    padding-bottom: 30px;
}

.copyright-area-l-11 p {
    margin-bottom: 0;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.642857px;
    color: #000;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-12
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-12-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-12-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-12-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-12-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.hero-area-l-12 {
    padding-top: 105px;
    padding-bottom: 30px;
}

@media (min-width: 480px) {
    .hero-area-l-12 {
        padding-top: 110px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-12 {
        padding-top: 130px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-12 {
        padding-top: 225px;
        padding-bottom: 150px;
    }
}

.hero-area-l-12 .content {
    position: relative;
    z-index: 5;
    padding-top: 45px;
}

@media (min-width: 768px) {
    .hero-area-l-12 .content {
        padding-right: 25px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-12 .content {
        padding-top: 0;
        padding-right: 103px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-12 .content {
        padding-right: 0;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-12 .content {
        padding-right: 80px;
    }
}

.hero-area-l-12 .content h1 {
    font-family: 'Gothic A1', sans-serif;
    color: #1F1F1F;
    font-size: 34px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: -3px;
    line-height: 40px;
    margin-bottom: 10px;
}

@media (min-width: 576px) {
    .hero-area-l-12 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-12 .content h1 {
        font-size: 48px;
        line-height: 54px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-12 .content h1 {
        font-size: 70px;
        line-height: 86px;
    }
}

.hero-area-l-12 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    color: #222F3F;
    font-size: 21px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
}

.hero-area-l-12 .content .btn {
    min-width: 210px;
    height: 60px;
    background: #FC593B;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #FFFFFF;
    margin-top: 22px;
}

.star-ratings-l12 {
    margin-top: 17px;
}

.star-ratings-l12 i {
    color: #4EBE86;
}

.star-ratings-l12 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.32381px;
    color: #878B90;
    margin-top: 8px;
}

@media (min-width: 480px) {
    .star-ratings-l12 p {
        margin-top: 0;
        margin-left: 15px;
    }
}

.star-ratings-l12 .star-dif-color {
    color: #E5E5E5;
}

.hero-video-l12 .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-video-l12 .video-icon i {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    background: #4EBE86;
    color: #fff;
}

.header-btn-l-12 .sign-in-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #666666;
    min-width: 87px;
    height: 40px;
    border-radius: 3px;
    margin-right: 15px;
}

.hero-shape-l12-1 {
    position: absolute;
    left: 0;
    top: -41%;
    z-index: -1;
}

@media (min-width: 768px) {
    .hero-shape-l12-1 {
        left: -10%;
    }
}

@media (min-width: 992px) {
    .hero-shape-l12-1 {
        top: 30%;
        left: -5%;
        top: 0%;
    }
}

.hero-shape-l12-2 {
    position: absolute;
    right: -40%;
    top: 0%;
}

@media (min-width: 992px) {
    .hero-shape-l12-2 {
        right: 0;
    }
}

.video-area-shape-l-12 {
    position: absolute;
    right: -15%;
    bottom: -11%;
    z-index: -1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Counter Area-L-12
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.counter-area-l12 {
    z-index: 5;
}

.counter-area-l12 h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -1px;
    color: #333333;
    padding-bottom: 40px;
}

.counter-area-l12-items {
    margin-bottom: -25px;
}

.counter-area-l12-items .col-xxl-3 {
    margin-bottom: 25px;
}

.counter-single-wrapper-l-12 {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 10px 60px rgba(35, 44, 39, 0.0513757);
    border-radius: 10px;
    padding: 60px 30px 53px 30px;
}

.counter-single-wrapper-l-12 h5 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -2px;
    color: #4EBE86;
}

.counter-single-wrapper-l-12 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #333333;
}

.counter-single-wrapper-l-12 .counter_bg-img {
    position: absolute;
    left: 0;
    bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Topics Area-L-12
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.topics-area-l-12 {
    background: #F7FBFC;
    padding-top: 55px;
    padding-bottom: 45px;
    z-index: 1;
}

@media (min-width: 768px) {
    .topics-area-l-12 {
        padding-top: 75px;
        padding-bottom: 65px;
    }
}

@media (min-width: 992px) {
    .topics-area-l-12 {
        padding-top: 125px;
        padding-bottom: 115px;
    }
}

.topics-area-l-12 .section-heading-6 {
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .topics-area-l-12 .section-heading-6 {
        padding-bottom: 60px;
    }
}

.topics-area-l-12 .topics-area-l12-shape-1 {
    position: absolute;
    left: -4%;
    top: -10%;
    z-index: -1;
}

.topics-area-l-12 .topics-area-l12-shape-2 {
    position: absolute;
    right: -16%;
    top: 40%;
    transform: rotate(19deg);
}

@media (min-width: 1600px) {
    .topics-area-l-12 .topics-area-l12-shape-2 {
        right: -8%;
    }
}

.faq-content-l-12 .topics-accordion-wrapper {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
    border-radius: 10px;
    padding: 30px 30px 25px 30px;
    margin-bottom: 15px;
}

.faq-content-l-12 .topics-accordion-wrapper .topics-main-icon {
    margin-right: 25px;
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .faq-content-l-12 .topics-accordion-wrapper .topics-main-icon {
        margin-bottom: 0;
    }
}

.faq-content-l-12 .topics-accordion-wrapper .topics-main-icon img {
    padding: 22px;
    background: #EEF4F5;
    border-radius: 10px;
}

.faq-content-l-12 .topics-accordion-wrapper .topics-collapse-wrapper {
    margin: 40px -22px 0px -22px;
}

.faq-content-l-12 .topics-accordion-wrapper .topics-collapse-wrapper li {
    margin: 0px 22px;
}

.faq-content-l-12 .topics-accordion-wrapper .topics-collapse-wrapper li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.857143px;
    color: #1F1F1F;
    display: flex;
    align-items: baseline;
}

.faq-content-l-12 .topics-accordion-wrapper .topics-collapse-wrapper .fas {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 22px;
    text-align: center;
    background: #DDF7EA;
    color: #4EBE86;
    font-size: 12px;
    margin-right: 13px;
}

.faq-content-l-12 .content h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -1.26316px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: -5px;
}

@media (min-width: 480px) {
    .faq-content-l-12 .content h3 {
        font-size: 24px;
    }
}

.faq-content-l-12 .content span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.faq-content-l-12 .video-hour-area {
    background: #4EBE86;
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .faq-content-l-12 .video-hour-area {
        margin-right: 20px;
    }
}

@media (min-width: 992px) {
    .faq-content-l-12 .video-hour-area {
        margin-top: 0px;
    }
}

.faq-content-l-12 .video-hour-area li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.352941px;
    color: #FFFFFF;
}

.faq-content-l-12 .video-hour-area li span {
    display: inline-block;
    height: 25px;
    width: 1px;
    background: #79D8A8;
    margin: 5px 20px 0px 20px;
}

.faq-content-l-12 .accordion-trigger.arrow-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-content-l-12 .accordion-trigger.arrow-icon:after {
    content: "\ea05";
    font-family: "Grayic";
    font-size: 30px;
    display: block;
    line-height: 1;
    transform: rotate(0deg);
    transition: .4s;
    color: #878b90;
    top: 7px;
    position: absolute;
    right: -10px;
}

.faq-content-l-12 .accordion-trigger.arrow-icon[aria-expanded="true"]:after {
    transform: rotate(-180deg);
}

.faq-content-l-12 .accordion-trigger-2.arrow-icon:after {
    top: 27px;
    right: 23px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Content Area-L-12-1
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-12-1 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-area-l-12-1 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-12-1 {
        padding-top: 130px;
    }
}

@media (min-width: 992px) {
    .content-area-l-12-1 .main-img {
        padding-right: 100px;
    }
}

.content-area-l-12-1 .content-area-l-12-shape {
    position: absolute;
    top: 15%;
    left: -7%;
    z-index: -1;
}

.content-area-l-12-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-12-1 .content {
        padding-top: 0;
    }
}

.content-area-l-12-1 .content a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #4EBE86;
    display: inline-block;
    margin-bottom: 40px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Testimonial Area L-12
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-12 {
    padding-bottom: 60px;
    position: relative;
}

@media (min-width: 768px) {
    .testimonial-area-l-12 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-12 {
        padding-bottom: 130px;
    }
}

.testimonial-area-l-12 .testimonial-border-area-l-12 {
    border-top: 1px solid #E5E5E5;
    margin: 50px 0px 50px 0px;
}

@media (min-width: 768px) {
    .testimonial-area-l-12 .testimonial-border-area-l-12 {
        margin: 70px 0px 70px 0px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-12 .testimonial-border-area-l-12 {
        margin: 130px 0px 120px 0px;
    }
}

.testimonial-area-l-12 .testimonial-area-l12-shape {
    position: absolute;
    right: 5%;
    bottom: -33%;
}

@media (min-width: 992px) {
    .testimonial-area-l-12 .section-heading-6 {
        padding-right: 35px;
    }
}

@media (min-width: 1200px) {
    .testimonial-area-l-12 .section-heading-6 {
        padding-right: 85px;
    }
}

.testimonial-area-l-12 .section-heading-6 h2 {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .testimonial-area-l-12 .section-heading-6 h2 {
        margin-bottom: 60px;
    }
}

.card--testimonial-l-12 {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
    height: 100%;
}

.testimonial-l-12-content {
    padding: 25px 30px 27px 30px;
}

.testimonial-l-12-content span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.642857px;
    color: #666666;
}

.testimonial-l-12-content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.425px;
    color: #000000;
    margin-bottom: 0;
}

.testimonial-l-12-content .video-area i {
    width: 54px;
    height: 54px;
    line-height: 54px;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    background: #4EBE86;
    color: #fff;
}

.l-12-slider-arrow-1 {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .l-12-slider-arrow-1 {
        margin-bottom: 0px;
    }
}

.l-12-slider-arrow-1 i {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #4EBE86;
    background: #DDF7EA;
    border-radius: 50%;
    margin: 0px 6px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.l-12-slider-arrow-1 .slick-active {
    color: #fff;
    background: #4EBE86;
}

.testimonial-slider-l-12 {
    margin: 0px -15px;
}

.testimonial-slider-l-12 .single-item {
    margin: 0px 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       FAQ Area L-12
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.faq-area-l-12 {
    background: #283730;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .faq-area-l-12 {
        padding-top: 70px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .faq-area-l-12 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.faq-area-l-12 .section-heading-6 {
    padding-bottom: 40px;
}

@media (min-width: 992px) {
    .faq-area-l-12 .section-heading-6 {
        padding-bottom: 60px;
    }
}

.faq-area-l-12 .section-heading-6 h2 {
    color: #fff;
}

.faq-area-l-12 .faq-l-12-shape {
    position: absolute;
    left: 0;
    bottom: 0;
}

.faq-area-l-12 .accordion li {
    position: relative;
    list-style: none;
    background: #384E44;
    border: 1px solid #20342B;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
    border-radius: 10px;
    padding: 27px 30px 29px 30px;
    margin-bottom: 15px;
}

.faq-area-l-12 .accordion li.active {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
    border-radius: 10px;
}

.faq-area-l-12 .accordion li.active a {
    color: #000;
}

.faq-area-l-12 .accordion li p {
    display: none;
    padding: 20px 15px 0px 0px;
    margin-bottom: 6px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.faq-area-l-12 .accordion a {
    width: 100%;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -1px;
    position: relative;
    padding-right: 20px;
}

.faq-area-l-12 .accordion a:hover {
    color: #fff;
}

.faq-area-l-12 .accordion li .active {
    color: #000 !important;
}

.faq-area-l-12 .accordion .active:after {
    border-right: 2px solid #94AEA2 !important;
    border-bottom: 2px solid #94AEA2 !important;
}

.faq-area-l-12 .accordion a:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid #94AEA2;
    border-bottom: 2px solid #94AEA2;
    position: absolute;
    right: 0px;
    content: " ";
    top: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
}

.faq-area-l-12 .accordion a.active:after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transition: all 0.2s ease-in-out;
}

.money-back-content-l12 {
    margin-top: 32px;
}

@media (min-width: 768px) {
    .money-back-content-l12 {
        margin-top: 52px;
    }
}

@media (min-width: 992px) {
    .money-back-content-l12 {
        margin-top: 80px;
    }
}

.money-back-content-l12 h2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: -2.33px;
    color: #FFFFFF;
}

@media (min-width: 576px) {
    .money-back-content-l12 h2 {
        font-size: 70px;
        line-height: 70px;
    }
}

.money-back-content-l12 h2 span {
    color: #4EBE86;
}

.money-back-content-l12 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #E5E5E5;
    margin-top: 20px;
}

.money-back-content-l12 .content-img {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .money-back-content-l12 .content-img {
        margin-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Newsletter Area L-12
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.newsletter-area-l-12 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .newsletter-area-l-12 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .newsletter-area-l-12 {
        padding-top: 140px;
        padding-bottom: 140px;
    }
}

.newsletter-area-l-12 .news-letter-l-12-shape {
    position: absolute;
    right: -15%;
    top: -47%;
}

.newsletter-area-l-12 span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #666666;
}

.newsletter-area-l-12 h2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 45px;
    text-align: center;
    letter-spacing: -2.53px;
    color: #000000;
    margin: 25px 0px 40px 0px;
}

@media (min-width: 576px) {
    .newsletter-area-l-12 h2 {
        font-size: 42px;
        line-height: 53px;
    }
}

@media (min-width: 768px) {
    .newsletter-area-l-12 h2 {
        font-size: 48px;
        line-height: 57px;
    }
}

.newsletter-area-l-12 .btn {
    min-width: 210px;
    height: 60px;
    background: #FC593B;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Footer Area L-12
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-12 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .footer-area-l-12 {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-12 {
        padding-bottom: 50px;
    }
}

.footer-area-l-12 .footer-l-12-top-border {
    border-top: 1px solid #E5E5E5;
    margin: 0px 0px 45px 0px;
}

@media (min-width: 768px) {
    .footer-area-l-12 .footer-l-12-top-border {
        margin: 0px 0px 65px 0px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-12 .footer-l-12-top-border {
        margin: 0px 0px 70px 0px;
    }
}

.footer-area-l-12 .footer-area-l-12-shape {
    position: absolute;
    left: -30%;
    bottom: 0%;
}

@media (min-width: 992px) {
    .footer-area-l-12 .footer-area-l-12-shape {
        left: -8%;
        bottom: -85%;
    }
}

.footer-area-l-12 .footer-widget p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 45px;
    letter-spacing: -0.321429px;
    color: #666666;
}

.footer-area-l-12 .footer-widget ul {
    margin-top: 25px;
}

.footer-area-l-12 .footer-widget ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: -0.4px;
    color: #000000;
}

@media (min-width: 576px) {
    .footer-area-l-12 .footer-widget.widget3 {
        margin-top: 70px;
    }
}

.footer-subs-form-l-12 {
    background: #DDF7EA;
    border-radius: 5px;
    padding: 20px 30px 30px 30px;
    margin-top: 20px;
}

@media (min-width: 992px) {
    .footer-subs-form-l-12 {
        margin-top: 0;
    }
}

.footer-subs-form-l-12 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 45px;
    letter-spacing: -0.321429px;
    color: #666666;
    margin-bottom: 5px;
}

.footer-subs-form-l-12 h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 20px;
}

.footer-subs-form-l-12 .input-group .form-control {
    background: #FFFFFF;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    color: #666666;
    width: 177px;
    height: 56px;
    border: 0;
    padding-left: 20px;
}

@media (min-width: 480px) {
    .footer-subs-form-l-12 .input-group .form-control {
        font-size: 15px;
        width: 320px;
    }
}

@media (min-width: 992px) {
    .footer-subs-form-l-12 .input-group .form-control {
        width: 250px;
    }
}

@media (min-width: 1200px) {
    .footer-subs-form-l-12 .input-group .form-control {
        width: 320px;
    }
}

.footer-subs-form-l-12 .input-group .form-control::-moz-placeholder {
    color: #666666;
}

.footer-subs-form-l-12 .input-group .form-control:-ms-input-placeholder {
    color: #666666;
}

.footer-subs-form-l-12 .input-group .form-control::placeholder {
    color: #666666;
}

.footer-subs-form-l-12 .input-group .btn {
    min-width: 60px;
    height: 56px;
    background: #4EBE86;
    border-radius: 0px 5px 5px 0px;
}

.footer-subs-form-l-12 .input-group .btn i {
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L4
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-13-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-13-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-13-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-13-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.hero-area-l-13 {
    padding-top: 105px;
    padding-bottom: 30px;
}

@media (min-width: 480px) {
    .hero-area-l-13 {
        padding-top: 110px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-13 {
        padding-top: 110px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-13 {
        padding-top: 225px;
        padding-bottom: 0px;
    }
}

.hero-area-l-13 .content {
    position: relative;
    z-index: 5;
    padding-top: 25px;
}

@media (min-width: 992px) {
    .hero-area-l-13 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-13 .content {
        padding-right: 0;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-13 .content {
        padding-right: 82px;
    }
}

.hero-area-l-13 .content h1 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -5px;
    color: #1F1F1F;
    margin-bottom: 10px;
}

@media (min-width: 576px) {
    .hero-area-l-13 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-13 .content h1 {
        font-size: 60px;
        line-height: 70px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-13 .content h1 {
        font-size: 70px;
        line-height: 72px;
    }
}

.hero-area-l-13 .content p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.3px;
    color: #222F3F;
    margin: 25px 0px 35px 0px;
}

.hero-area-l-13 .content .btn {
    min-width: 210px;
    height: 60px;
    background: #FC593B;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #FFFFFF;
    margin-top: 22px;
}

/* .hero-area-l-13 .bg-shape-13 {
    background: url(../image/l3/hero-bg.png);
    background-repeat: no-repeat;
    position: absolute;
    top: -50%;
    height: 135%;
    width: 135%;
    background-size: cover;
    left: -30%;
    transform: rotate(-11deg);
    z-index: -1;
} */

.hero-area-l-13 .apps-btn {
    margin: 0px -6px -10px -6px;
}

.hero-area-l-13 .apps-btn a {
    margin: 0px 6px 10px 6px;
    display: inline-block;
}

@media (min-width: 992px) {
    .banner-image-l-13 {
        margin-top: -100px;
    }
}

.header-btn-l-13 .download-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    min-width: 152px;
    height: 40px;
    background: #333333;
    border-radius: 3px;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-1 section start-L-13
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-13-1 {
    padding-top: 65px;
}

@media (min-width: 768px) {
    .content-area-l-13-1 {
        padding-top: 65px;
    }
}

@media (min-width: 992px) {
    .content-area-l-13-1 {
        padding-top: 65px;
    }
}

.content-area-l-13-1 .content {
    padding-top: 45px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .content-area-l-13-1 .content {
        padding-top: 0;
        padding-bottom: 0px;
    }
}

@media (min-width: 576px) {
    .app-image-position-l-13 {
        margin-bottom: 0px;
    }
}

@media (min-width: 768px) {
    .app-image-position-l-13 {
        margin-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .app-image-position-l-13 {
        margin-bottom: -200px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-2 section start-L-13
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-13-2 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-area-l-13-2 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-13-2 {
        padding-top: 115px;
    }
}

.content-area-l-13-2 .content {
    padding-top: 45px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .content-area-l-13-2 .content {
        padding-top: 0;
        padding-bottom: 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-3 section start-L-13
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-13-3 {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .content-area-l-13-3 {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .content-area-l-13-3 {
        padding-top: 120px;
    }
}

.content-area-l-13-3 .content {
    padding-top: 45px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .content-area-l-13-3 .content {
        padding-top: 0;
        padding-bottom: 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Testimonial -L-13
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-13 {
    padding-top: 45px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .testimonial-area-l-13 {
        padding-top: 65px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-13 {
        padding-top: 115px;
        padding-bottom: 130px;
    }
}

.testimonial-area-l-13 .section-heading-7 {
    padding-bottom: 35px;
}

.testimonial-area-l-13 .testimonial-area-l-13-items {
    margin-bottom: -25px;
}

.testimonial-area-l-13 .testimonial-area-l-13-items .col-xl-4 {
    margin-bottom: 25px;
}

/* .testimonial-area-l-13 .bg-shape-3 {
    background: url(../image/l3/testimonial-bg.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: -68%;
    height: 173%;
    width: 155%;
    left: -40%;
    transform: rotate(-7deg);
    background-size: cover;
    z-index: -1;
} */

.testimonial-area-l-13 .testimonial-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.036062);
    border-radius: 5px;
    padding: 33px 35px 18px 35px;
}

.testimonial-area-l-13 .testimonial-card p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: #1F1F1F;
}

.testimonial-area-l-13 .testimonial-card img {
    margin-right: 22px;
}

.testimonial-area-l-13 .testimonial-card .content-body {
    margin-top: -8px;
}

.testimonial-area-l-13 .testimonial-card .content-body h5 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.31875px;
    color: #1F1F1F;
    margin-bottom: 0;
}

.testimonial-area-l-13 .testimonial-card .content-body p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.testimonial-area-l-13 .testimonial-card .customer-identity {
    margin-top: 30px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    CTA-area-L-13 
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-13 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l-13 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-13 {
        padding-bottom: 130px;
    }
}

.cta-area-l-13 .content span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #666666;
}

.cta-area-l-13 .content h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -5px;
    color: #1F1F1F;
    margin: 40px 0px 50px 0px;
}

@media (min-width: 768px) {
    .cta-area-l-13 .content h2 {
        font-size: 48px;
        line-height: 58px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-13 .content h2 {
        font-size: 60px;
        line-height: 70px;
    }
}

.cta-area-l-13 .apps-btn {
    margin: 0px -6px -10px -6px;
}

.cta-area-l-13 .apps-btn a {
    margin: 0px 6px 10px 6px;
    display: inline-block;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Footer-area-L-13 
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-l-13 .footer-l-13-items {
    padding: 30px 0px;
}

.footer-l-13 .footer-border-l-13 {
    border-top: 1px solid #E5E5E5;
}

.footer-widget-l-13 {
    margin: 30px 0px;
}

@media (min-width: 768px) {
    .footer-widget-l-13 {
        margin: 0px 0px;
    }
}

.footer-widget-l-13 ul {
    margin: 0px -15px 0px -15px;
}

.footer-widget-l-13 ul li {
    margin: 0px 15px 0px 15px;
}

.footer-widget-l-13 ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.642857px;
    color: #333333;
}

.copyright-area-l-13 p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.642857px;
    color: #666666;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-14-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-14-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-14-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-14-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.hero-area-l-14 {
    padding-top: 105px;
    padding-bottom: 0px;
}

@media (min-width: 480px) {
    .hero-area-l-14 {
        padding-top: 110px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-14 {
        padding-top: 110px;
        padding-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-14 {
        padding-top: 225px;
        padding-bottom: 25px;
    }
}

.hero-area-l-14 .content {
    position: relative;
    z-index: 5;
    padding-top: 25px;
}

@media (min-width: 992px) {
    .hero-area-l-14 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-14 .content {
        padding-right: 0;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-14 .content {
        padding-right: 82px;
    }
}

.hero-area-l-14 .content h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -3.11px;
    color: #1F1F1F;
    margin-bottom: 10px;
}

@media (min-width: 576px) {
    .hero-area-l-14 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-14 .content h1 {
        font-size: 60px;
        line-height: 66px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-14 .content h1 {
        font-size: 70px;
        line-height: 76px;
    }
}

.hero-area-l-14 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.3px;
    color: #222F3F;
    margin: 18px 0px 30px 0px;
}

.hero-area-l-14 .content .btn {
    min-width: 205px;
    height: 60px;
    background: #333333;
    border-radius: 3px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    margin-top: 10px;
}

/* .hero-area-l-14 .bg-shape-14 {
    background: url(../image/l4/hero-bg.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    left: 0;
    transform: rotate(0deg);
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
} */

@media (min-width: 992px) {
    .hero-area-l-14 .bg-shape-14 {
        top: -25px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-14 .bg-shape-14 {
        top: -15px;
    }
}

.header-btn-l-14 .trail-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    min-width: 152px;
    height: 40px;
    background: #333333;
    border-radius: 3px;
    margin-left: 15px;
}

.banner-image-l-14 {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .banner-image-l-14 {
        padding-top: 0;
        margin-top: 100px;
    }
}

@media (min-width: 1200px) {
    .banner-image-l-14 {
        margin-top: -100px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Brand Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-14 {
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .brand-area-l-14 {
        padding-top: 80px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .brand-area-l-14 {
        padding-top: 100px;
        padding-bottom: 130px;
    }
}

.brand-area-l-14 h4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    padding-bottom: 35px;
}

.brand-area-l-14-items {
    margin: 0px -20px -45px -20px;
}

.brand-area-l-14-items .single-brand {
    margin: 0px 20px 45px 20px;
}

.brand-aarea-border-l14 {
    border-top: 1px solid #E4E4E4;
    margin: 60px 0px 0px 0px;
}

@media (min-width: 768px) {
    .brand-aarea-border-l14 {
        margin: 80px 0px 0px 0px;
    }
}

@media (min-width: 992px) {
    .brand-aarea-border-l14 {
        margin: 100px 0px 0px 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-area-1-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-14-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-14-1 .content {
        padding-top: 0;
    }
}

.content-area-l-14-1 .content .btn {
    border: 0;
    padding: 0;
    min-width: initial;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #0C39F8;
    margin-top: 30px;
}

@media (min-width: 768px) {
    .content-area-l-14-1 .content .btn {
        margin-top: 50px;
    }
}

.content-area-l-14-1 .content .btn i {
    margin-left: 22px;
}

.content-area-l-14-1 .content .btn:hover {
    color: #0C39F8 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Content-area-2-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-14-2 {
    padding-top: 80px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .content-area-l-14-2 {
        padding-top: 80px;
        padding-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .content-area-l-14-2 {
        padding-top: 130px;
        padding-bottom: 30px;
    }
}

.content-area-l-14-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-14-2 .content {
        padding-top: 0;
    }
}

.content-area-l-14-2 .content .btn {
    border: 0;
    padding: 0;
    min-width: initial;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #0C39F8;
    margin-top: 30px;
}

@media (min-width: 768px) {
    .content-area-l-14-2 .content .btn {
        margin-top: 50px;
    }
}

.content-area-l-14-2 .content .btn i {
    margin-left: 22px;
}

.content-area-l-14-2 .content .btn:hover {
    color: #0C39F8 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Areea L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.features-area-l-14 .features-area-l-14 {
    margin-bottom: -45px;
}

.features-area-l-14 .features-area-l-14 .col-lg-6 {
    margin-bottom: 45px;
}

.features-area-l-14 .bg-shape-img-2 {
    background: #0C39F8;
    padding: 80px 0px;
}

@media (min-width: 992px) {
    /* .features-area-l-14 .bg-shape-img-2 {
        background: url(../image/l4/features-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0%;
        padding: 175px 30px 112px 30px;
    } */
}

@media (min-width: 1200px) {
    .features-area-l-14 .bg-shape-img-2 {
        padding: 175px 87px 112px 87px;
    }
}

@media (min-width: 1600px) {
    .features-area-l-14 .bg-shape-img-2 {
        padding: 190px 175px 190px 175px;
    }
}

.features-area-l-14 .content-area img {
    margin-right: 25px;
}

@media (min-width: 576px) {
    .features-area-l-14 .content-area img {
        margin-right: 60px;
    }
}

.features-area-l-14 .content-area h5 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1.33333px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.features-area-l-14 .content-area p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    /* or 165% */
    letter-spacing: -0.4px;
    color: #E4E4E4;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Pricing Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.pricing-area-l14 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .pricing-area-l14 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l14 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.pricing-area-l14 .section-heading-8 {
    margin-bottom: 70px;
}

.pricing-area-l14 .l-14-pricing-table {
    margin-top: 40px;
}

.pricing-area-l14 .l-14-pricing-table .single-price .btn {
    min-width: 200px;
    height: 50px;
    background: #333333;
    border-radius: 3px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.pricing-area-l14 .l-14-pricing-table .single-price .price-btn-bro .btn {
    background: #14EE80;
    color: #333333;
}

.pricing-area-l14 .l-14-pricing-table .single-price span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 32px;
    /* identical to box height, or 267% */
    text-align: center;
    text-transform: uppercase;
    color: #666666;
}

.pricing-area-l14 .l-14-pricing-table .single-price .main-price .price span {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 58px;
    line-height: 50px;
    /* identical to box height, or 86% */
    text-align: center;
    color: #000000;
}

.pricing-area-l14 .l-14-pricing-table .single-price .main-price .price h2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 58px;
    line-height: 50px;
    /* identical to box height, or 86% */
    text-align: center;
    color: #000000;
}

.pricing-area-l14 .l-14-pricing-table .single-price .main-price span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    text-align: center;
    letter-spacing: -0.428571px;
    color: #666666;
    display: inline-block;
    margin-bottom: 25px;
}

.pricing-area-l14 .l-14-pricing-table .single-price .price-body ul {
    padding: 15px 0px 15px 0px;
}

.pricing-area-l14 .l-14-pricing-table .single-price .price-body ul li {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 53px;
    /* or 331% */
    text-align: center;
    letter-spacing: -0.761905px;
    color: #1F1F1F;
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-yearly);
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
    background: #1290a4;
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
    left: calc(100% - 24px);
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
    background: #1290a4;
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
    left: calc(100% - 52%);
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
    color: #666666 !important;
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
    color: #fff !important;
}

.pricing-area-l14 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-monthly);
}

.pricing-area-l14 .btn-toggle-2[data-pricing-trigger].active:before {
    color: #fff !important;
}

.pricing-area-l14 .btn-toggle-2[data-pricing-trigger].active:after {
    color: #666666 !important;
}

.pricing-area-l14 .dynamic-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l14 .static-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l14 .btn-toggle {
    width: 48px;
    height: 24px;
    border-radius: 17px;
    background-color: #f8f8f8 !important;
    position: relative;
    margin: 0 15px;
}

.pricing-area-l14 .btn-toggle span {
    width: 16px;
    height: 16px;
    background-color: #1290a4;
    position: absolute;
    left: 5px;
    top: 4px;
    transition: 0.4s;
    border-radius: 500px;
    pointer-events: none;
}

.pricing-area-l14 .btn-toggle-2 {
    width: 220px;
    height: 42px;
    border-radius: 30px;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    position: relative;
    margin: 0 15px;
    z-index: 1;
}

.pricing-area-l14 .btn-toggle-2:before {
    position: absolute;
    content: "Monthly";
    width: 46%;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    height: 34px;
    background: transparent;
    left: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l14 .btn-toggle-2:after {
    position: absolute;
    content: "Yearly";
    width: 46%;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    height: 34px;
    background: transparent;
    right: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l14 .btn-toggle-2 .round {
    width: 50%;
    /* left: 0 !important; */
    height: 34px;
    top: 3px;
    left: 4px;
    background: blue;
    display: block;
    z-index: -1;
}

.pricing-area-l14 .btn-toggle-2 span {
    display: none;
}

.pricing-area-l14 .btn-toggle[data-value-active="yearly"] {
    background-color: #1290a4 !important;
}

.pricing-area-l14 .btn-toggle[data-value-active="yearly"] span {
    left: calc(100% - 25px) !important;
}

.pricing-area-l14 .btn-toggle-square {
    height: 65px;
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 5px;
    align-items: center;
}

.pricing-area-l14 .btn-toggle-square.bg-whisper-3:hover,
.pricing-area-l14 .btn-toggle-square.bg-whisper-3:focus {
    background: #8e8e8e !important;
}

.pricing-area-l14 .btn-toggle-square span {
    min-width: 160px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.pricing-area-l14 .btn-toggle-square span:first-child {
    border-radius: 10px 0 0 10px;
}

.pricing-area-l14 .btn-toggle-square span:nth-child(2) {
    border-radius: 0px 10px 10px 0;
}

.pricing-area-l14 .btn-toggle-square span.active {
    color: #fff;
    background: #1290a4;
}

.pricing-area-l14 .popular-pricing:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 4px;
    background: #f46f7c;
    border-radius: 6px;
}

.pricing-area-l14 .popular-pricing.popular-pricing-2:before {
    height: 3px;
    top: -3px;
    background: #14ee80;
}

.pricing-area-l14 .popular-pricing.popular-pricing-3:before {
    height: 3px;
    top: -3px;
    background: #50e3c2;
}

.pricing-area-l14 .active-border {
    position: relative;
}

.pricing-area-l14 .active-border:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 5px;
    background: #5029de;
    border-radius: 5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Cta Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-14 {
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .cta-area-l-14 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-14 {
        padding-bottom: 120px;
    }
}

.cta-area-l-14 .bg-shape-img-3 {
    height: 100%;
    background: #0C39F8;
}

@media (min-width: 992px) {
    /* .cta-area-l-14 .bg-shape-img-3 {
        background: url(../image/l4/cta-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left bottom;
        width: 97%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    } */
}

.cta-area-l-14 .cta-area-l-14-content {
    padding: 40px 0px 60px 0px;
}

@media (min-width: 768px) {
    .cta-area-l-14 .cta-area-l-14-content {
        padding: 60px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-14 .cta-area-l-14-content {
        padding: 100px 0px 72px 0px;
    }
}

.cta-area-l-14 .cta-area-l-14-content h2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    /* identical to box height, or 154% */
    letter-spacing: -2.52632px;
    color: #FFFFFF;
}

@media (min-width: 768px) {
    .cta-area-l-14 .cta-area-l-14-content h2 {
        font-size: 48px;
        line-height: 60px;
    }
}

.cta-area-l-14 .cta-area-l-14-content .btn {
    background: #14EE80;
    border-radius: 3px;
    min-width: 195px;
    height: 60px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #333333;
    margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-15-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-15-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-15-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-15-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.hero-area-l-15 {
    background: #E3EEF8;
    padding-top: 80px;
    padding-bottom: 40px;
}

@media (min-width: 480px) {
    .hero-area-l-15 {
        padding-top: 90px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-15 {
        padding-top: 110px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-15 {
        padding-top: 250px;
        padding-bottom: 175px;
    }
}

.hero-area-l-15 .content {
    position: relative;
    z-index: 5;
    padding-top: 25px;
}

@media (min-width: 992px) {
    .hero-area-l-15 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-15 .content {
        padding-right: 0;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-15 .content {
        padding-right: 82px;
    }
}

.hero-area-l-15 .content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #666666;
}

.hero-area-l-15 .content h1 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -3.11px;
    color: #1F1F1F;
    margin: 27px 0px 20px 0px;
}

@media (min-width: 576px) {
    .hero-area-l-15 .content h1 {
        font-size: 40px;
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-15 .content h1 {
        font-size: 60px;
        line-height: 66px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-15 .content h1 {
        font-size: 70px;
        line-height: 70px;
    }
}

.hero-area-l-15 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #666666;
    margin: 18px 0px 15px 0px;
}

.hero-area-l-15 .rating-text {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.32381px;
    color: #878B90;
}

.header-btn-l-15 .get-start-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #258AFF;
    min-width: 152px;
    height: 40px;
    border: 2px solid #258AFF;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 15px;
}

.header-btn-l-15 .get-start-btn:hover {
    color: #258AFF !important;
}

.landing-15-menu.reveal-header .get-start-btn {
    color: #258AFF !important;
}

.subscription-form-15 {
    margin-bottom: 25px;
}

.subscription-form-15 .form-control {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.9px;
    max-width: 100%;
    height: 60px;
    background: #EEF4F5;
    border-radius: 3px;
    padding-left: 25px;
    border: 0;
    margin-top: 20px;
}

@media (min-width: 480px) {
    .subscription-form-15 .form-control {
        max-width: 275px;
    }
}

.subscription-form-15 .form-control::-moz-placeholder {
    color: #878B90;
}

.subscription-form-15 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.subscription-form-15 .form-control::placeholder {
    color: #878B90;
}

.subscription-form-15 .btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #FFFFFF;
    min-width: 100%;
    height: 55px;
    border-radius: 3px;
    padding: 0px 15px;
    background: #FC593B;
    margin-top: 15px;
}

@media (min-width: 480px) {
    .subscription-form-15 .btn {
        margin-top: 0;
        min-width: 171px;
        height: 60px;
        margin-left: 5px;
        margin-top: -6px;
    }
}

@media (min-width: 576px) {
    .subscription-form-15 .btn {
        min-width: 209px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Feature Area-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l-15 {
    padding-bottom: 40px;
    background: #E3EEF8;
}

@media (min-width: 768px) {
    .feature-area-l-15 {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-15 {
        padding-bottom: 110px;
    }
}

.feature-area-l-15-items {
    margin-bottom: -25px;
}

.feature-area-l-15-items .col-lg-4 {
    margin-bottom: 25px;
}

.feature-area-l-15-items .content img {
    margin-right: 25px;
}

.feature-area-l-15-items .content .text-box {
    margin-top: -7px;
    padding-right: 30px;
}

.feature-area-l-15-items .content .text-box h3 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -1px;
    color: #000000;
}

.feature-area-l-15-items .content .text-box p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: #666666;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content-Area-1-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-15-1 {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #258AFF;
}

@media (min-width: 768px) {
    .content-area-l-15-1 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-1 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-area-l-15-1 .content {
    padding-top: 45px;
}

@media (min-width: 768px) {
    .content-area-l-15-1 .content {
        padding-top: 0;
    }
}

@media (min-width: 1600px) {
    .content-area-l-15-1 .content {
        padding-right: 31px;
    }
}

.content-area-l-15-1 .content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .content-area-l-15-1 .content h6 {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-1 .content h6 {
        margin-bottom: 55px;
    }
}

.content-area-l-15-1 .content h2 {
    color: #FFFFFF;
}

.content-area-l-15-1 .content p {
    color: #E4E4E4;
}

.content-area-l-15-1 .content .btn {
    min-width: 210px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.9px;
    color: #258AFF;
    margin-top: 48px;
}

.content-area-l-15-1 .content .btn:hover {
    color: #258AFF !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content-Area-2-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-15-2 {
    padding-top: 60px;
    padding-bottom: 40px;
    background: #E3EEF8;
}

@media (min-width: 768px) {
    .content-area-l-15-2 {
        padding-top: 80px;
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-2 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-area-l-15-2 .content {
    padding-top: 45px;
}

@media (min-width: 768px) {
    .content-area-l-15-2 .content {
        padding-top: 0;
    }
}

.content-area-l-15-2 .content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #258AFF;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .content-area-l-15-2 .content h6 {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-2 .content h6 {
        margin-bottom: 55px;
    }
}

.content-area-l-15-2 .content p {
    color: #666666;
}

.content-area-l-15-2 .services-content-area {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .content-area-l-15-2 .services-content-area {
        margin-top: 50px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-2 .services-content-area {
        margin-top: 70px;
    }
}

.content-area-l-15-2 .services-content-area .services-icon {
    background: #258AFF;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    margin-bottom: 32px;
}

.content-area-l-15-2 .services-content-area .services-content {
    padding-right: 20px;
}

@media (min-width: 992px) {
    .content-area-l-15-2 .services-content-area .services-content {
        padding-right: 20px;
    }
}

@media (min-width: 1200px) {
    .content-area-l-15-2 .services-content-area .services-content {
        padding-right: 53px;
    }
}

.content-area-l-15-2 .services-content-area .services-content h3 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 13px;
}

.content-area-l-15-2 .services-content-area .services-content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: #666666;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content-Area-3-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-15-3 {
    padding-bottom: 60px;
    background: #E3EEF8;
}

@media (min-width: 768px) {
    .content-area-l-15-3 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-3 {
        padding-bottom: 130px;
    }
}

.content-area-l-15-3 .content-area-l-15-3-border {
    border-top: 1px solid #FFFFFF;
    margin: 0px 0px 60px 0px;
}

@media (min-width: 768px) {
    .content-area-l-15-3 .content-area-l-15-3-border {
        margin: 0px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-3 .content-area-l-15-3-border {
        margin: 0px 0px 130px 0px;
    }
}

.content-area-l-15-3 .content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #258AFF;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .content-area-l-15-3 .content h6 {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .content-area-l-15-3 .content h6 {
        margin-bottom: 55px;
    }
}

.content-area-l-15-3 .content p {
    color: #666666;
}

.content-area-l-15-3-main-items {
    padding-top: 45px;
    margin-bottom: -25px;
}

@media (min-width: 992px) {
    .content-area-l-15-3-main-items {
        padding-top: 0;
    }
}

.content-area-l-15-3-main-items .col-lg-6 {
    margin-bottom: 25px;
}

.content-area-l-15-3-items {
    margin-bottom: -25px;
}

.content-area-l-15-3-items .col-lg-12 {
    margin-bottom: 25px;
}

.content-area-l-15-3-items .card-content {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.036062);
    border-radius: 5px;
    padding: 35px 35px 35px 35px;
}

.content-area-l-15-3-items .card-content p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: #1F1F1F;
}

.content-area-l-15-3-items .card-content .user-meta {
    margin-top: 30px;
}

.content-area-l-15-3-items .card-content .user-meta img {
    margin-right: 22px;
}

.content-area-l-15-3-items .card-content .user-meta .user-info {
    margin-top: -5px;
}

.content-area-l-15-3-items .card-content .user-meta .user-info h5 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -0.31875px;
    color: #1F1F1F;
    margin-bottom: 0;
}

.content-area-l-15-3-items .card-content .user-meta .user-info span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
    display: inline-block;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Brand-Area-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-15 {
    padding-bottom: 60px;
    background: #E3EEF8;
}

@media (min-width: 768px) {
    .brand-area-l-15 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .brand-area-l-15 {
        padding-bottom: 130px;
    }
}

.brand-area-l-15-items {
    margin: 0px -20px -45px -20px;
}

.brand-area-l-15-items .single-brand {
    margin: 0px 20px 45px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
           Pricing-Area-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .pricing-area-l-15 {
    background: url(../image/l5/l5-pricing-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
} */

.pricing-area-l-15 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .pricing-area-l-15 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l-15 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.pricing-area-l-15 .section-heading-9 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .pricing-area-l-15 .section-heading-9 {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l-15 .section-heading-9 {
        margin-bottom: 75px;
    }
}

.pricing-area-l-15 .section-heading-9 h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .pricing-area-l-15 .section-heading-9 h6 {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l-15 .section-heading-9 h6 {
        margin-bottom: 55px;
    }
}

.pricing-area-l-15 .section-heading-9 h2 {
    color: #fff;
}

.pricing-area-l-15-items {
    margin-bottom: -25px;
}

.pricing-area-l-15-items .col-xl-4 {
    margin-bottom: 25px;
}

.pricing-area-l-15-items .single-table {
    height: 100%;
    padding: 30px 30px 15px 30px;
}

@media (min-width: 480px) {
    .pricing-area-l-15-items .single-table {
        padding: 30px 45px 15px 45px;
    }
}

.pricing-area-l-15-items .l-15-table-1 {
    background: #2B405A;
    border-radius: 6px;
}

.pricing-area-l-15-items .l-15-table-1 .table-top span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.433333px;
    text-transform: uppercase;
    color: #E5E5E5;
    display: inline-block;
    margin-bottom: 30px;
}

.pricing-area-l-15-items .l-15-table-1 .main-price span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -1.2px;
    color: #FFFFFF;
    display: inline-block;
    margin-right: 8px;
}

.pricing-area-l-15-items .l-15-table-1 .main-price h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -2px;
    color: #FFFFFF;
    margin-top: -8px;
}

.pricing-area-l-15-items .l-15-table-1 .main-price h2 span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.68px;
    color: #FFFFFF;
    display: inline-block;
    margin-left: 5px;
}

.pricing-area-l-15-items .l-15-table-1 .main-price p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.4px;
    color: #E5E5E5;
}

.pricing-area-l-15-items .l-15-table-1 .table-body {
    margin-top: 25px;
}

.pricing-area-l-15-items .l-15-table-1 .table-body ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: -0.425px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.pricing-area-l-15-items .l-15-table-1 .table-body ul li i {
    margin-right: 10px;
}

.pricing-area-l-15-items .l-15-table-1 .table-body .del {
    color: #878B90;
}

.pricing-area-l-15-items .l-15-table-1 .table-body del {
    color: #878B90;
}

.pricing-area-l-15-items .l-15-table-1 .table-btn .btn {
    min-width: 190px;
    height: 50px;
    background: #258AFF;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1.06px;
    color: #FFFFFF;
    margin-top: 22px;
}

.pricing-area-l-15-items .l-15-table-1 .table-btn p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #878B90;
    margin-top: 10px;
}

.pricing-area-l-15-items .l-15-table-2 {
    background: #fff;
    border-radius: 6px;
}

.pricing-area-l-15-items .l-15-table-2 .table-top .popular-item {
    background: #4EAB08;
    border-radius: 13px;
    color: #fff;
    padding: 0px 15px;
}

.pricing-area-l-15-items .l-15-table-2 .table-top span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.433333px;
    text-transform: uppercase;
    color: #666666;
    display: inline-block;
    margin-bottom: 30px;
}

.pricing-area-l-15-items .l-15-table-2 .main-price span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -1.2px;
    color: #1F1F1F;
    display: inline-block;
    margin-right: 8px;
}

.pricing-area-l-15-items .l-15-table-2 .main-price h2 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -2px;
    color: #1F1F1F;
    margin-top: -8px;
}

.pricing-area-l-15-items .l-15-table-2 .main-price h2 span {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.68px;
    color: #1F1F1F;
    display: inline-block;
    margin-left: 5px;
}

.pricing-area-l-15-items .l-15-table-2 .main-price p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.4px;
    color: #666666;
}

.pricing-area-l-15-items .l-15-table-2 .table-body {
    margin-top: 25px;
}

.pricing-area-l-15-items .l-15-table-2 .table-body ul li {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: -0.425px;
    color: #1F1F1F;
    margin-bottom: 8px;
}

.pricing-area-l-15-items .l-15-table-2 .table-body ul li i {
    margin-right: 10px;
}

.pricing-area-l-15-items .l-15-table-2 .table-btn .btn {
    min-width: 190px;
    height: 50px;
    background: #FC593B;
    border-radius: 5px;
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1.06px;
    color: #FFFFFF;
    margin-top: 22px;
}

.pricing-area-l-15-items .l-15-table-2 .table-btn p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #878B90;
    margin-top: 10px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Footer-Area-L-15
                  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-l-15 {
    background: #E3EEF8;
    padding-top: 60px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-l-15 {
        padding-top: 80px;
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-l-15 {
        padding-top: 130px;
        padding-bottom: 100px;
    }
}

.footer-l-15 .content {
    margin: 35px 0px 33px 0px;
}

.footer-l-15 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.footer-l-15 .footer-widget p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-bottom: 35px;
}

.footer-l-15 .footer-widget ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: -0.4px;
    color: #1F1F1F;
}

.footer-l-15 .social-icons {
    margin-bottom: 50px;
}

@media (min-width: 992px) {
    .footer-l-15 .social-icons {
        margin-bottom: 0;
    }
}

.footer-l-15 .social-icons ul {
    margin: 0px -9px 0px -9px;
}

.footer-l-15 .social-icons ul li {
    margin: 0px 9px 0px 9px;
}

.footer-l-15 .social-icons ul li i {
    font-size: 16px;
    color: #1f1f1f !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-14
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-16-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-16-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-16-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-16-menu.reveal-header .sign-in-btn {
    color: #666666 !important;
}

.hero-area-l-16 {
    background: #E3EEF8;
    padding-top: 45px;
    padding-bottom: 0px;
}

@media (min-width: 480px) {
    .hero-area-l-16 {
        padding-top: 60px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-16 {
        padding-top: 78px;
        padding-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-16 {
        padding-top: 160px;
        padding-bottom: 110px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-16 {
        padding-top: 160px;
        padding-bottom: 0px;
    }
}

.hero-area-l-16 .content {
    position: relative;
    z-index: 5;
    padding-top: 25px;
}

@media (min-width: 992px) {
    .hero-area-l-16 .content {
        padding-right: 5px;
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-16 .content {
        padding-right: 0;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-16 .content {
        padding-right: 82px;
    }
}

.hero-area-l-16 .content h6 {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2.8125px;
    text-transform: uppercase;
    color: #666666;
}

.hero-area-l-16 .content h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #000;
    margin: 27px 0px 20px 0px;
}

@media (min-width: 576px) {
    .hero-area-l-16 .content h1 {
        font-size: 40px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-16 .content h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-16 .content h1 {
        font-size: 56px;
        line-height: 68px;
    }
}

.hero-area-l-16 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #000000;
    margin: 30px 0px 38px 0px;
}

.hero-area-l-16 .content .hero-btns {
    margin: 0px -7px -15px -7px;
    text-align: center;
}

@media (min-width: 480px) {
    .hero-area-l-16 .content .hero-btns {
        text-align: left;
    }
}

.hero-area-l-16 .content .hero-btns a {
    margin: 0px 7px 15px 7px;
}

.hero-area-l-16 .content .hero-btns i {
    margin-right: 15px;
    font-size: x-large;
}

.hero-area-l-16 .content .hero-btns .btn {
    background: #2780FF;
    border-radius: 5px;
    min-width: 238px;
    height: 60px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.hero-area-l-16 .star-ratings {
    margin-top: 20px;
}

.hero-area-l-16 .star-ratings .star-dif-color {
    color: #E5E5E5;
}

.hero-area-l-16 .star-ratings i {
    color: #FA8231;
}

.hero-area-l-16 .star-ratings .rating-text {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.32381px;
    color: #535659;
    margin-left: 15px;
}

@media (min-width: 480px) {
    .hero-area-l-16 .star-ratings .rating-text {
        font-size: 17px;
    }
}

.hero-area-l-16 .hero-shape-1 {
    position: absolute;
    bottom: 0;
    left: 7%;
}

.hero-area-l-16 .hero-img {
    margin-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l-16 .hero-img {
        margin-top: 0;
    }
}

.hero-area-l-16 .hero-img .hero-shape-2 {
    position: absolute;
    top: 0;
    left: -45px;
    z-index: -1;
    width: 87%;
}

@media (min-width: 1200px) {
    .hero-area-l-16 .hero-img .hero-shape-2 {
        width: initial;
    }
}

.header-btn-l-16 .download-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #fff;
    min-width: 152px;
    height: 40px;
    background: #258AFF;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Feature Area-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l6 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-area-l6 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-area-l6 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.feature-area-l6 .feature-area-l6-items {
    margin-bottom: -30px;
}

.feature-area-l6 .feature-area-l6-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .feature-area-l6 .content-area {
        padding-right: 10px;
    }
}

@media (min-width: 1200px) {
    .feature-area-l6 .content-area {
        padding-right: 96px;
    }
}

.feature-area-l6 .content-area h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 64px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.15;
    margin-bottom: 60px;
}

.feature-area-l6 .content-area h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 25px;
}

.feature-area-l6 .content-area p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    color: #323232;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Content Area 1-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-1-l-16 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-1-l-16 .content {
        padding-top: 0px;
    }
}

.content-area-1-l-16 .content span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-block;
    background: #FA8231;
    border-radius: 15px;
    padding: 8px 20px;
    margin-bottom: 30px;
    display: inline-flex;
    align-items: center;
}

.content-area-1-l-16 .content span i {
    margin-right: 10px;
}

@media (min-width: 992px) {
    .content-area-1-l-16 .image-area {
        margin-bottom: -155px;
    }
}

.content-area-1-l-16 .image-area img {
    border-radius: 55px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Content Area 2-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-2-l-16 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-area-2-l-16 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-2-l-16 {
        padding-top: 130px;
    }
}

.content-area-2-l-16 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-2-l-16 .content {
        padding-top: 0px;
    }
}

.content-area-2-l-16 .content h2 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .content-area-2-l-16 .content h2 {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .content-area-2-l-16 .content h2 {
        margin-bottom: 70px;
    }
}

.content-area-2-l-16 .content .single-item {
    margin-bottom: 40px;
}

.content-area-2-l-16 .content .single-item i {
    margin-right: 25px;
    color: #fff;
    background: #2780FF;
    height: 55px;
    width: 55px;
    line-height: 55px;
    text-align: center;
    font-size: x-large;
}

.content-area-2-l-16 .content .content-body {
    margin-top: -8px;
}

.content-area-2-l-16 .content .content-body h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    color: #000000;
}

.content-area-2-l-16 .content .content-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    color: #323232;
}

@media (min-width: 992px) {
    .content-area-2-l-16 .image-area {
        margin-bottom: -155px;
    }
}

.content-area-2-l-16 .image-area img {
    border-radius: 55px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        ScreenShot Area-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.screenshot-area-l-16 {
    background: #222D3D;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .screenshot-area-l-16 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .screenshot-area-l-16 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.screenshot-area-l-16 .section-heading-10 h2 {
    color: #fff;
}

.screenshot-area-l-16 .section-heading-10 p {
    color: #DBE4F1;
}

.screenshot-slider-wrapper-l-16 {
    position: relative;
    margin-top: 20px;
}

.screenshot-slider-wrapper-l-16 .screenshot-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    min-height: 720px !important;
    max-height: 720px !important;
}

@media (min-width: 690px) {
    .screenshot-slider-wrapper-l-16 .screenshot-image {
        width: 274px;
        min-height: 720px !important;
        max-height: 720px !important;
        margin-bottom: 0;
    }
}

@media (min-width: 690px) {
    .screenshot-slider-wrapper-l-16 .screenshot-image img {
        width: 100%;
        border-radius: 20px;
    }
}

.screenshot-slider-wrapper-l-16 .phone-bg-img {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 11;
    display: none;
}

@media (min-width: 690px) {
    .screenshot-slider-wrapper-l-16 .phone-bg-img {
        display: block;
        transform: translate(-50%, -50%);
    }
}

.screenshot-slider-wrapper-l-16 .screenshot-slider .slick-list {
    margin: 0 -23.5px;
}

.screenshot-slider-wrapper-l-16 .screenshot-slider .slick-slide {
    opacity: .5;
    transition: 0.4s;
}

.screenshot-slider-wrapper-l-16 .screenshot-slider .slick-center {
    opacity: 1;
    border-radius: 40px;
    max-height: 720px !important;
    transform: scale(1.1);
    min-height: 690px;
}

.screenshot-slider-wrapper-l-16 .screenshot-slider .slick-center img {
    border-radius: 40px;
}

.screenshot-slider-wrapper-l-16 .l-16-slide-btn {
    position: absolute;
    right: 31%;
    top: 49%;
    height: 56px;
    width: 56px;
    line-height: 56px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fff;
    transition: .4s;
    z-index: 1;
}

@media (min-width: 576px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn {
        left: 12%;
    }
}

@media (min-width: 768px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn {
        left: 12%;
    }
}

@media (min-width: 992px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn {
        left: 23%;
    }
}

.screenshot-slider-wrapper-l-16 .l-16-slide-btn.active {
    background: #2780FF;
    color: #fff !important;
    border: 1px solid #2780FF;
}

.screenshot-slider-wrapper-l-16 .l-16-slide-btn:hover {
    background: #2780FF;
    color: #fff !important;
    border: 1px solid #2780FF;
}

.screenshot-slider-wrapper-l-16 .l-16-slide-btn.slick-next {
    left: 31%;
}

@media (min-width: 576px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn.slick-next {
        left: auto;
        right: 12%;
    }
}

@media (min-width: 768px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn.slick-next {
        left: auto;
        right: 12%;
    }
}

@media (min-width: 992px) {
    .screenshot-slider-wrapper-l-16 .l-16-slide-btn.slick-next {
        left: auto;
        right: 23%;
    }
}

.screenshots-dots-l-16 ul.slick-dots {
    list-style: none;
    padding-left: 0;
    margin-top: 25px;
}

.screenshots-dots-l-16 ul.slick-dots li {
    cursor: pointer;
    display: inline-block;
    width: 6px;
    height: 6px;
    font-size: 0;
    background: #fff;
    margin: 0 7px;
}

.screenshots-dots-l-16 ul.slick-dots li button {
    display: none;
}

.screenshots-dots-l-16 .slick-dots {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0 4.5px;
}

.screenshots-dots-l-16 .slick-dots li {
    border: none;
    list-style: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    color: transparent;
    opacity: 0.3;
}

.screenshots-dots-l-16 .slick-dots li:focus {
    outline: none;
    box-shadow: none;
}

.screenshots-dots-l-16 .slick-dots li.slick-active {
    background-color: #fff !important;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Testimonial Area-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-16 {
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .testimonial-area-l-16 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-16 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.testimonial-area-l-16 .section-heading-10 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .testimonial-area-l-16 .section-heading-10 {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-16 .section-heading-10 {
        margin-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-16 .testimonial-card {
        padding-right: 75px;
    }
}

@media (min-width: 1200px) {
    .testimonial-area-l-16 .testimonial-card {
        padding-right: 70px;
    }
}

@media (min-width: 1600px) {
    .testimonial-area-l-16 .testimonial-card {
        padding-right: 142px;
    }
}

.testimonial-area-l-16 .testimonial-card img {
    margin-bottom: 40px;
}

.testimonial-area-l-16 .testimonial-card p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #000000;
}

.testimonial-area-l-16 .testimonial-card .user-identity {
    margin-top: 15px;
}

.testimonial-area-l-16 .testimonial-card .user-identity h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.testimonial-area-l-16 .testimonial-card .user-identity span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
}

.testimonial-slider-l-16 .slick-arrow {
    display: none !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Newsletter Area-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .newsletter-area-l-16 {
    background: url(../image/l7/l7-newslater-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 60px;
} */

@media (min-width: 768px) {
    .newsletter-area-l-16 {
        padding-top: 80px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .newsletter-area-l-16 {
        padding-top: 110px;
        padding-bottom: 105px;
    }
}

.newsletter-area-l-16 .newsletter-area-shape {
    position: absolute;
    bottom: 0;
    left: 5%;
}

.newsletter-area-l-16 .content .icon-box i {
    height: 84px;
    width: 84px;
    line-height: 84px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    color: #2780FF;
    font-size: xxx-large;
    margin-bottom: 28px;
}

.newsletter-area-l-16 .content h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.345px;
    color: #000000;
    margin-bottom: 40px;
}

.newsletter-area-l-16 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #878B90;
    margin-bottom: 0;
    margin-top: 35px;
}

.newsletter-area-l-16 .newsletter-btns {
    margin: 0px -7px -15px -7px;
}

.newsletter-area-l-16 .newsletter-btns a {
    margin: 0px 7px 15px 7px;
}

.newsletter-area-l-16 .newsletter-btns i {
    margin-right: 15px;
    font-size: x-large;
}

.newsletter-area-l-16 .newsletter-btns .btn {
    background: #2780FF;
    border-radius: 5px;
    min-width: 238px;
    height: 60px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Footer Area-L-16
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-16 {
    padding-top: 55px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-area-l-16 {
        padding-top: 75px;
        padding-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-16 {
        padding-top: 95px;
        padding-bottom: 30px;
    }
}

.footer-area-l-16 .brand-logo {
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .footer-area-l-16 .brand-logo {
        margin-bottom: 0;
        margin-right: 35px;
    }
}

@media (min-width: 1600px) {
    .footer-area-l-16 .content-body {
        padding-right: 60px;
    }
}

.footer-area-l-16 .content-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
}

.footer-area-l-16 .footer-single-item {
    padding-top: 20px;
}

@media (min-width: 992px) {
    .footer-area-l-16 .footer-single-item {
        padding-top: 0;
    }
}

.footer-area-l-16 .footer-single-item h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
}

.footer-area-l-16 .footer-single-item p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
}

.footer-area-l-16 .copyright-area {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .footer-area-l-16 .copyright-area {
        margin-top: 60px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-16 .copyright-area {
        margin-top: 80px;
    }
}

.footer-area-l-16 .copyright-area p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.footer-area-l-16 .copyright-area .social-icons ul {
    margin: 0px -10px 0px -10px;
}

.footer-area-l-16 .copyright-area .social-icons ul li {
    margin: 0px 10px 0px 10px;
}

.footer-area-l-16 .copyright-area .social-icons ul li i {
    font-size: 22px;
    color: #000000;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-17
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-17-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-17-menu .site-menu-main .nav-link-item i {
    color: #fff;
}

.landing-17-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-17-menu .menu-block.active .nav-link-item i {
    color: #000;
}

.landing-17-menu.reveal-header .sign-in-btn {
    color: #fff !important;
}

.landing-17-menu.reveal-header .nav-link-item {
    color: #fff !important;
}

.landing-17-menu.reveal-header {
    background: #1D263A !important;
}

/* .hero-area-l-17 {
    background: url(../image/l8/hero-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding-top: 107px;
    padding-bottom: 60px;
    z-index: 5;
} */

.hero-area-l-17:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .8;
}

@media (min-width: 480px) {
    .hero-area-l-17 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-17 {
        padding-top: 150px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-17 {
        padding-top: 155px;
        padding-bottom: 0;
    }
}

.hero-area-l-17 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l-17 .content {
        padding-top: 0;
        padding-bottom: 90px;
    }
}

.hero-area-l-17 .content h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #fff;
}

@media (min-width: 576px) {
    .hero-area-l-17 .content h1 {
        font-size: 40px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-17 .content h1 {
        font-size: 46px;
        line-height: 60px;
    }
}

.hero-area-l-17 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #fff;
    margin: 5px 0px 30px 0px;
}

.hero-area-l-17 .content span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #DBE4F1;
    display: block;
    margin-top: 10px;
}

.hero-area-l-17 .content .btn {
    background: #50E3C2;
    border-radius: 3px;
    min-width: 227px;
    height: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;
}

.hero-area-l-17 .content .btn:hover {
    color: #0F3D33 !important;
}

.hero-area-l-17 .hero-area-image img {
    box-shadow: 0px 22px 74px rgba(20, 21, 24, 0.1845);
    border-radius: 5px;
}

@media (min-width: 992px) {
    .hero-area-l-17 .hero-area-image {
        margin-bottom: -180px;
    }
}

.header-btn-l-17 .sign-in-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #DBE4F1;
    min-width: initial;
    padding: 0;
    margin-right: 20px;
}

.header-btn-l-17 .start-trail-btn {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #0F3D33;
    min-width: 163px;
    height: 50px;
    background: #50E3C2;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               Feature Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l-17 {
    padding-top: 60px;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .feature-area-l-17 {
        padding-top: 80px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-17 {
        padding-top: 110px;
        padding-bottom: 105px;
        margin-top: 180px;
    }
}

.feature-area-l-17 .feature-area-l-17-items {
    margin-bottom: -30px;
}

.feature-area-l-17 .feature-area-l-17-items .col-lg-4 {
    margin-bottom: 30px;
}

.feature-area-l-17 .feature-area-l-17-items .single-features {
    padding: 0px 30px;
}

@media (min-width: 992px) {
    .feature-area-l-17 .feature-area-l-17-items .single-features {
        padding: 0px 20px;
    }
}

@media (min-width: 1200px) {
    .feature-area-l-17 .feature-area-l-17-items .single-features {
        padding: 0px 35px;
    }
}

.feature-area-l-17 .feature-area-l-17-items .single-features .circle-dot-1 i {
    font-size: 10px;
    color: #FC5C65;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: rgba(252, 92, 101, 0.06);
    margin-bottom: 35px;
}

.feature-area-l-17 .feature-area-l-17-items .single-features .circle-dot-2 i {
    font-size: 10px;
    color: #50E3C2;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: rgba(80, 163, 227, 0.06);
    margin-bottom: 35px;
}

.feature-area-l-17 .feature-area-l-17-items .single-features .circle-dot-3 i {
    font-size: 10px;
    color: #A665FB;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: rgba(166, 101, 251, 0.06);
    margin-bottom: 35px;
}

.feature-area-l-17 .feature-area-l-17-items .single-features h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 25px;
}

.feature-area-l-17 .feature-area-l-17-items .single-features p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    color: #323232;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .feature-area-l-17 .feature-area-l-17-items .single-features.single-border:after {
        position: absolute;
        content: "";
        right: -15px;
        top: 50%;
        height: 111px;
        transform: translateY(-50%);
        width: 1px;
        background: #E8E8EA;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Content Area 1-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-17-1 {
    background: #F8FBFE;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l-17-1 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-17-1 {
        padding-top: 130px;
        padding-bottom: 240px;
    }
}

.content-area-l-17-1 .content-img .image-2 {
    position: absolute;
    top: 30%;
    right: 8%;
    display: none;
}

@media (min-width: 480px) {
    .content-area-l-17-1 .content-img .image-2 {
        display: block;
    }
}

.content-area-l-17-1 .content-img img {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.103346);
    border-radius: 5px;
    width: 100%;
}

@media (min-width: 480px) {
    .content-area-l-17-1 .content-img img {
        width: initial;
    }
}

.content-area-l-17-1 .content {
    padding-top: 45px;
}

@media (min-width: 480px) {
    .content-area-l-17-1 .content {
        margin-top: 105px;
    }
}

@media (min-width: 992px) {
    .content-area-l-17-1 .content {
        margin-top: 0;
        padding-top: 0;
    }
}

.content-area-l-17-1 .content .btn {
    min-width: 227px;
    height: 50px;
    background: rgba(80, 227, 194, 0.14);
    mix-blend-mode: normal;
    border-radius: 3px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;
    margin-top: 40px;
}

.content-area-l-17-1 .content .btn:hover {
    color: #0F3D33 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Content Area 2-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-17-2 {
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l-17-2 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-17-2 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.content-area-l-17-2 .content-img .image-2 {
    position: absolute;
    top: 17%;
    right: -3%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) -23.88%, #FFFFFF 100%);
    display: none;
}

@media (min-width: 480px) {
    .content-area-l-17-2 .content-img .image-2 {
        display: block;
    }
}

.content-area-l-17-2 .content-img img {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.103346);
    border-radius: 5px;
    width: 100%;
}

@media (min-width: 480px) {
    .content-area-l-17-2 .content-img img {
        width: initial;
    }
}

.content-area-l-17-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-17-2 .content {
        padding-top: 0;
    }
}

.content-area-l-17-2 .content .btn {
    min-width: 227px;
    height: 50px;
    background: rgba(80, 227, 194, 0.14);
    mix-blend-mode: normal;
    border-radius: 3px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;
    margin-top: 40px;
}

.content-area-l-17-2 .content .btn:hover {
    color: #0F3D33 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Video Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .video-area-l-17 {
    position: relative;
    background: url(../image/l8/video-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    padding-bottom: 40px;
    z-index: 1;
} */

.video-area-l-17:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .6;
}

@media (min-width: 768px) {
    .video-area-l-17 {
        padding-top: 80px;
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .video-area-l-17 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.video-area-l-17 .video-content a i {
    background: #fff;
    width: 96px;
    height: 96px;
    line-height: 96px;
    border-radius: 50%;
    text-align: center;
    color: #50E3C2;
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .video-area-l-17 .video-content a i {
        margin-bottom: 55px;
    }
}

.video-area-l-17 .video-content h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 50px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.video-area-l-17 .video-content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Pricing Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l-17 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .pricing-area-l-17 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l-17 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.pricing-area-l-17 .content {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .pricing-area-l-17 .content {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .pricing-area-l-17 .content {
        margin-bottom: 60px;
    }
}

.pricing-area-l-17 .single-price {
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 42px 54px rgba(0, 0, 0, 0.0939481);
    border-radius: 6px;
}

.pricing-area-l-17 .toggle-btn {
    margin-bottom: 40px;
}

.pricing-area-l-17 .single-price {
    padding: 30px 45px 15px 45px;
}

.pricing-area-l-17 .single-price .price-top span {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.433333px;
    text-transform: uppercase;
    color: #878B90;
    display: inline-block;
    margin-bottom: 30px;
}

.pricing-area-l-17 .single-price .main-price span {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1.2px;
    color: #000000;
    display: inline-block;
    margin-right: 8px;
}

.pricing-area-l-17 .single-price .main-price h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 60px;
    letter-spacing: -0.3px;
    color: #000000;
    margin-top: -8px;
}

.pricing-area-l-17 .single-price .main-price h2 span {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #000000;
    display: inline-block;
    margin-left: 5px;
}

.pricing-area-l-17 .single-price .main-price p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.4px;
    color: #323232;
}

.pricing-area-l-17 .single-price .price-body {
    margin-top: 25px;
}

.pricing-area-l-17 .single-price .price-body ul li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.45px;
    color: #000000;
    margin-bottom: 8px;
}

.pricing-area-l-17 .single-price .price-body ul li i {
    margin-right: 10px;
}

.pricing-area-l-17 .single-price .price-body .del {
    color: #8E8E8E;
}

.pricing-area-l-17 .single-price .price-body del {
    color: #8E8E8E;
}

.pricing-area-l-17 .single-price .price-btn .btn {
    min-width: 227px;
    height: 50px;
    background: #50E3C2;
    border-radius: 3px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;
    margin-top: 22px;
}

.pricing-area-l-17 .single-price .price-btn p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-top: 10px;
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-yearly);
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
    background: #1787FC;
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
    left: calc(100% - 24px);
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
    background: #1787FC;
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
    left: calc(100% - 52%);
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
    color: #0F3D33 !important;
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
    color: #0F3D33 !important;
}

.pricing-area-l-17 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-monthly);
}

.pricing-area-l-17 .btn-toggle-2[data-pricing-trigger].active:before {
    color: #0F3D33 !important;
}

.pricing-area-l-17 .btn-toggle-2[data-pricing-trigger].active:after {
    color: #0F3D33 !important;
}

.pricing-area-l-17 .dynamic-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l-17 .static-value:before {
    display: inline-block;
    content: attr(data-active);
}

.pricing-area-l-17 .btn-toggle {
    width: 48px;
    height: 24px;
    border-radius: 17px;
    background-color: #f8f8f8 !important;
    position: relative;
    margin: 0 15px;
}

.pricing-area-l-17 .btn-toggle span {
    width: 16px;
    height: 16px;
    background-color: #0F3D33;
    position: absolute;
    left: 5px;
    top: 4px;
    transition: 0.4s;
    border-radius: 500px;
    pointer-events: none;
}

.pricing-area-l-17 .btn-toggle-2 {
    width: 220px;
    height: 42px;
    border-radius: 30px;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    position: relative;
    margin: 0 15px;
    z-index: 1;
}

.pricing-area-l-17 .btn-toggle-2:before {
    font-family: Inter;
    position: absolute;
    content: "Monthly";
    width: 46%;
    font-size: 12px;
    letter-spacing: -0.3px;
    font-weight: 600;
    text-transform: uppercase;
    color: #0F3D33;
    height: 34px;
    background: transparent;
    left: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l-17 .btn-toggle-2:after {
    font-family: Inter;
    position: absolute;
    content: "Yearly";
    width: 46%;
    font-size: 12px;
    letter-spacing: -0.3px;
    font-weight: 600;
    text-transform: uppercase;
    color: #0F3D33;
    height: 34px;
    background: transparent;
    right: 9px;
    top: 4px;
    line-height: 34px;
}

.pricing-area-l-17 .btn-toggle-2 .round {
    width: 50%;
    /* left: 0 !important; */
    height: 34px;
    top: 3px;
    left: 4px;
    background: #50E3C2;
    display: block;
    z-index: -1;
}

.pricing-area-l-17 .btn-toggle-2 span {
    display: none;
}

.pricing-area-l-17 .btn-toggle[data-value-active="yearly"] {
    background-color: #0F3D33 !important;
}

.pricing-area-l-17 .btn-toggle[data-value-active="yearly"] span {
    left: calc(100% - 25px) !important;
}

.pricing-area-l-17 .btn-toggle-square {
    height: 65px;
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 5px;
    align-items: center;
}

.pricing-area-l-17 .btn-toggle-square.bg-whisper-3:hover,
.pricing-area-l-17 .btn-toggle-square.bg-whisper-3:focus {
    background: #0F3D33 !important;
}

.pricing-area-l-17 .btn-toggle-square span {
    min-width: 160px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0F3D33;
}

.pricing-area-l-17 .btn-toggle-square span:first-child {
    border-radius: 10px 0 0 10px;
}

.pricing-area-l-17 .btn-toggle-square span:nth-child(2) {
    border-radius: 0px 10px 10px 0;
}

.pricing-area-l-17 .btn-toggle-square span.active {
    color: #fff;
    background: #0F3D33;
}

.pricing-area-l-17 .popular-pricing {
    margin-top: 25px;
}

@media (min-width: 768px) {
    .pricing-area-l-17 .popular-pricing {
        margin-top: 0;
    }
}

.pricing-area-l-17 .popular-pricing:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 4px;
    border-radius: 6px;
}

.pricing-area-l-17 .popular-pricing.popular-pricing-3:before {
    height: 3px;
    top: -3px;
    background: #50E3C2;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Brand Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-17 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .brand-area-l-17 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .brand-area-l-17 {
        padding-bottom: 130px;
    }
}

.brand-area-l-17 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #878B90;
    margin-bottom: 45px;
}

.brand-area-l-17-items {
    margin: 0px -20px -45px -20px;
}

.brand-area-l-17-items .single-brand {
    margin: 0px 20px 45px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Testimonial Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-17 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .testimonial-area-l-17 {
        padding-bottom: 80px;
    }
}

.testimonial-area-l-17 .testimonial-card {
    padding: 20px 30px 37px 30px;
}

@media (min-width: 768px) {
    .testimonial-area-l-17 .testimonial-card {
        padding: 20px 45px 37px 45px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-17 .testimonial-card {
        padding: 20px 30px 37px 30px;
    }
}

@media (min-width: 1200px) {
    .testimonial-area-l-17 .testimonial-card {
        padding: 20px 45px 37px 45px;
    }
}

.testimonial-area-l-17 .testimonial-card img {
    margin-right: 20px;
}

.testimonial-area-l-17 .testimonial-card p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: #000000;
    padding: 55px 30px 22px 0px;
    min-height: 240px;
}

.testimonial-area-l-17 .testimonial-card .user-identity h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #323232;
    margin-bottom: 0;
}

.testimonial-area-l-17 .testimonial-card .user-identity span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #323232;
    display: inline-block;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              CTA Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-17 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l-17 {
        padding-bottom: 80px;
    }
}

.cta-area-l-17 h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 50px;
    letter-spacing: -0.221053px;
    color: #000000;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .cta-area-l-17 h2 {
        margin-bottom: 0;
    }
}

.cta-area-l-17 .btn {
    background: #50E3C2;
    border-radius: 3px;
    min-width: 227px;
    height: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Footer Area-L-17
            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-17 {
    background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
    padding-top: 60px;
    padding-bottom: 35px;
}

@media (min-width: 768px) {
    .footer-area-l-17 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-17 {
        padding-top: 115px;
    }
}

.footer-area-l-17 .footer-area-l-17-items {
    margin-bottom: -30px;
}

.footer-area-l-17 .footer-area-l-17-items .col {
    margin-bottom: 30px;
}

.footer-area-l-17 .footer-shape {
    position: absolute;
    top: -12px;
    left: 0;
    width: 102%;
}

@media (min-width: 480px) {
    .footer-area-l-17 .footer-shape {
        top: -9px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-17 .footer-shape {
        top: -17px;
    }
}

@media (min-width: 1200px) {
    .footer-area-l-17 .footer-shape {
        top: -30px;
    }
}

.footer-area-l-17 .footer-shape img {
    width: 100%;
}

.footer-area-l-17 .footer-widget p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    color: #DBE4F1;
    mix-blend-mode: normal;
    opacity: 0.7;
    margin-bottom: 30px;
}

.footer-area-l-17 .footer-widget ul {
    margin-bottom: -12px;
}

.footer-area-l-17 .footer-widget ul li {
    margin-bottom: 12px;
}

.footer-area-l-17 .footer-widget ul li a {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 34px;
    color: #F8FBFE;
}

.copyright-area-l-17 {
    margin-top: 50px;
}

@media (min-width: 768px) {
    .copyright-area-l-17 {
        margin-top: 70px;
    }
}

@media (min-width: 992px) {
    .copyright-area-l-17 {
        margin-top: 100px;
    }
}

.copyright-area-l-17 .copyright p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #DBE4F1;
    margin-bottom: 0;
}

.copyright-area-l-17 .footer-menu {
    margin: 20px 0px 30px 0px;
}

@media (min-width: 768px) {
    .copyright-area-l-17 .footer-menu {
        margin: 0;
    }
}

.copyright-area-l-17 .footer-menu ul {
    margin: 0px -10px -15px -10px;
}

.copyright-area-l-17 .footer-menu ul li {
    margin: 0px 10px 15px 10px;
}

.copyright-area-l-17 .footer-menu ul li a {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #DBE4F1;
}

.copyright-area-l-17 .social-icons ul {
    margin: 0px -8px 0px -8px;
}

.copyright-area-l-17 .social-icons ul li {
    margin: 0px 8px 0px 8px;
}

.copyright-area-l-17 .social-icons ul li i {
    font-size: 20px;
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-18
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-18-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-18-menu .site-menu-main .nav-link-item i {
    color: #fff;
}

.landing-18-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-18-menu.reveal-header .sign-in-btn {
    color: #fff !important;
}

.landing-18-menu .menu-block.active .nav-link-item i {
    color: #000;
}

.landing-18-menu.reveal-header .nav-link-item {
    color: #fff !important;
}

.landing-18-menu.reveal-header {
    background: #1D263A !important;
}

.hero-area-l-18 {
    background: linear-gradient(180deg, #0a74ff 0%, #6451ec 100%);
    padding-top: 93px;
    padding-bottom: 0px;
}

@media (min-width: 480px) {
    .hero-area-l-18 {
        padding-top: 115px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-18 {
        padding-top: 135px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-18 {
        padding-top: 205px;
        padding-bottom: 0;
    }
}

@media (min-width: 992px) {
    .hero-area-l-18 .content {
        margin-top: -11px;
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-18 .content {
        padding-right: 30px;
    }
}

.hero-area-l-18 .content h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #fff;
}

@media (min-width: 576px) {
    .hero-area-l-18 .content h1 {
        font-size: 40px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-18 .content h1 {
        font-size: 46px;
        line-height: 58px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-18 .content h1 {
        font-size: 56px;
        line-height: 68px;
    }
}

.hero-area-l-18 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #DBE4F1;
    margin: 25px 0px 0px 0px;
}

.hero-area-l-18 .download-btns {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l-18 .download-btns {
        padding-top: 0;
    }
}

.hero-area-l-18 .download-btns .dw-wi-btn {
    margin-bottom: 20px;
    width: 310px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px 20px 12px 20px;
}

.hero-area-l-18 .download-btns .icon-box img {
    margin-right: 18px;
}

.hero-area-l-18 .download-btns .text h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222D3D;
    margin: -4px 0px 0px 0px;
}

.hero-area-l-18 .download-btns .text span {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #878B90;
}

.hero-area-l-18 .download-btns .dw-mac-btn {
    width: 310px;
    background: #4482FF;
    border-radius: 5px;
    padding: 20px 20px 12px 20px;
}

.hero-area-l-18 .download-btns .dw-mac-btn h6 {
    color: #fff;
}

.hero-area-l-18 .download-btns .dw-mac-btn span {
    color: #fff;
}

.hero-area-l-18 .hero-area-image img {
    margin-top: 60px;
}

@media (min-width: 768px) {
    .hero-area-l-18 .hero-area-image img {
        margin-top: 80px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-18 .hero-area-image img {
        margin-top: 120px;
    }
}

.header-btn-l-18 .trail-btn {
    background: #EFD358;
    border-radius: 3px;
    min-width: 145px;
    height: 45px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #222D3D;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Brand Area-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-18 {
    padding-top: 35px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .brand-area-l-18 {
        padding-bottom: 70px;
    }
}

.brand-area-l-18 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #878B90;
    margin-bottom: 45px;
}

.brand-area-l-18-items {
    margin: 0px -20px -45px -20px;
}

.brand-area-l-18-items .single-brand {
    margin: 0px 20px 45px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Feature Area-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l-18 {
    background: #F8FBFE;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-area-l-18 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-18 {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.feature-area-l-18 .section-heading-10 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .feature-area-l-18 .section-heading-10 {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-18 .section-heading-10 {
        margin-bottom: 80px;
    }
}

.feature-card-l-18-items {
    padding-top: 45px;
    margin-bottom: -20px;
}

@media (min-width: 992px) {
    .feature-card-l-18-items {
        padding-top: 0;
    }
}

.feature-card-l-18 {
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 30px 30px 30px;
}

.feature-card-l-18 .icon-box {
    margin-bottom: 30px;
    margin-right: 25px;
    height: 58px;
    width: 58px;
    line-height: 58px;
    text-align: center;
    border-radius: 50%;
    background: #EEF5FF;
    display: inline-table;
}

@media (min-width: 480px) {
    .feature-card-l-18 .icon-box {
        margin-bottom: 0;
    }
}

.feature-card-l-18 .content-body h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222D3D;
}

.feature-card-l-18 .content-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #878B90;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .feature-l-18-main-image {
        position: absolute;
        top: 0;
        width: 99%;
    }
}

@media (min-width: 1200px) {
    .feature-l-18-main-image {
        width: 82%;
    }
}

@media (min-width: 1600px) {
    .feature-l-18-main-image {
        width: 72%;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Content Area-1-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-18-1 {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .content-area-l-18-1 {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .content-area-l-18-1 {
        padding-top: 115px;
    }
}

.content-area-l-18-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-18-1 .content {
        padding-top: 0px;
    }
}

.content-area-l-18-1 .content h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: #FA8231;
    margin-bottom: 22px;
}

.content-area-l-18-1 .content .btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2780FF;
    border-bottom: 2px solid #2780ff;
    padding: 0;
    min-width: initial;
    border-radius: 0;
    margin-top: 28px;
}

.content-area-l-18-1 .content .btn:hover {
    color: #2780FF !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Content Area-1-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-18-2 {
    padding-top: 45px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-area-l-18-2 {
        padding-top: 65px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-18-2 {
        padding-top: 115px;
        padding-bottom: 130px;
    }
}

.content-area-l-18-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-18-2 .content {
        padding-top: 0px;
    }
}

.content-area-l-18-2 .content h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: #878B90;
    margin-bottom: 22px;
}

.content-area-l-18-2 .content .btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2780FF;
    border-bottom: 2px solid #2780ff;
    padding: 0;
    min-width: initial;
    border-radius: 0;
    margin-top: 28px;
}

.content-area-l-18-2 .content .btn:hover {
    color: #2780FF !important;
}

.content-area-l-18-2 .content-img img {
    width: 100%;
}

@media (min-width: 480px) {
    .content-area-l-18-2 .content-img img {
        width: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Testimonial Area-1-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .testimonial-area-l-18 {
    background: url(../image/l9/testimonial-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    padding-bottom: 45px;
} */

@media (min-width: 768px) {
    .testimonial-area-l-18 {
        padding-top: 80px;
        padding-bottom: 65px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-18 {
        padding-top: 130px;
        padding-bottom: 113px;
    }
}

.testimonial-area-l-18 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .testimonial-area-l-18 .content {
        padding-top: 0;
    }
}

.testimonial-area-l-18 .content h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
}

@media (min-width: 576px) {
    .testimonial-area-l-18 .content h2 {
        font-size: 38px;
        line-height: 50px;
    }
}

.testimonial-area-l-18 .content .btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #EFD358;
    border-bottom: 2px solid #EFD358;
    padding: 0;
    min-width: initial;
    border-radius: 0;
    margin-top: 28px;
}

.testimonial-area-l-18 .content .btn:hover {
    color: #EFD358 !important;
}

.testimonial-logo-l-18 {
    margin-top: 40px;
    margin-bottom: -35px;
}

@media (min-width: 768px) {
    .testimonial-logo-l-18 {
        margin-top: 70px;
    }
}

@media (min-width: 992px) {
    .testimonial-logo-l-18 {
        margin-top: 105px;
    }
}

.testimonial-logo-l-18 .col-sm-6 {
    margin-bottom: 35px;
}

.testimonial-card-slider-l-18 img {
    border-radius: 10px 10px 0px 0px;
}

.testimonial-card-slider-l-18 .single-item {
    background: #FFFFFF;
    border-radius: 10px;
}

.testimonial-card-slider-l-18 .single-item .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.testimonial-card-slider-l-18 .single-item .video-icon i {
    color: #fff;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    border-radius: 50%;
    background: rgba(39, 102, 255, 0.9);
}

.testimonial-card-slider-l-18 .single-item .content-body {
    padding: 45px 30px 40px 30px;
}

@media (min-width: 768px) {
    .testimonial-card-slider-l-18 .single-item .content-body {
        padding: 45px 50px 40px 50px;
    }
}

.testimonial-card-slider-l-18 .single-item .content-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: #000000;
}

.testimonial-card-slider-l-18 .single-item .content-body .user-identity {
    margin-top: 15px;
}

.testimonial-card-slider-l-18 .single-item .content-body .user-identity h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #323232;
}

.testimonial-card-slider-l-18 .single-item .content-body .user-identity span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #323232;
}

.testimonial-card-slider-l-18 .single-item .content-body .customer-img {
    margin-top: 15px;
}

@media (min-width: 480px) {
    .testimonial-card-slider-l-18 .single-item .content-body .customer-img {
        margin-top: 0;
    }
}

.testimonial-card-slider-l-18 ul.slick-dots li button {
    display: none;
}

.testimonial-card-slider-l-18 ul.slick-dots {
    list-style: none;
    padding-left: 0;
    margin-top: 70px;
}

.testimonial-card-slider-l-18 ul.slick-dots {
    margin-top: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.testimonial-card-slider-l-18 ul.slick-dots li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: .3;
    margin: 0 4px;
    background: #fff;
    cursor: pointer;
}

.testimonial-card-slider-l-18 ul.slick-dots li.slick-active {
    width: 10px !important;
    height: 10px !important;
    background: #fff !important;
    opacity: 1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Newsletter Area-L-18
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-18 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l-18 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-18 {
        padding-top: 90px;
        padding-bottom: 100px;
    }
}

.cta-area-l-18 .download-btns {
    margin-bottom: -20px;
    margin-top: 45px;
}

.cta-area-l-18 .download-btns .dw-wi-btn {
    margin-bottom: 20px;
    width: 310px;
    border: 1px solid #DBE4F1;
    border-radius: 5px;
    padding: 20px 20px 12px 20px;
}

.cta-area-l-18 .download-btns .icon-box img {
    margin-right: 18px;
}

.cta-area-l-18 .download-btns .text h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222D3D;
    margin: -4px 0px 0px 0px;
}

.cta-area-l-18 .download-btns .text span {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #878B90;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Footer Area-L-18
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-18 {
    padding-top: 60px;
    padding-bottom: 30px;
    background: #F8FBFE;
}

@media (min-width: 768px) {
    .footer-area-l-18 {
        padding-top: 80px;
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-18 {
        padding-top: 110px;
        padding-bottom: 90px;
    }
}

.footer-area-l-18 .footer-logo {
    margin-bottom: 40px;
}

.footer-area-l-18 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #666666;
}

.footer-area-l-18 .social-icons {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .footer-area-l-18 .social-icons {
        margin-top: 50px;
    }
}

@media (min-width: 992px) {
    .footer-area-l-18 .social-icons {
        margin-top: 60px;
    }
}

.footer-area-l-18 .social-icons ul {
    margin: 0px -8px 0px -8px;
}

.footer-area-l-18 .social-icons ul li {
    margin: 0px 8px 0px 8px;
}

.footer-area-l-18 .social-icons ul li i {
    font-size: 20px;
    color: #000;
}

.footer-l-18-items {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .footer-l-18-items {
        padding-top: 0;
    }
}

.footer-l-18-items .footer-widget p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #666666;
    margin-bottom: 25px;
}

.footer-l-18-items .footer-widget ul li a {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: -0.3px;
    color: #000000;
}

@media (min-width: 576px) {
    .footer-l-18-items .footer-widget.widget3 {
        margin-top: 52px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-19
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-19-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-19-menu .site-menu-main .nav-link-item i {
    color: #fff;
}

.landing-19-menu .menu-block.active .nav-link-item i {
    color: #000;
}

.landing-19-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-19-menu.reveal-header .sign-in-btn {
    color: #fff !important;
}

.landing-19-menu.reveal-header .nav-link-item {
    color: #fff !important;
}

.landing-19-menu.reveal-header {
    background: #1D263A !important;
}

.hero-area-l-19 {
    background: linear-gradient(-48deg, #8731e8 0%, #4528dc 100%);
    padding-top: 105px;
    padding-bottom: 55px;
}

@media (min-width: 480px) {
    .hero-area-l-19 {
        padding-top: 125px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-19 {
        padding-top: 145px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-19 {
        padding-top: 205px;
        padding-bottom: 140px;
    }
}

.hero-area-l-19 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l-19 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-19 .content {
        padding-right: 30px;
    }
}

.hero-area-l-19 .content h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #fff;
}

@media (min-width: 576px) {
    .hero-area-l-19 .content h1 {
        font-size: 40px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-19 .content h1 {
        font-size: 46px;
        line-height: 58px;
    }
}

.hero-area-l-19 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #DBE4F1;
    margin: 25px 0px 30px 0px;
}

.get-chapter-form-l-19 .form-control {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #878B90;
    background: #FFFFFF;
    border-radius: 3px;
    width: 100%;
    height: 60px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19 .form-control {
        width: 455px;
    }
}

.get-chapter-form-l-19 .form-control::-moz-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19 .form-control::placeholder {
    color: #878B90;
}

.get-chapter-form-l-19 .btn {
    background: #EFD358;
    border-radius: 3px;
    width: 100%;
    height: 60px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #222D3D;
    margin-top: 12px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19 .btn {
        width: 455px;
        height: 60px;
    }
}

.get-chapter-form-l-19 .btn:hover {
    color: #222D3D !important;
}

.get-chapter-form-l-19 span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.28px;
    color: #FFFFFF;
    display: inline-block;
    margin-top: 15px;
}

.header-btn-l-19 .read-btn {
    background: #EFD358;
    border-radius: 3px;
    min-width: 145px;
    height: 45px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #222D3D;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Services Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.services-area-l-19 {
    padding-top: 55px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .services-area-l-19 {
        padding-top: 70px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .services-area-l-19 {
        padding-top: 125px;
        padding-bottom: 125px;
    }
}

.services-area-l-19 .section-heading-12 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .services-area-l-19 .section-heading-12 {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .services-area-l-19 .section-heading-12 {
        margin-bottom: 98px;
    }
}

.services-area-l-19-items {
    margin-bottom: -40px;
}

@media (min-width: 768px) {
    .services-area-l-19-items {
        margin-bottom: -50px;
    }
}

@media (min-width: 992px) {
    .services-area-l-19-items {
        margin-bottom: -73px;
    }
}

.services-area-l-19-items .col-lg-4 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .services-area-l-19-items .col-lg-4 {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .services-area-l-19-items .col-lg-4 {
        margin-bottom: 73px;
    }
}

@media (min-width: 1200px) {
    .services-area-l-19-items .single-services {
        padding-right: 10px;
    }
}

@media (min-width: 1600px) {
    .services-area-l-19-items .single-services {
        padding-right: 70px;
    }
}

.services-area-l-19-items .single-services .icon-box {
    margin-right: 25px;
}

.services-area-l-19-items .single-services .icon-box i {
    background: #EFD358;
    color: #fff;
    font-size: 26px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    border-radius: 50%;
}

.services-area-l-19-items .single-services .icon-box-2 i {
    background: #81E2CC;
}

.services-area-l-19-items .single-services .icon-box-3 i {
    background: #2780FF;
}

.services-area-l-19-items .single-services .icon-box-4 i {
    background: #B8E986;
}

.services-area-l-19-items .single-services .icon-box-5 i {
    background: #F69F51;
}

.services-area-l-19-items .single-services .icon-box-6 i {
    background: #9E84FF;
}

.services-area-l-19-items .single-services .content-body h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222D3D;
}

.services-area-l-19-items .single-services .content-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #878B90;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Brand Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-19 {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .brand-area-l-19 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .brand-area-l-19 {
        padding-bottom: 100px;
    }
}

.brand-area-l-19 .content p {
    margin-bottom: 35px;
}

.brand-area-l-19-items {
    margin: 0px -20px -45px -20px;
}

.brand-area-l-19-items .single-brand {
    margin: 0px 20px 45px 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Content Area-1-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-19-1 {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #F6F9FC;
}

@media (min-width: 768px) {
    .content-area-l-19-1 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-19-1 {
        padding-top: 130px;
        padding-bottom: 70px;
    }
}

.content-area-l-19-1 .content {
    padding-top: 0px;
}

@media (min-width: 992px) {
    .content-area-l-19-1 .content {
        padding-top: 0;
    }
}

.get-chapter-form-l-19-2 {
    display: inline-grid;
    margin-top: 35px;
}

.get-chapter-form-l-19-2 .form-control {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #878B90;
    background: #FFFFFF;
    border: 0;
    border-radius: 3px;
    width: 100%;
    height: 60px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19-2 .form-control {
        width: 455px;
    }
}

.get-chapter-form-l-19-2 .form-control::-moz-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-2 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-2 .form-control::placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-2 .btn {
    background: #EFD358;
    border-radius: 3px;
    width: 100%;
    height: 60px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #222D3D;
    margin-top: 12px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19-2 .btn {
        width: 455px;
        height: 60px;
    }
}

.get-chapter-form-l-19-2 .btn:hover {
    color: #222D3D !important;
}

.get-chapter-form-l-19-2 span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.28px;
    color: #878B90;
    display: inline-block;
    margin-top: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Tab Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.tab-area-l-19 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .tab-area-l-19 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .tab-area-l-19 {
        padding-top: 125px;
        padding-bottom: 110px;
    }
}

.tab-area-l-19 .content {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .tab-area-l-19 .content {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .tab-area-l-19 .content {
        margin-bottom: 75px;
    }
}

.tab-area-l-19 .chapter-tab-area-l-19 {
    margin-bottom: -20px;
}

.tab-area-l-19 .chapter-tab-area-l-19 .nav-link {
    margin-bottom: 20px;
}

.tab-area-l-19 .chapter-tab-area-l-19 .nav-link p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #878B90;
    margin-bottom: 12px;
}

.tab-area-l-19 .chapter-tab-area-l-19 .nav-link h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #878B90;
}

.tab-area-l-19 .chapter-tab-area-l-19 .nav-link.active p {
    color: #5029DE;
}

.tab-area-l-19 .chapter-tab-area-l-19 .nav-link.active h4 {
    color: #222D3D;
}

.tab-area-l-19 .tab-content {
    margin-top: 45px;
}

@media (min-width: 768px) {
    .tab-area-l-19 .tab-content {
        margin-top: 0;
    }
}

.tab-area-l-19 .tab-content .content-card {
    background: #FFFFFF;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0710485);
    border-radius: 8px;
    padding: 40px 40px 50px 40px;
}

.tab-area-l-19 .tab-content .active-border {
    border-top: 5px solid #5029DE;
}

.tab-area-l-19 .tab-content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #323232;
    margin-bottom: 25px;
}

.tab-area-l-19 .tab-content ul {
    margin-bottom: -32px;
    margin-top: 40px;
}

@media (min-width: 768px) {
    .tab-area-l-19 .tab-content ul {
        margin-top: 50px;
    }
}

@media (min-width: 992px) {
    .tab-area-l-19 .tab-content ul {
        margin-top: 65px;
    }
}

.tab-area-l-19 .tab-content ul li {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #222D3D;
    margin-bottom: 32px;
}

.tab-area-l-19 .tab-content ul li i {
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #5029DE;
    font-size: 12px;
    margin-right: 12px;
    display: inline-table;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Testimonial Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l-19 {
    background: #F6F9FC;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .testimonial-area-l-19 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-19 {
        padding-top: 125px;
        padding-bottom: 125px;
    }
}

.testimonial-area-l-19 .content {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .testimonial-area-l-19 .content {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-19 .content {
        margin-bottom: 75px;
    }
}

.testimonial-area-l-19-items {
    margin-bottom: -30px;
}

.testimonial-area-l-19-items .col-lg-4 {
    margin-bottom: 30px;
}

.testimonial-area-l-19-items .col-lg-12 {
    margin-bottom: 30px;
}

.testimonial-area-l-19-items .testimonial-card {
    padding: 40px 40px 35px 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.036062);
    border-radius: 5px;
}

@media (min-width: 992px) {
    .testimonial-area-l-19-items .testimonial-card {
        padding: 40px 30px 35px 30px;
    }
}

@media (min-width: 1200px) {
    .testimonial-area-l-19-items .testimonial-card {
        padding: 40px 40px 35px 40px;
    }
}

.testimonial-area-l-19-items .testimonial-card .rating i {
    color: #FA8231;
}

.testimonial-area-l-19-items .testimonial-card p {
    font-family: Inter;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.1px;
    color: #1F1F1F;
    margin-top: 30px;
    margin-bottom: 35px;
}

.testimonial-area-l-19-items .testimonial-card .customer-img img {
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .testimonial-area-l-19-items .testimonial-card .customer-img img {
        margin-bottom: 0;
        margin-right: 25px;
    }
}

.testimonial-area-l-19-items .testimonial-card .user-identity h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.3375px;
    color: #1F1F1F;
    margin-bottom: 0;
}

.testimonial-area-l-19-items .testimonial-card .user-identity span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #666666;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Author Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.author-area-l-19 {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .author-area-l-19 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .author-area-l-19 {
        padding-top: 125px;
        padding-bottom: 80px;
    }
}

.author-area-l-19 .content {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .author-area-l-19 .content {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .author-area-l-19 .content {
        margin-bottom: 75px;
    }
}

.author-area-l-19 .about-content {
    margin-bottom: -30px;
}

.author-area-l-19 .about-content img {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .author-area-l-19 .about-content img {
        margin-bottom: 0;
        margin-right: 55px;
    }
}

@media (min-width: 992px) {
    .author-area-l-19 .about-content img {
        margin-right: 75px;
    }
}

.author-area-l-19 .about-content h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #222D3D;
    margin-bottom: 30px;
}

.author-area-l-19 .about-content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #323232;
    margin-bottom: 35px;
}

.author-area-l-19 .about-content a {
    text-decoration: underline;
    color: #323232;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          CTA Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-19 {
    background: linear-gradient(312.24deg, #8731E8 9.69%, #4528DC 98.05%);
    padding-top: 85px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .cta-area-l-19 {
        padding-top: 115px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-19 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.cta-area-l-19 .content {
    padding-top: 45px;
}

@media (min-width: 768px) {
    .cta-area-l-19 .content {
        padding-right: 45px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-19 .content {
        padding-top: 0;
    }
}

.cta-area-l-19 .content h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: -0.3px;
    color: #fff;
}

@media (min-width: 576px) {
    .cta-area-l-19 .content h2 {
        font-size: 40px;
        line-height: 52px;
    }
}

@media (min-width: 768px) {
    .cta-area-l-19 .content h2 {
        font-size: 46px;
        line-height: 58px;
    }
}

.cta-area-l-19 .content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: #DBE4F1;
    margin-bottom: 40px;
}

.cta-area-l-19 .cta-shape {
    position: absolute;
    top: -13px;
    left: -15%;
    width: 125%;
}

.get-chapter-form-l-19-3 .form-control {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #878B90;
    background: #FFFFFF;
    border-radius: 3px;
    width: 100%;
    height: 60px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19-3 .form-control {
        width: 455px;
    }
}

.get-chapter-form-l-19-3 .form-control::-moz-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-3 .form-control:-ms-input-placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-3 .form-control::placeholder {
    color: #878B90;
}

.get-chapter-form-l-19-3 .btn {
    background: #EFD358;
    border-radius: 3px;
    width: 100%;
    height: 60px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #222D3D;
    margin-top: 12px;
}

@media (min-width: 480px) {
    .get-chapter-form-l-19-3 .btn {
        width: 455px;
        height: 60px;
    }
}

.get-chapter-form-l-19-3 .btn:hover {
    color: #222D3D !important;
}

.get-chapter-form-l-19-3 span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.28px;
    color: #FFFFFF;
    display: inline-block;
    margin-top: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Footer Area-L-19
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-19 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-area-l-19 .footer-copyright p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
    margin-bottom: 0;
}

.footer-menu-item-l9 {
    padding: 25px 0px;
    margin: 0px -20px -25px -20px;
}

@media (min-width: 992px) {
    .footer-menu-item-l9 {
        padding: 0;
    }
}

.footer-menu-item-l9 li {
    margin: 0px 20px 25px 20px;
    display: inline-flex;
    flex-wrap: wrap;
}

.footer-menu-item-l9 li a {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
}

.footer-social-icon-l9 {
    margin: 0px -15px 0px -15px;
}

.footer-social-icon-l9 li {
    display: inline-block;
    margin: 0px 15px 0px 15px;
}

.footer-social-icon-l9 li a i {
    color: #000;
    font-size: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Hero Area-L-19
  
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-20-menu .site-menu-main .nav-link-item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-20-menu .site-menu-main .nav-link-item i {
    color: #1f1f1f;
}

.landing-20-menu .menu-block.active .nav-link-item i {
    color: #000;
}

.landing-20-menu .site-menu-main .sub-menu--item {
    font-family: 'Baloo Chettan 2', cursive;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
}

.landing-20-menu.reveal-header .download-btn {
    color: #1f1f1f !important;
}

.landing-20-menu.reveal-header .nav-link-item {
    color: #1f1f1f !important;
}

.landing-20-menu.reveal-header {
    background: #fff !important;
}

.hero-area-l-20 {
    background: #f4fcff;
    padding-top: 105px;
    padding-bottom: 55px;
}

@media (min-width: 480px) {
    .hero-area-l-20 {
        padding-top: 125px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-20 {
        padding-top: 145px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .hero-area-l-20 {
        padding-top: 205px;
        padding-bottom: 185px;
    }
}

.hero-area-l-20 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .hero-area-l-20 .content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .hero-area-l-20 .content {
        padding-right: 10px;
    }
}

@media (min-width: 1600px) {
    .hero-area-l-20 .content {
        padding-right: 60px;
    }
}

.hero-area-l-20 .content h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 44px;
    color: #2F4B6E;
}

@media (min-width: 576px) {
    .hero-area-l-20 .content h1 {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (min-width: 768px) {
    .hero-area-l-20 .content h1 {
        font-size: 46px;
        line-height: 56px;
    }
}

.hero-area-l-20 .content p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #2F4B6E;
    margin: 20px 0px 40px 0px;
}

.hero-area-l-20 .content .btn-area .btn {
    justify-content: left;
    background: #F8E587;
    border-radius: 30.5px;
    min-width: 236px;
    height: 57px;
    font-family: Nunito;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.888889px;
    text-transform: uppercase;
    color: #2F4B6E;
}

.hero-area-l-20 .content .btn-area .btn i {
    margin-right: 35px;
    font-size: xx-large;
}

.hero-area-l-20 .content .btn-area .btn:hover {
    color: #2F4B6E !important;
}

.hero-area-l-20 .content .btn-area span {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #738091;
    display: inherit;
    margin-top: 15px;
}

.header-btn-l-20 .get-btn {
    background: #EFD358;
    border-radius: 30px;
    text-transform: uppercase;
    min-width: 145px;
    height: 45px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    letter-spacing: 0.777778px;
    line-height: 19px;
    text-align: center;
    color: #2F4B6E;
    margin-left: 15px;
}

.header-btn-l-20 .get-btn:hover {
    color: #2F4B6E !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
                  CTA Area-L-20
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-20 {
    background: #f4fcff;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .cta-area-l-20 {
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-20 {
        padding-bottom: 125px;
    }
}

.cta-area-l-20 .cta-border-l-20 {
    border-top: 1px solid #E1E8F2;
    margin: 60px 0px 0px 0px;
}

.cta-area-l-20 .content .icon-box img {
    margin-right: 35px;
}

.cta-area-l-20 .content .content-body p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    color: #2F4B6E;
}

.cta-area-l-20 .cta-l-20-1-btn {
    margin-top: 30px;
}

@media (min-width: 992px) {
    .cta-area-l-20 .cta-l-20-1-btn {
        margin-top: 0;
    }
}

.cta-area-l-20 .cta-l-20-1-btn .btn {
    min-width: 278px;
    height: 57px;
    background: #F4FCFF;
    border: 2px solid #D0DCEB;
    box-sizing: border-box;
    border-radius: 30.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.888889px;
    text-transform: uppercase;
    color: #2F4B6E;
}

.cta-area-l-20 .cta-l-20-1-btn .btn:hover {
    color: #2F4B6E !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Feature Area-L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l-19 {
    background: linear-gradient(180deg, #f4fcff 0%, #e8f5fa 100%);
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .feature-area-l-19 {
        border-radius: 0 0 293px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-19 {
        padding-bottom: 120px;
    }
}

.feature-area-l-19 .section-heading-13 {
    margin-bottom: 70px;
}

.feature-area-l-19-items {
    margin-bottom: -30px;
}

.feature-area-l-19-items .col-xl-3 {
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .feature-area-l-19-items .single-features {
        padding: 0px 30px;
    }
}

@media (min-width: 992px) {
    .feature-area-l-19-items .single-features {
        padding: 0px 15px;
    }
}

@media (min-width: 1200px) {
    .feature-area-l-19-items .single-features {
        padding: 0px 30px;
    }
}

@media (min-width: 1600px) {
    .feature-area-l-19-items .single-features {
        padding: 0px 55px;
    }
}

.feature-area-l-19-items .single-features h5 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 30px;
    color: #2F4B6E;
    margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
             Content Area 1-L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-20-1 {
    background: #F4FCFF;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .content-area-l-20-1 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .content-area-l-20-1 {
        padding-top: 130px;
        margin-bottom: -45px;
    }
}

.content-area-l-20-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-20-1 .content {
        padding-top: 0;
    }
}

.image-bottom-shape-l-20 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-3deg);
    background: #f4fcff;
    width: 100%;
    height: 185px;
}

.main-image img {
    width: 100%;
}

@media (min-width: 480px) {
    .main-image img {
        width: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               Content Area 2-L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-20-2 {
    background: #F4FCFF;
}

@media (min-width: 992px) {
    .content-area-l-20-2 {
        margin-bottom: -145px;
    }
}

.content-area-l-20-2 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-20-2 .content {
        padding-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               Content Area 2-L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l-20-3 {
    background: #F4FCFF;
    padding-top: 60px;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .content-area-l-20-3 {
        padding-top: 80px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .content-area-l-20-3 {
        padding-top: 100px;
        padding-bottom: 90px;
        margin-bottom: -135px;
    }
}

.content-area-l-20-3 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .content-area-l-20-3 .content {
        padding-top: 0;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               Testimonial Area L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .testimonial-area-l-20 {
    background: url(../image/l11/testimonial-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 70px;
    padding-bottom: 70px;
} */

@media (min-width: 768px) {
    .testimonial-area-l-20 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (min-width: 992px) {
    .testimonial-area-l-20 {
        padding-top: 160px;
        padding-bottom: 170px;
    }
}

.testimonial-area-l-20 .btn-area .btn {
    justify-content: left;
    min-width: 285px;
    height: 57px;
    background: #F8E587;
    border: 2px solid #2F4B6E;
    border-radius: 30.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.888889px;
    text-transform: uppercase;
    color: #2F4B6E;
    margin-top: 40px;
}

.testimonial-area-l-20 .btn-area .btn i {
    margin-right: 35px;
    font-size: x-large;
}

.testimonial-area-l-20 .btn-area .btn:hover {
    color: #2F4B6E !important;
}

.testimonial-area-l-20 .image-group .image-1 {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1;
}

@media (min-width: 1200px) {
    .testimonial-area-l-20 .image-group .image-1 img {
        width: 85%;
    }
}

@media (min-width: 1600px) {
    .testimonial-area-l-20 .image-group .image-1 img {
        width: initial;
    }
}

.testimonial-area-l-20 .image-group .image-2 {
    position: absolute;
    bottom: -20%;
    left: 0;
    z-index: 1;
}

.testimonial-area-l-20 .image-group .image-3 {
    position: absolute;
    top: -40px;
    right: 0;
    z-index: 1;
}

@media (min-width: 1200px) {
    .testimonial-area-l-20 .image-group .image-3 img {
        width: 85%;
    }
}

@media (min-width: 1600px) {
    .testimonial-area-l-20 .image-group .image-3 img {
        width: initial;
    }
}

.testimonial-area-l-20 .image-group .image-4 {
    position: absolute;
    top: 0;
    right: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               CTA Area-2 L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-20-2 {
    background: #F4FCFF;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .cta-area-l-20-2 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .cta-area-l-20-2 {
        padding-top: 130px;
    }
}

.cta-area-l-20-2 .btn-area .btn {
    justify-content: left;
    min-width: 285px;
    height: 57px;
    background: #F8E587;
    border-radius: 30.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.888889px;
    text-transform: uppercase;
    color: #2F4B6E;
    margin-top: 40px;
}

.cta-area-l-20-2 .btn-area .btn i {
    margin-right: 35px;
    font-size: x-large;
}

.cta-area-l-20-2 .btn-area .btn:hover {
    color: #2F4B6E !important;
}

.cta-area-l-20-2 img {
    margin-top: 80px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
               Footer Area L-20
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l-20 {
    background: #E6F8FF;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .footer-area-l-20 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.footer-area-l-20 .social-icons ul {
    margin: 0px -3px;
}

.footer-area-l-20 .social-icons ul li {
    margin: 0px 3px;
}

.footer-area-l-20 .social-icons ul li a i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: #E6F8FF;
    border: 2px solid #D0DCEB;
    color: #738091;
    font-size: 18px;
}

.footer-area-l-20 p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #2F4B6E;
    margin-bottom: 0;
    margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Sign In Page 01
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.sign-in-menu-1 {
    background: #fff;
}

.sign-in-menu-1 .site-menu-main .nav-link-item {
    color: #213053;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
}

.sign-in-menu-1 .site-menu-main .nav-link-item i {
    color: #213053;
}

.sign-in-menu-1 .site-menu-main .sub-menu--item {
    color: #213053;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
}

.sign-in-menu-1.reveal-header .log-in-btn {
    color: #666666 !important;
}

.sign-in-header-btn-1 .log-in-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: normal;
    color: #666666;
    min-width: 87px;
    height: 40px;
    border: 1px solid #1F1F1F;
    border-radius: 3px;
}

.sign-in-header-btn-1 .download-trail-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    letter-spacing: normal;
    color: #FFFFFF;
    background: #1787FC;
    border-radius: 30px;
    min-width: 131px;
    height: 40px;
    margin-left: 15px;
    padding-top: 18px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign In 01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .sign-in-1 {
    background: url(../image/sign-in/sign-in-bg-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .sign-in-1 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-in-1 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-in-1 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-in-1-box .heading {
    margin-bottom: 40px;
}

.sign-in-1-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #fff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-in-1-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-in-1-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.sign-in-1-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.sign-in-1-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.sign-in-1-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.sign-in-1-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-1-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-1-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-1-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-in-1-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 20px 0px 35px 0px;
}

.sign-in-1-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.sign-in-1-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign Up 01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .sign-up-1 {
    background: url(../image/sign-in/sign-in-bg-2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .sign-up-1 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-up-1 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-up-1 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-up-1-box .heading {
    margin-bottom: 40px;
}

.sign-up-1-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-up-1-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-up-1-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.sign-up-1-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.sign-up-1-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.sign-up-1-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.sign-up-1-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-1-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-1-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-1-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-up-1-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 20px 0px 35px 0px;
}

.sign-up-1-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.sign-up-1-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Reset Password 01
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .reset-password-1 {
    background: url(../image/sign-in/sign-in-bg-3.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .reset-password-1 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .reset-password-1 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .reset-password-1 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.reset-password-1-box .heading {
    margin-bottom: 40px;
}

.reset-password-1-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .reset-password-1-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.reset-password-1-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.reset-password-1-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.reset-password-1-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.reset-password-1-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.reset-password-1-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-password-1-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-password-1-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-password-1-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 10px 0px 35px 0px;
}

.reset-password-1-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.reset-password-1-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign up 02
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .sign-up-2 {
    background: url(../image/sign-in/sign-up-2-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .sign-up-2 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-up-2 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-up-2 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-up-2-box .heading {
    margin-bottom: 40px;
}

.sign-up-2-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-up-2-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-up-2-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.sign-up-2-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.sign-up-2-box form .form-control {
    background: transparent;
    border-radius: 0px;
    border-bottom: 2px solid #EAECED;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 0px;
    margin-bottom: 18px;
}

.sign-up-2-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-2-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-2-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-2-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-up-2-box form .keep-sign-area .check-input-control input:checked~.checkbox {
    background: #1D263A !important;
    border-color: #1D263A;
}

.sign-up-2-box form .sign-in-log-btn .btn {
    background: #FF794D;
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
    height: 45px;
    margin: 20px 0px 35px 0px;
}

.sign-up-2-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.sign-up-2-box form .create-new-acc-text p a {
    color: #FF794D;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign In 02
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .sign-in-2 {
    background: url(../image/sign-in/sign-in-2-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .sign-in-2 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-in-2 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-in-2 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-in-2-box .heading {
    margin-bottom: 40px;
}

.sign-in-2-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-in-2-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-in-2-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.sign-in-2-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.sign-in-2-box form .form-control {
    background: transparent;
    border-radius: 0px;
    border-bottom: 2px solid #EAECED;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 0px;
    margin-bottom: 18px;
}

.sign-in-2-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-2-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-2-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-2-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-in-2-box form .keep-sign-area .check-input-control input:checked~.checkbox {
    background: #1D263A !important;
    border-color: #1D263A;
}

.sign-in-2-box form .sign-in-log-btn .btn {
    background: #FF794D;
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
    height: 45px;
    margin: 20px 0px 35px 0px;
}

.sign-in-2-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.sign-in-2-box form .create-new-acc-text p a {
    color: #FF794D;
}

.sign-in-2-box .reset-now-text p {
    font-size: 15px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 35px;
    margin-bottom: 0;
}

.sign-in-2-box .reset-now-text p a {
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Reset Password 02
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .reset-pass-2 {
    background: url(../image/sign-in/reset-pass-2-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 123px;
    padding-bottom: 60px;
} */

@media (min-width: 480px) {
    .reset-pass-2 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .reset-pass-2 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .reset-pass-2 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.reset-pass-2-box .heading {
    margin-bottom: 40px;
}

.reset-pass-2-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .reset-pass-2-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.reset-pass-2-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.reset-pass-2-box form {
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 40px 40px 30px 40px;
}

.reset-pass-2-box form .form-control {
    background: transparent;
    border-radius: 0px;
    border-bottom: 2px solid #EAECED;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 0px;
    margin-bottom: 18px;
}

.reset-pass-2-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-2-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-2-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-2-box form .sign-in-log-btn .btn {
    background: #FF794D;
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
    height: 45px;
    margin: 10px 0px 25px 0px;
}

.reset-pass-2-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.reset-pass-2-box form .create-new-acc-text p a {
    color: #FF794D;
}

.reset-pass-2-box .reset-now-text p {
    font-size: 15px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 35px;
    margin-bottom: 0;
}

.reset-pass-2-box .reset-now-text p a {
    color: #FFFFFF;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign Up 01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.sign-up-3 {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;
}

@media (min-width: 480px) {
    .sign-up-3 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-up-3 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-up-3 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-up-3 .inner-page-left-bg img {
    width: 100%;
}

@media (min-width: 992px) {
    .sign-up-3 .inner-page-left-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 56%;
    }

    .sign-up-3 .inner-page-left-bg img {
        width: 100%;
        height: 1240px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media (min-width: 1200px) {
    .sign-up-3 .inner-page-left-bg {
        width: 46%;
    }
}

.sign-up-3-box .heading {
    margin-bottom: 40px;
}

.sign-up-3-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-up-3-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-up-3-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.7;
}

.sign-up-3-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.sign-up-3-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.sign-up-3-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-3-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-3-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-up-3-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-up-3-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 20px 0px 35px 0px;
}

.sign-up-3-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.sign-up-3-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Sign In 03
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.sign-in-3 {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;
}

@media (min-width: 480px) {
    .sign-in-3 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .sign-in-3 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .sign-in-3 {
        padding-top: 235px;
        padding-bottom: 150px;
    }
}

.sign-in-3 .inner-page-left-bg img {
    width: 100%;
}

@media (min-width: 992px) {
    .sign-in-3 .inner-page-left-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 56%;
    }

    .sign-in-3 .inner-page-left-bg img {
        width: 100%;
        height: 945px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media (min-width: 1200px) {
    .sign-in-3 .inner-page-left-bg {
        width: 46%;
    }
}

.sign-in-3-box .heading {
    margin-bottom: 40px;
}

.sign-in-3-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .sign-in-3-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.sign-in-3-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.7;
}

.sign-in-3-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.sign-in-3-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.sign-in-3-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-3-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-3-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.sign-in-3-box form .keep-sign-area .remember-text {
    font-size: 15px;
    line-height: 28px;
    color: rgba(29, 38, 58, 0.8);
    margin-left: 15px;
}

.sign-in-3-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 20px 0px 35px 0px;
}

.sign-in-3-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.sign-in-3-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Reset Password 03
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.reset-pass-3 {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;
}

@media (min-width: 480px) {
    .reset-pass-3 {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .reset-pass-3 {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .reset-pass-3 {
        padding-top: 320px;
        padding-bottom: 220px;
    }
}

.reset-pass-3 .inner-page-left-bg img {
    width: 100%;
}

@media (min-width: 992px) {
    .reset-pass-3 .inner-page-left-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 56%;
    }

    .reset-pass-3 .inner-page-left-bg img {
        width: 100%;
        height: 945px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media (min-width: 1200px) {
    .reset-pass-3 .inner-page-left-bg {
        width: 46%;
    }
}

.reset-pass-3-box .heading {
    margin-bottom: 40px;
}

.reset-pass-3-box .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .reset-pass-3-box .heading h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.reset-pass-3-box .heading p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.7;
}

.reset-pass-3-box form label {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 10px;
}

.reset-pass-3-box form .form-control {
    background: rgba(225, 231, 240, 0.5);
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    height: 50px;
    padding-left: 25px;
    margin-bottom: 18px;
}

.reset-pass-3-box form .form-control::-moz-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-3-box form .form-control:-ms-input-placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-3-box form .form-control::placeholder {
    color: #1D263A !important;
    opacity: 0.6;
}

.reset-pass-3-box form .sign-in-log-btn .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    height: 56px;
    margin: 10px 0px 25px 0px;
}

.reset-pass-3-box form .create-new-acc-text p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.reset-pass-3-box form .create-new-acc-text p a {
    color: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        404 Page
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.error-page {
    padding-top: 108px;
    padding-bottom: 60px;
}

@media (min-width: 480px) {
    .error-page {
        padding-top: 125px;
    }
}

@media (min-width: 576px) {
    .error-page {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .error-page {
        padding-top: 150px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .error-page {
        padding-top: 245px;
        padding-bottom: 175px;
    }
}

.error-page-content h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-top: 30px;
    margin-bottom: 25px;
}

@media (min-width: 576px) {
    .error-page-content h2 {
        font-size: 60px;
        line-height: 66px;
    }
}

.error-page-content p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.7;
}

.back-to-btn .btn {
    background: #FF794D;
    border-radius: 5px;
    min-width: 213px;
    height: 45px;
    color: #fff;
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
    margin-top: 25px;
}

.back-to-btn .btn:hover {
    color: #fff !important;
}

.back-to-btn a {
    text-decoration: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
              Footer Area SIgn In 01
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-sign-in-1 {
    padding-top: 60px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .footer-sign-in-1 {
        padding-top: 80px;
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .footer-sign-in-1 {
        padding-top: 80px;
        padding-bottom: 85px;
    }
}

.footer-sign-in-1 .footer-border-top {
    margin: 40px 0px 60px 0px;
    border-top: 1px solid #E5E5E5;
}

@media (min-width: 768px) {
    .footer-sign-in-1 .footer-border-top {
        margin: 60px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .footer-sign-in-1 .footer-border-top {
        margin: 103px 0px 120px 0px;
    }
}

.footer-sign-in-1 .content {
    margin: 35px 0px 33px 0px;
}

.footer-sign-in-1 .content p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #666666;
}

.footer-sign-in-1 .footer-widget p {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #666666;
    margin-bottom: 35px;
}

.footer-sign-in-1 .footer-widget ul li a {
    font-family: 'Baloo Chettan 2', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: -0.4px;
    color: #1F1F1F;
}

.footer-sign-in-1 .social-icons {
    margin-bottom: 50px;
}

@media (min-width: 992px) {
    .footer-sign-in-1 .social-icons {
        margin-bottom: 0;
    }
}

.footer-sign-in-1 .social-icons ul {
    margin: 0px -9px 0px -9px;
}

.footer-sign-in-1 .social-icons ul li {
    margin: 0px 9px 0px 9px;
}

.footer-sign-in-1 .social-icons ul li i {
    font-size: 16px;
    color: #1f1f1f !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Testimonial 01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-testimonial-banner {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
}

@media (min-width: 480px) {
    .inner-testimonial-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-testimonial-banner {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-testimonial-banner {
        padding-top: 200px;
        padding-bottom: 80px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Testimonial 02
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-testimonial-main-area {
    background: #F9FAFB;
    padding-bottom: 120px;
}

.inner-testimonial-main-area .inner-testimonial-card-1-items {
    margin-bottom: -25px;
}

.inner-testimonial-main-area .inner-testimonial-card-1-items .col-xl-4 {
    margin-bottom: 25px;
}

.inner-testimonial-main-area .inner-testimonial-card-1 {
    background: #fff;
    border: 1px solid rgba(223, 223, 223, 0.7);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 30px 25px 30px 25px;
}

@media (min-width: 480px) {
    .inner-testimonial-main-area .inner-testimonial-card-1 {
        height: 100%;
    }
}

@media (min-width: 768px) {
    .inner-testimonial-main-area .inner-testimonial-card-1 .content {
        min-height: 250px;
    }
}

@media (min-width: 992px) {
    .inner-testimonial-main-area .inner-testimonial-card-1 .content {
        min-height: 170px;
    }
}

.inner-testimonial-main-area .inner-testimonial-card-1 .content p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
}

.inner-testimonial-main-area .inner-testimonial-card-1 .user-identity {
    background: rgba(214, 227, 235, 0.3);
    padding: 15px 20px;
    border-radius: 15px;
    margin-top: 40px;
}

.inner-testimonial-main-area .inner-testimonial-card-1 .user-identity .user-image img {
    margin: auto;
    margin-bottom: 15px;
}

@media (min-width: 480px) {
    .inner-testimonial-main-area .inner-testimonial-card-1 .user-identity .user-image img {
        margin-bottom: 0;
        margin-right: 25px;
    }
}

.inner-testimonial-main-area .inner-testimonial-card-1 .user-identity .user-details h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.inner-testimonial-main-area .inner-testimonial-card-1 .user-identity .user-details p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Service 01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-service-banner {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
}

@media (min-width: 480px) {
    .inner-service-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-service-banner {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-service-banner {
        padding-top: 200px;
        padding-bottom: 80px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Service 02
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-service-01 {
    padding-top: 28px;
}

@media (min-width: 768px) {
    .inner-service-01 {
        padding-top: 48px;
    }
}

@media (min-width: 992px) {
    .inner-service-01 {
        padding-top: 78px;
    }
}

.inner-service-newsletter-01 {
    background: #F9FAFB;
}

.inner-service-newsletter-01 .newsletter-form-l5 .btn {
    background: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service Details content Area 1
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-details-1-content-1 {
    background: #F9FAFB;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .service-details-1-content-1 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .service-details-1-content-1 {
        padding-bottom: 130px;
    }
}

.service-details-1-content-1 .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .service-details-1-content-1 .content {
        padding-top: 0;
    }
}

.service-details-1-content-1 .content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 20px;
}

.service-details-1-content-1 .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service Details Process Area
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-details-process-area {
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .service-details-process-area {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .service-details-process-area {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.service-details-process-area .heading h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #1D263A;
}

@media (min-width: 768px) {
    .service-details-process-area .heading h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.service-details-process-items {
    position: relative;
    margin-top: 40px;
    margin-bottom: -30px;
}

@media (min-width: 768px) {
    .service-details-process-items {
        margin-top: 60px;
    }
}

@media (min-width: 992px) {
    .service-details-process-items {
        margin-top: 90px;
    }

    .service-details-process-items:after {
        position: absolute;
        content: "";
        border-top: 3px dashed rgba(23, 98, 252, 0.2);
        width: 75%;
        top: 25px;
        left: 40px;
        z-index: -1;
    }
}

.service-details-process-items .col-lg-3 {
    margin-bottom: 30px;
}

.service-details-process-items .number-box span {
    filter: drop-shadow(0px 4px 25px rgba(23, 135, 252, 0.5));
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    background: #1787FC;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    margin-bottom: 45px;
}

.service-details-process-items .content-box h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 20px;
}

.service-details-process-items .content-box p {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: rgba(2, 11, 18, 0.7);
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service Details content Area 2
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-details-1-content-2 {
    background: #F9FAFB;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .service-details-1-content-2 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .service-details-1-content-2 {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}

.service-details-1-content-2 .content-area-2 {
    padding-top: 60px;
}

@media (min-width: 768px) {
    .service-details-1-content-2 .content-area-2 {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .service-details-1-content-2 .content-area-2 {
        padding-top: 115px;
    }
}

.service-details-1-content-2 .content {
    margin-bottom: -55px;
    padding-top: 45px;
}

@media (min-width: 992px) {
    .service-details-1-content-2 .content {
        padding-top: 0;
    }
}

.service-details-1-content-2 .content .card-item {
    margin-bottom: 55px;
}

.service-details-1-content-2 .content .card-item .icon-box {
    margin-right: 30px;
    margin-bottom: 30px;
    border: 1.5px solid #1787FC;
    width: 36px;
    height: 36px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    display: inline-table;
}

@media (min-width: 480px) {
    .service-details-1-content-2 .content .card-item .icon-box {
        margin-bottom: 0;
    }
}

.service-details-1-content-2 .content .card-item .content-box h6 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 20px;
    margin-top: -5px;
}

.service-details-1-content-2 .content .card-item .content-box p {
    font-size: 17px;
    line-height: 30px;
    color: #262729;
    opacity: 0.7;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service Details Cta Area
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-details-1-cta-area {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .service-details-1-cta-area {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .service-details-1-cta-area {
        padding-top: 90px;
    }
}

.service-details-1-cta-area .content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .service-details-1-cta-area .content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.service-details-1-cta-area .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 0;
}

.service-details-1-cta-area .cta-req-btn {
    margin-top: 30px;
}

@media (min-width: 992px) {
    .service-details-1-cta-area .cta-req-btn {
        margin-top: 0;
    }
}

.service-details-1-cta-area .cta-req-btn .btn {
    min-width: 263px;
    height: 55px;
    background: #20BFA9;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
}

.service-details-1-cta-area .cta-border {
    border-top: 1px solid rgba(29, 36, 58, 0.13);
    margin: 70px 0px 0px 0px;
}

@media (min-width: 768px) {
    .service-details-1-cta-area .cta-border {
        margin: 90px 0px 0px 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Blog Category
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-category-area {
    background: #F9FAFB;
    padding-bottom: 0px;
}

@media (min-width: 768px) {
    .blog-category-area {
        padding-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .blog-category-area {
        padding-bottom: 25px;
    }
}

.blog-category-area .heading h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .blog-category-area .heading h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.blog-category-area .read-article-btn .btn {
    min-width: 195px;
    height: 55px;
    border: 1.5px solid #2D947A;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #2D947A;
}

.blog-category-area .read-article-btn .btn:hover {
    color: #2D947A !important;
}

.blog-category-items {
    padding-bottom: 20px;
    padding-top: 45px;
}

.blog-category-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .blog-category-items {
        padding-bottom: 40px;
        padding-top: 45px;
    }
}

@media (min-width: 992px) {
    .blog-category-items {
        padding-bottom: 65px;
        padding-top: 45px;
    }
}

.blog-category-items .blog-card .blog-content h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FF794D;
    margin-top: 35px;
    margin-bottom: 20px;
}

.blog-category-items .blog-card .blog-content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Blog Regular
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-regular-area {
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .blog-regular-area {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .blog-regular-area {
        padding-bottom: 130px;
    }
}

.blog-regular-items {
    padding-bottom: 20px;
}

.blog-regular-items .col-lg-4 {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .blog-regular-items {
        padding-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .blog-regular-items {
        padding-bottom: 65px;
    }
}

.blog-regular-items .blog-card .blog-content h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FF794D;
    margin-top: 35px;
    margin-bottom: 20px;
}

.blog-regular-items .blog-card .blog-content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
}

.blog-reg-pagination-area ul {
    margin: 0px -5px;
}

.blog-reg-pagination-area ul li {
    margin: 0px 5px;
    font-size: 21px;
    color: #1D263A;
    cursor: pointer;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background: #FFFFFF;
    border: 1px solid #EAE9F2;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
}

.blog-reg-pagination-area ul li:hover {
    color: #fff;
    background: #1787FC;
}

.blog-reg-pagination-area ul li:hover a {
    color: #fff;
}

.blog-reg-pagination-area ul li a {
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Blog Details
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .inner-blog-details-banner {
    background: url(../image/blog/blog-details-banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0px;
    padding-top: 120px;
    padding-bottom: 55px;
} */

@media (min-width: 480px) {
    .inner-blog-details-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-blog-details-banner {
        padding-top: 150px;
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .inner-blog-details-banner {
        padding-top: 250px;
        padding-bottom: 65px;
    }
}

@media (min-width: 1200px) {
    .inner-blog-details-banner {
        padding-top: 405px;
    }
}

.blog-details-content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #FFFFFF;
}

@media (min-width: 480px) {
    .blog-details-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.blog-details-content .blog-details-banner-item {
    margin-top: 30px;
}

.blog-details-content .blog-details-banner-item ul {
    margin: 0px -15px -20px -15px;
}

.blog-details-content .blog-details-banner-item ul li {
    margin: 0px 15px 20px 15px;
}

.blog-details-content .blog-details-banner-item ul li i {
    font-size: 18px;
    color: #1787FC;
    margin-right: 12px;
}

.blog-details-content .blog-details-banner-item ul li span {
    font-size: 15px;
    line-height: 28px;
    color: #FFFFFF;
    opacity: 0.8;
    display: inline-block;
}

.blog-details-content-area {
    background: #F9FAFB;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .blog-details-content-area {
        padding-top: 80px;
    }
}

.blog-details-content-area .content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-top: 43px;
    margin-bottom: 20px;
}

.blog-details-content-area .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 20px;
}

.blog-details-content-area .content .advance-text-area .content {
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .blog-details-content-area .content .advance-text-area .content {
        margin-left: 30px;
        margin-bottom: 0;
    }
}

.blog-details-content-area .content .advance-text-area img {
    height: 225px;
    width: 225px;
    -o-object-fit: fill;
    object-fit: fill;
}

.blog-details-content-area .content .quote-area-blog-details {
    background: linear-gradient(270.32deg, #047AF5 0.34%, rgba(23, 135, 252, 0.6) 99.79%);
    border-radius: 0px 25px 25px 25px;
    margin: 32px 0px 30px 0px;
    padding: 35px 40px 10px 70px;
}

.blog-details-content-area .content .quote-area-blog-details p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
}

.blog-details-content-area .content .quote-area-blog-details .quote {
    position: absolute;
    left: 40px;
    top: 20px;
}

.blog-details-content-area .content .key-summary-area ul {
    margin-top: 32px;
    margin-bottom: -18px;
}

.blog-details-content-area .content .key-summary-area ul li {
    margin-bottom: 18px;
}

.blog-details-content-area .content .key-summary-area ul li span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    display: inline-block;
    margin-top: -8px;
}

.blog-details-content-area .content .key-summary-area ul li i {
    font-size: 8px;
    color: #1787FC;
    margin-right: 12px;
}

.blog-details-content-area .content .tagged-area {
    margin-top: 60px;
}

.blog-details-content-area .content .tagged-area h4 {
    margin-right: 30px;
}

.blog-details-content-area .content .tagged-area .tag-item ul {
    margin: 0px -6px -12px- 6px;
}

.blog-details-content-area .content .tagged-area .tag-item ul li {
    margin: 0px 6px 12px 6px;
    border: 1px solid rgba(29, 38, 58, 0.13);
    padding: 10px 30px;
}

.blog-details-content-area .content .tagged-area .tag-item ul li a {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
}

.blog-details-content-area .content .tagged-area-bottom-border {
    border-top: 1px solid rgba(29, 58, 36, 0.13);
    margin-top: 90px;
    margin-bottom: 35px;
}

.blog-details-right-sidebar h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 20px;
}

.blog-details-right-sidebar h5 a {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1D263A;
}

.blog-details-right-sidebar p a {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
}

.blog-details-right-sidebar .recent-post-item {
    margin-bottom: -16px;
}

.blog-details-right-sidebar .recent-post-item .post-content {
    border-bottom: 1px solid rgba(29, 38, 58, 0.1);
    margin-bottom: 16px;
}

.blog-details-right-sidebar .recent-post-item .post-content:last-child {
    border-bottom: 0;
}

.blog-details-right-sidebar .categories-item ul {
    margin: 0px 0px -35px 0px;
}

.blog-details-right-sidebar .categories-item ul li {
    border-bottom: 1px solid rgba(29, 38, 58, 0.1);
    margin: 0px 0px 17px 0px;
    padding-bottom: 17px;
}

.blog-details-right-sidebar .categories-item ul li a {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #1D263A;
}

.blog-details-right-sidebar .categories-item ul li:last-child {
    border-bottom: 0;
}

.blog-details-right-sidebar .blog-archive {
    position: relative;
    z-index: 1;
}

.blog-details-right-sidebar .blog-archive .nice-select {
    border: 1px solid rgba(29, 38, 58, 0.1);
    font-size: 17px;
    line-height: 30px;
    color: rgba(29, 38, 58, 0.8);
    float: inherit;
    height: 60px;
    line-height: 60px;
    width: 100%;
}

.blog-details-right-sidebar .blog-archive .nice-select .list {
    width: 100%;
}

.blog-details-right-sidebar .blog-archive .nice-select.nice-select:after {
    margin-top: -8px;
    border-bottom: 2px solid #1D263A;
    border-right: 2px solid #1D263A;
    content: '';
    height: 10px;
    right: 20px;
    width: 10px;
}

.blog-details-right-sidebar .blog-tag-cloud .tag-cloud-item ul {
    margin: 0px -6px -12px -6px;
}

.blog-details-right-sidebar .blog-tag-cloud .tag-cloud-item ul li {
    margin: 0px 6px 12px 6px;
    border: 1px solid rgba(29, 38, 58, 0.13);
    padding: 10px 30px;
}

.blog-details-right-sidebar .blog-tag-cloud .tag-cloud-item ul li a {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
}

.blog-details-right-sidebar .search-form-area .form-control {
    height: 60px;
    border-radius: 5px;
    border: 1px solid rgba(29, 38, 58, 0.1);
    background-color: #fff;
    color: #1D263A;
    opacity: 0.7;
    font-size: 15px;
    line-height: normal;
    padding-left: 50px;
}

.blog-details-right-sidebar .search-form-area .form-control::-moz-placeholder {
    color: #1D263A;
    opacity: 0.7;
}

.blog-details-right-sidebar .search-form-area .form-control:-ms-input-placeholder {
    color: #1D263A;
    opacity: 0.7;
}

.blog-details-right-sidebar .search-form-area .form-control::placeholder {
    color: #1D263A;
    opacity: 0.7;
}

.blog-details-right-sidebar .search-form-area button[type=submit] {
    position: absolute;
    left: 30px;
    top: 50%;
    color: #000;
    background-color: transparent;
    cursor: pointer;
    transform: translate(-50%, -50%);
    border: 0;
}

.blog-details-right-sidebar .sidebar-bg {
    background: #fff;
    border-radius: 10px;
    padding: 30px 30px 35px 30px;
    margin-bottom: 30px;
    filter: drop-shadow(0px 4px 56px rgba(22, 22, 22, 0.05));
}

.blog-previous-next-link-area .col-md-6 {
    margin-bottom: 30px;
}

.blog-previous-next-link-area .project-link-area .icon-box i {
    background: #1787FC;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 65px;
    width: 65px;
    line-height: 65px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-right: 25px;
}

.blog-previous-next-link-area .project-link-area .content-box span {
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 3px;
}

.blog-previous-next-link-area .project-link-area .content-box h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.blog-previous-next-link-area .project-link-area.project-link-area-next i {
    margin-left: 25px;
    margin-right: 0;
}

.comments-area {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #F9FAFB;
}

@media (min-width: 768px) {
    .comments-area {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .comments-area {
        padding-top: 70px;
        padding-bottom: 130px;
    }
}

.comments-area .comments-area-top-border {
    border-top: 1px solid rgba(181, 181, 181, 0.2);
}

.comments-area h4 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 45px;
}

.comments-area .comment-form {
    padding-top: 55px;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .comments-area .comment-form {
        padding-top: 75px;
        padding-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .comments-area .comment-form {
        padding-top: 105px;
        padding-bottom: 60px;
    }
}

.comments-area .comment-form .form-card-area {
    margin-bottom: 30px;
}

.comments-area .comment-form .form-card-area .user-image {
    margin-bottom: 20px;
}

@media (min-width: 480px) {
    .comments-area .comment-form .form-card-area .user-image {
        margin-bottom: 0;
        margin-right: 25px;
    }
}

.comments-area .comment-form .form-card-area .user-image img {
    width: 90px;
    height: 90px;
    -o-object-fit: fill;
    object-fit: fill;
}

.comments-area .comment-form .form-card-area .user-review-content {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(10, 4, 60, 0.02);
    border-radius: 0px 25px 25px 25px;
    padding: 30px 35px 25px 35px;
}

.comments-area .comment-form .form-card-area .user-review-content h5 a {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
}

.comments-area .comment-form .form-card-area .user-review-content h6 small {
    font-size: 15px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.comments-area .comment-form .form-card-area .user-review-content h6 span {
    color: #1D263A;
    opacity: 0.8;
    display: inline-block;
    margin: 0px 4px;
}

.comments-area .comment-form .form-card-area .user-review-content p {
    font-size: 18px;
    line-height: 32px;
    color: #1D263A;
    margin-top: 15px;
}

.comments-area .comment-form .form-card-area .user-review-content .reply-btn .btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #1D263A;
    padding: 0;
    min-width: inherit;
}

.comments-area .comment-form .form-card-area .user-review-content .reply-btn .btn i {
    margin-right: 10px;
}

.comments-area .comment-form .form-card-area .user-review-content .reply-btn .btn:hover {
    color: #1D263A !important;
}

@media (min-width: 992px) {
    .comments-area .comment-form .form-card-area--reply {
        padding-left: 120px;
    }
}

.blog-details-contact-form-area h4 {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 45px;
}

.blog-details-contact-form-area .blog-details-contact-form {
    position: relative;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 30px;
}

@media (min-width: 576px) {
    .blog-details-contact-form-area .blog-details-contact-form {
        padding: 30px 45px 40px 45px;
    }
}

.blog-details-contact-form-area .blog-details-contact-form .form-group {
    margin-bottom: 30px;
}

.blog-details-contact-form-area .blog-details-contact-form label {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 5px;
}

.blog-details-contact-form-area .blog-details-contact-form .form-control {
    background: #E1ECF0;
    border-radius: 10px;
    height: 50px;
    font-size: 15px;
    line-height: 28px;
    color: #717986;
    border: 0;
    padding-left: 22px;
    padding-right: 22px;
}

.blog-details-contact-form-area .blog-details-contact-form .form-control::-moz-placeholder {
    color: #717986;
}

.blog-details-contact-form-area .blog-details-contact-form .form-control:-ms-input-placeholder {
    color: #717986;
}

.blog-details-contact-form-area .blog-details-contact-form .form-control::placeholder {
    color: #717986;
}

.blog-details-contact-form-area .blog-details-contact-form textarea {
    background: #E1ECF0;
    border-radius: 10px;
    height: 100px !important;
}

@media (min-width: 768px) {
    .blog-details-contact-form-area .blog-details-contact-form textarea {
        height: 180px !important;
    }
}

.blog-details-contact-form-area .blog-details-contact-form .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    height: 56px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Pricing-01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-pricing-area-1 {
    background: #F9FAFB;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .inner-pricing-area-1 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-pricing-area-1 {
        padding-bottom: 120px;
    }
}

.inner-pricing-area-1-items {
    margin-bottom: -30px;
}

.inner-pricing-area-1-items .col-xl-4 {
    margin-bottom: 30px;
}

.inner-pricing-area-1-items .price-card {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 40px 35px 40px 35px;
}

.inner-pricing-area-1-items .price-card .price-top-area {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.inner-pricing-area-1-items .price-card .price-top-area h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 5px;
}

.inner-pricing-area-1-items .price-card .price-top-area span {
    display: inline-block;
    font-size: 13px;
    line-height: 22px;
    color: #1D263A;
    opacity: 0.7;
}

.inner-pricing-area-1-items .price-card .price-item-list {
    padding-top: 40px;
}

.inner-pricing-area-1-items .price-card .price-item-list ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 15px;
}

.inner-pricing-area-1-items .price-card .price-item-list ul li i {
    color: #1D263A;
}

.inner-pricing-area-1-items .price-card .price-item-list ul li.del {
    color: #7F828A;
}

.inner-pricing-area-1-items .price-card .price-item-list ul li.del i {
    color: #7F828A;
}

.inner-pricing-area-1-items .price-card .price-area-btn .btn {
    width: 100%;
    height: 56px;
    background: #1787FC;
    border-radius: 50px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-top: 27px;
}

.inner-pricing-area-1-items .price-card.professional-price-card {
    background: #1787FC;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-top-area {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-top-area h4 {
    color: #fff;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-top-area span {
    color: #fff;
    opacity: 0.7;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-item-list {
    padding-top: 40px;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-item-list ul li {
    color: #fff;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-item-list ul li i {
    color: #fff;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-item-list ul li.del {
    color: #fff;
    opacity: 0.7;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-item-list ul li.del i {
    color: #fff;
    opacity: 0.7;
}

.inner-pricing-area-1-items .price-card.professional-price-card .price-area-btn .btn {
    background: #fff;
    color: #1787FC;
}

.feature-area-price-1 {
    background: #F9FAFB;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .feature-area-price-1 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .feature-area-price-1 {
        padding-bottom: 130px;
    }
}

.feature-area-price-1-items {
    margin-bottom: -55px;
}

.feature-area-price-1-items .col-lg-4 {
    margin-bottom: 55px;
}

.feature-card-price-1 .icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    text-align: center;
    background: rgba(214, 227, 235, 0.4);
    display: inline-table;
}

.feature-card-price-1 .icon img {
    width: 32px;
    height: 32px;
}

.feature-card-price-1 .icon.icon-2 {
    background: rgba(250, 229, 218, 0.4);
}

.feature-card-price-1 .icon.icon-3 {
    background: #EBF5FF;
}

.feature-card-price-1 .content {
    margin-left: 35px;
}

.feature-card-price-1 .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 15px;
}

.feature-card-price-1 .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Pricing 02
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-price-table-2 {
    background: #F9FAFB;
    padding-bottom: 50px;
}

@media (min-width: 768px) {
    .inner-price-table-2 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .inner-price-table-2 {
        padding-bottom: 120px;
    }
}

.inner-price-table-2 .section__heading h2 {
    margin-bottom: 30px;
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-yearly);
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
    background: #1787FC;
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
    left: calc(100% - 24px);
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
    background: #1787FC;
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
    left: calc(100% - 52%);
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
    color: #1D263A !important;
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
    color: #fff !important;
}

.inner-price-table-2 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
    display: inline-block;
    content: attr(data-monthly);
}

.inner-price-table-2 .btn-toggle-2[data-pricing-trigger].active:before {
    color: #fff !important;
}

.inner-price-table-2 .btn-toggle-2[data-pricing-trigger].active:after {
    color: #1D263A !important;
}

.inner-price-table-2 .dynamic-value:before {
    display: inline-block;
    content: attr(data-active);
}

.inner-price-table-2 .static-value:before {
    display: inline-block;
    content: attr(data-active);
}

.inner-price-table-2 .btn-toggle {
    width: 48px;
    height: 24px;
    border-radius: 17px;
    background-color: #f8f8f8 !important;
    position: relative;
    margin: 0 15px;
}

.inner-price-table-2 .btn-toggle span {
    width: 16px;
    height: 16px;
    background-color: #1787FC;
    position: absolute;
    left: 5px;
    top: 4px;
    transition: 0.4s;
    border-radius: 500px;
    pointer-events: none;
}

.inner-price-table-2 .btn-toggle-8 {
    width: 265px;
    height: 42px;
    border-radius: 30px;
    background-color: #fff !important;
    position: relative;
    margin: 0 15px;
    z-index: 1;
}

.inner-price-table-2 .btn-toggle-8:before {
    position: absolute;
    content: "PER MONTHLY" !important;
    width: 50%;
    text-transform: uppercase;
    color: #fff;
    height: 34px;
    background: transparent;
    left: 6px;
    top: 4px;
    line-height: 34px;
    font-size: 12px;
    font-weight: 700 !important;
    letter-spacing: 0.5px;
}

.inner-price-table-2 .btn-toggle-8:after {
    position: absolute;
    content: "PER YEARLY" !important;
    width: 50%;
    font-size: 13px;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: #1D263A;
    height: 34px;
    background: transparent;
    right: 4px;
    top: 4px;
    line-height: 34px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.inner-price-table-2 .btn-toggle-8 .round {
    width: 50%;
    height: 34px;
    top: 3px;
    left: 4px;
    background: #FF794D;
    display: block;
    z-index: -1;
}

.inner-price-table-2 .btn-toggle-8 span {
    display: none;
}

.inner-price-table-2 .btn-toggle[data-value-active="yearly"] {
    background-color: #1787FC !important;
}

.inner-price-table-2 .btn-toggle[data-value-active="yearly"] span {
    left: calc(100% - 25px) !important;
}

.inner-price-table-2 .btn-toggle-square {
    height: 65px;
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 5px;
    align-items: center;
}

.inner-price-table-2 .btn-toggle-square.bg-whisper-3:hover,
.inner-price-table-2 .btn-toggle-square.bg-whisper-3:focus {
    background: #fff !important;
}

.inner-price-table-2 .btn-toggle-square span {
    min-width: 160px;
    min-height: 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}

.inner-price-table-2 .btn-toggle-square span:first-child {
    border-radius: 10px 0 0 10px;
}

.inner-price-table-2 .btn-toggle-square span:nth-child(2) {
    border-radius: 0px 10px 10px 0;
}

.inner-price-table-2 .btn-toggle-square span.active {
    color: #fff;
    background: #1787FC;
}

.inner-price-table-2 .popular-pricing:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 4px;
    background: #f46f7c;
    border-radius: 6px;
}

.inner-price-table-2 .popular-pricing.popular-pricing-2:before {
    height: 3px;
    top: -3px;
    background: red;
}

.inner-price-table-2 .priceing_area_four .sec_title {
    margin-bottom: 50px;
}

.inner-price-table-2 .price_info_two .price_head {
    display: table;
    width: 100%;
}

.inner-price-table-2 .price_info_two .price_head .p_head {
    width: calc(100%/5);
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    padding: 31px 0;
}

.inner-price-table-2 .price_info_two .price_head .p_head h5 {
    color: #1D263A;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

@media (min-width: 576px) {
    .inner-price-table-2 .price_info_two .price_head .p_head h5 {
        font-size: 16px;
    }
}

.inner-price-table-2 .price_info_two .price_head .p_head p {
    color: #1D263A;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

.inner-price-table-2 .price_info_two .price_head .p_head h2 {
    color: #1D263A;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1.12px;
    line-height: normal;
    margin-top: 27px;
}

@media (min-width: 576px) {
    .inner-price-table-2 .price_info_two .price_head .p_head h2 {
        font-size: 34px;
    }
}

.inner-price-table-2 .ex_team_item .hover_content .n_hover_content ul,
.inner-price-table-2 .job_listing .listing_tab .list_item .joblisting_text ul,
.inner-price-table-2 .price_info_two .price_head .p_head h5,
.inner-price-table-2 .priceing_area_four .sec_title h2 {
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .inner-price-table-2 .price_info_two {
        padding-top: 63px;
    }
}

@media (min-width: 992px) {
    .inner-price-table-2 .price_info_two .price_head .p_head:nth-child(4) {
        background: #1D263A;
        border-radius: 9px 9px 0px 0px;
    }

    .inner-price-table-2 .price_info_two .price_item:nth-child(4) {
        background: #1D263A;
    }

    .inner-price-table-2 .price_info_two .price_item:nth-child(4) a {
        color: #fff !important;
    }

    .inner-price-table-2 .price_info_two .price_item:nth-child(4) span {
        color: #fff !important;
    }

    .inner-price-table-2 .price_info_two .price_item:nth-child(4) i {
        color: #fff !important;
    }

    .inner-price-table-2 .p_head:nth-child(4) h2 {
        color: #fff !important;
    }

    .inner-price-table-2 .p_head:nth-child(4) p {
        color: #fff !important;
    }
}

.inner-price-table-2 .price_info_two .price_item {
    width: calc(100%/5);
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    cursor: pointer;
    padding: 15px 0;
}

.inner-price-table-2 .price_info_two .price_item span {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #222d39;
}

.inner-price-table-2 .pr_list:last-child .price_item:nth-child(4) {
    border-radius: 0px 0px 9px 9px;
}

.inner-price-table-2 .price_info_two .price_item:first-child {
    text-align: left;
}

.inner-price-table-2 .price_info_two .price_item span i {
    font-size: 15px;
    color: #1D263A;
}

.inner-price-table-2 .pr_list:last-child {
    border: none !important;
}

.inner-price-table-2 .p_head:nth-child(4) h5 {
    color: #fff !important;
    border-radius: 30px;
    background-color: #FF794D;
    padding: 0px 11px;
}

@media (min-width: 576px) {
    .inner-price-table-2 .p_head:nth-child(4) h5 {
        display: inline-block;
        padding: 8px 30px;
    }
}

.inner-price-table-2 .price_info_two .price_item .pr_title {
    position: relative;
    display: inline-block;
    color: #1D263A;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

.inner-price-table-2 .price_btn i {
    margin-left: 13px;
    background-color: #fff;
    color: #1787FC;
    border: 2px solid #1787FC;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
}

@media (min-width: 992px) {
    .inner-price-table-2 .price_item.price-get-started-btn-3 i {
        background-color: #332727;
        color: #fff;
        border: 2px solid #fff;
    }
}

@media (min-width: 480px) {
    .inner-price-table-2 .pr_list.pricing-get-strated-btn {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .inner-price-table-2 .pr_list.pricing-get-strated-btn {
        display: inherit;
    }
}

.inner-price-table-2 .price_info_two .price_item .check {
    color: #1D263A;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

.inner-price-table-2 .price_info_two .pr_list {
    display: table;
    width: 100%;
    border-bottom: 1px solid #eaeced;
}

.inner-price-table-2 .price_info_two a {
    text-decoration: none;
}

.inner-price-table-2 .price_info_two .price_btn {
    color: #1787FC;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

@media (min-width: 576px) {
    .inner-price-table-2 .price_info_two .price_btn {
        font-size: 16px;
    }
}

@media (max-width: 768px) {

    .inner-price-table-2 .price_info_two .pr_list,
    .inner-price-table-2 .price_info_two .price_head .p_head.time {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .inner-price-table-2 .price_info_two .price_item:first-child {
        text-align: center;
        padding: 5px 0;
    }
}

@media (max-width: 1199px) {
    .inner-price-table-2 .price_info_two .price_item:first-child {
        padding-left: 10px;
    }
}

@media (max-width: 768px) {
    .inner-price-table-2 .price_info_two .price_item {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .inner-price-table-2 .price_info_two .price_item:before {
        content: attr(data-title);
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 500;
    }
}

@media (max-width: 768px) {
    .inner-price-table-2 .price_info_two .price_item:first-child h5 {
        width: initial;
        border: 0;
        float: none;
        padding: 15px 0px;
    }
}

@media (max-width: 768px) {
    .inner-price-table-2 .price_info_two .price_item span {
        border-left: 1px dashed #ededed;
        display: block;
        float: right;
        font-size: 1.1rem;
        height: 100%;
        line-height: 3.1rem;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
    }
}

@media (max-width: 768px) and (min-width: 768px) {
    .inner-price-table-2 .pr_list.pricing-get-strated-btn {
        display: flex;
    }
}

@media (max-width: 768px) and (min-width: 992px) {
    .inner-price-table-2 .pr_list.pricing-get-strated-btn {
        display: initial;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Pricing 02 FAQ
  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.price-2-faq-area {
    background: #fff;
    padding-top: 50px;
}

@media (min-width: 768px) {
    .price-2-faq-area {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .price-2-faq-area {
        padding-top: 120px;
    }
}

.price-2-faq-area-items {
    padding-top: 70px;
    margin-bottom: -50px;
}

.price-2-faq-area-items .col-xl-6 {
    margin-bottom: 50px;
}

.price-2-faq-area-items .content .question-item h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 30px;
}

.price-2-faq-area-items .content .question-item p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.7;
    margin-bottom: 0;
}

.price-2-faq-area-bottom {
    padding-top: 40px;
}

@media (min-width: 768px) {
    .price-2-faq-area-bottom {
        padding-top: 60px;
    }
}

@media (min-width: 992px) {
    .price-2-faq-area-bottom {
        padding-top: 90px;
    }
}

.price-2-faq-area-bottom p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Contact-01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-pages-banner {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
}

@media (min-width: 480px) {
    .inner-pages-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-pages-banner {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-pages-banner {
        padding-top: 200px;
        padding-bottom: 80px;
    }
}

/* .inner-contact-form-area-1 {
    background: url(../image/contact/contact-bg-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0px 60px 0px;
} */

@media (min-width: 768px) {
    .inner-contact-form-area-1 {
        padding: 80px 0px 80px 0px;
    }
}

@media (min-width: 992px) {
    .inner-contact-form-area-1 {
        padding: 100px 0px 180px 0px;
    }
}

.inner-contact-form-1 {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 30px;
}

@media (min-width: 576px) {
    .inner-contact-form-1 {
        padding: 30px 45px 40px 45px;
    }
}

@media (min-width: 992px) {
    .inner-contact-form-1 {
        margin-top: -340px;
    }
}

@media (min-width: 1600px) {
    .inner-contact-form-1 {
        margin-top: -320px;
    }
}

.inner-contact-form-1 .form-group {
    margin-bottom: 30px;
}

.inner-contact-form-1 label {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 5px;
}

.inner-contact-form-1 .form-control {
    background: #E1ECF0;
    border-radius: 10px;
    height: 50px;
    font-size: 15px;
    line-height: 28px;
    color: #717986;
    border: 0;
    padding-left: 22px;
    padding-right: 22px;
}

.inner-contact-form-1 .form-control::-moz-placeholder {
    color: #717986;
}

.inner-contact-form-1 .form-control:-ms-input-placeholder {
    color: #717986;
}

.inner-contact-form-1 .form-control::placeholder {
    color: #717986;
}

.inner-contact-form-1 textarea {
    background: #E1ECF0;
    border-radius: 10px;
    height: 100px !important;
}

@media (min-width: 768px) {
    .inner-contact-form-1 textarea {
        height: 180px !important;
    }
}

.inner-contact-form-1 .btn {
    background: #20BFA9;
    border-radius: 50px;
    width: 100%;
    height: 56px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Contact-02
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-contact-area-2 {
    padding-top: 0;
    background: #F9FAFB;
}

.inner-contact-area-2 .cta-form-area-l9 {
    border-radius: 0px 0px 10px 10px;
}

.inner-contact-area-2 .cta-form-area-l9 .form-control {
    border-radius: 0;
}

.inner-contact-2-items-area {
    padding-bottom: 10px;
    background: #F5F5F5;
    padding-top: 6 0px;
}

@media (min-width: 768px) {
    .inner-contact-2-items-area {
        padding-top: 80px;
        padding-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .inner-contact-2-items-area {
        padding-top: 100px;
        padding-bottom: 80px;
    }
}

.inner-contact-2-items-area .inner-contact-2-items-area-items .col-lg-4 {
    margin-bottom: 40px;
}

.inner-contact-2-items-area .card-area .icon-box {
    margin-bottom: 30px;
    margin-right: 30px;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
    background: rgba(214, 227, 235, 0.4);
    display: inline-table;
}

.inner-contact-2-items-area .card-area .icon-box img {
    width: 24px;
    height: 24px;
}

@media (min-width: 480px) {
    .inner-contact-2-items-area .card-area .icon-box {
        margin-bottom: 0;
    }
}

.inner-contact-2-items-area .card-area .content-box {
    margin-top: -5px;
}

.inner-contact-2-items-area .card-area .content-box h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 20px;
}

.inner-contact-2-items-area .card-area .content-box p {
    font-size: 21px;
    line-height: 32px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-01
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-card-masonry {
    position: relative;
}

.portfolio-navbar {
    padding: 30px 0px;
}

@media (min-width: 768px) {
    .portfolio-navbar {
        padding: 50px 0px;
    }
}

.portfolio-navbar ul li a {
    color: #6f727b;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}

.link-hover-light:hover {
    color: #495fef;
}

.isotope-nav li a.active {
    color: #495fef;
}

.portfolio-card-masonry:hover .text-block {
    bottom: 20px;
    opacity: 1;
}

.portfolio-card-masonry .text-block {
    position: absolute;
    bottom: 0px;
    left: 20px;
    right: 20px;
    opacity: 0;
    transition: .4s;
    padding: 30px 15px 15px 30px;
}

.nogap-btn-margin {
    margin-top: 45px;
}

.portfolio-card-hover .card-image {
    transition: .4s;
}

.portfolio-card-hover .card-image img {
    transition: .4s;
}

.portfolio-card-hover:hover .card-image {
    transform: scale(0.8) rotate(-16deg);
}

.portfolio-card-hover:hover img {
    border-radius: 20px;
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
}

.link-hover-reset:hover {
    color: var(--color-text);
}

.link-hover-rotate {
    transition: .4s;
    display: inline-flex;
    will-change: transform;
    transform: rotate(0deg);
}

.link-hover-rotate:hover {
    transform: rotate(-10deg);
}

.isotope-item {
    transition: .4s;
}

.gr-bg-opacity {
    z-index: 1;
}

.gr-bg-opacity:before {
    position: absolute;
    content: "";
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: .9;
    border-radius: 8px;
}

.gr-pb-7,
.gr-py-7 {
    padding-bottom: 25px !important;
}

.portfolio-card-masonry span {
    color: #161c2d;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.portfolio-card-masonry h3 {
    color: #161c2d;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1.2px;
    line-height: 44px;
}

@media (min-width: 768px) {
    .portfolio-card-masonry h3 {
        font-size: 26px;
    }
}

.grid--no-gap .col-md-6 {
    padding: 0;
}

.grid--no-gap .col-sm-6 {
    padding: 0;
}

.grid--no-gap .col-lg-4 {
    padding: 0;
}

.grid--no-gap .col-lg-3 {
    padding: 0;
}

.grid--no-gap .col-12 {
    padding: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-03
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-grid-area-3 {
    background: #F9FAFB;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .portfolio-grid-area-3 {
        padding-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .portfolio-grid-area-3 {
        padding-bottom: 100px;
    }
}

.portfolio-grid-area-3 .portfolio-grid-area-3-items .portfolio-grid-3-card .content span {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FF794D;
    margin-bottom: 17px;
    margin-top: 20px;
}

.portfolio-grid-area-3 .portfolio-grid-area-3-items .portfolio-grid-3-card .content h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 30px;
}

@media (min-width: 992px) {
    .portfolio-grid-area-3 .portfolio-grid-area-3-items .portfolio-grid-3-card.port-margin-gap-1 {
        margin-top: 225px;
    }

    .portfolio-grid-area-3 .portfolio-grid-area-3-items .portfolio-grid-3-card.port-margin-gap-2 {
        margin-top: 155px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-Details-1
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-details-area-1 {
    position: relative;
    background: #F9FAFB;
    padding-top: 128px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
}

@media (min-width: 480px) {
    .portfolio-details-area-1 {
        padding-top: 140px;
    }
}

@media (min-width: 768px) {
    .portfolio-details-area-1 {
        padding-top: 160px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-1 {
        padding-top: 195px;
        padding-bottom: 100px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-1:after {
        position: absolute;
        content: '';
        background: #000;
        height: 100%;
        width: 62%;
        top: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        left: 0;
        opacity: 1;
        z-index: -1;
    }
}

.portfolio-details-area-1 .portfolio-details-1-image img {
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.portfolio-details-area-1 .port-details-1-content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .portfolio-details-area-1 .port-details-1-content {
        padding-top: 0px;
    }
}

@media (min-width: 1600px) {
    .portfolio-details-area-1 .port-details-1-content {
        padding-left: 80px;
    }
}

.portfolio-details-area-1 .port-details-1-content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1px;
    color: #262729;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .portfolio-details-area-1 .port-details-1-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.portfolio-details-area-1 .port-details-1-content p {
    font-size: 17px;
    line-height: 30px;
    color: #262729;
    opacity: 0.7;
}

.portfolio-details-area-1 .port-details-1-content .project-details {
    margin-top: 45px;
}

.portfolio-details-area-1 .port-details-1-content .project-details ul {
    margin-bottom: -15px;
}

.portfolio-details-area-1 .port-details-1-content .project-details ul li {
    margin-bottom: 15px;
}

.portfolio-details-area-1 .port-details-1-content .project-details ul li strong {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.portfolio-details-area-1 .port-details-1-content .project-details ul li span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-Details-2
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
/* .portfolio-details-area-2 {
    position: relative;
    padding-top: 180px;
    padding-bottom: 165px;
    position: relative;
    background: url(../image/portfolio/portfolio-details-2-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
} */

@media (min-width: 768px) {
    .portfolio-details-area-2 {
        padding-top: 250px;
        padding-bottom: 165px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-2 {
        padding-top: 370px;
        padding-bottom: 300px;
    }
}

.portfolio-details-content-area-2 {
    background: #F9FAFB;
    padding-top: 65px;
}

@media (min-width: 1600px) {
    .portfolio-details-content-area-2 .content {
        padding-right: 30px;
    }
}

.portfolio-details-content-area-2 .content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 25px;
}

.portfolio-details-content-area-2 .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 20px;
}

.project-info-area-pr-de-2 {
    padding-top: 45px;
    z-index: 1;
    position: relative;
}

@media (min-width: 992px) {
    .project-info-area-pr-de-2 {
        margin-top: -250px;
        padding-top: 0;
    }
}

.project-info-area-pr-de-2 .port-details-1-content {
    background: #FFFFFF;
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
    border-radius: 15px;
    padding: 38px 30px 45px 30px;
}

@media (min-width: 480px) {
    .project-info-area-pr-de-2 .port-details-1-content {
        padding: 38px 45px 45px 45px;
    }
}

.project-info-area-pr-de-2 .port-details-1-content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
}

@media (min-width: 480px) {
    .project-info-area-pr-de-2 .port-details-1-content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.project-info-area-pr-de-2 .port-details-1-content .btn {
    min-width: 150px;
    height: 55px;
    background: #FF794D;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 40px;
}

.project-info-area-pr-de-2 .port-details-1-content .project-details {
    margin-top: 38px;
}

.project-info-area-pr-de-2 .port-details-1-content .project-details ul {
    margin-bottom: -15px;
}

.project-info-area-pr-de-2 .port-details-1-content .project-details ul li {
    margin-bottom: 15px;
}

.project-info-area-pr-de-2 .port-details-1-content .project-details ul li strong {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.project-info-area-pr-de-2 .port-details-1-content .project-details ul li span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-left: 15px;
}

.project-info-area-pr-de-2 p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-top: 40px;
    margin-bottom: 0;
}

.process-area-port-de-2 {
    background: #F9FAFB;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .process-area-port-de-2 {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .process-area-port-de-2 {
        padding-top: 95px;
        padding-bottom: 130px;
    }
}

.process-area-port-de-2 .service-details-process-items {
    margin-top: 40px;
    z-index: 1;
}

.key-challenges-area {
    background: #F9FAFB;
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .key-challenges-area {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .key-challenges-area {
        padding-bottom: 110px;
    }
}

.key-challenges-area .content {
    padding-top: 45px;
}

@media (min-width: 992px) {
    .key-challenges-area .content {
        padding-top: 0;
    }
}

@media (min-width: 1600px) {
    .key-challenges-area .content {
        padding-right: 30px;
    }
}

.key-challenges-area .content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: -1px;
    color: #1D263A;
}

.key-challenges-area .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-top: 35px;
}

.key-challenges-area .challenge-list {
    margin-top: 30px;
}

.key-challenges-area .challenge-list ul li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.key-challenges-area .challenge-list ul li img {
    margin-right: 15px;
    margin-top: -5px;
}

.prev-next-project-area {
    padding-top: 35px;
    border-top: 1px solid rgba(29, 36, 58, 0.1);
    background: #F9FAFB;
}

.prev-next-project-area .prev-next-project-items .col-md-6 {
    margin-bottom: 30px;
}

.prev-next-project-area .prev-next-project-items .project-link-area .icon-box i {
    background: #1787FC;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 65px;
    width: 65px;
    line-height: 65px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-right: 25px;
}

.prev-next-project-area .prev-next-project-items .project-link-area .content-box span {
    display: inline-block;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 3px;
}

.prev-next-project-area .prev-next-project-items .project-link-area .content-box h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.prev-next-project-area .prev-next-project-items .project-link-area.project-link-area-next i {
    margin-left: 25px;
    margin-right: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-Details-3
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-details-area-3 {
    background: #F9FAFB;
    position: relative;
    padding-top: 130px;
    padding-bottom: 50px;
    position: relative;
}

@media (min-width: 480px) {
    .portfolio-details-area-3 {
        padding-top: 140px;
    }
}

@media (min-width: 768px) {
    .portfolio-details-area-3 {
        padding-top: 160px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-3 {
        padding-top: 200px;
    }
}

.portfolio-details-area-3 .product-details-slider {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .portfolio-details-area-3 .product-details-slider {
        margin-bottom: 0;
    }
}

.portfolio-details-area-3 .product-details-slider img {
    border-radius: 15px;
    -o-object-fit: fill;
    object-fit: fill;
}

@media (min-width: 768px) {
    .portfolio-details-area-3 .product-details-slider img {
        height: 295px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-3 .product-details-slider img {
        height: 390px;
    }
}

@media (min-width: 1200px) {
    .portfolio-details-area-3 .product-details-slider img {
        height: 468px;
    }
}

@media (min-width: 1600px) {
    .portfolio-details-area-3 .product-details-slider img {
        height: 538px;
    }
}

.portfolio-details-area-3 .product-details-vertical-slider {
    margin: 0px -12px;
}

.portfolio-details-area-3 .product-details-vertical-slider .single-slide {
    margin: 0px 12px;
}

@media (min-width: 768px) {
    .portfolio-details-area-3 .product-details-vertical-slider .single-slide {
        margin-bottom: 25px;
    }
}

.project-information-pd-3 {
    background: #F9FAFB;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .project-information-pd-3 {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .project-information-pd-3 {
        padding-bottom: 100px;
    }
}

.project-information-pd-3 .content h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 20px;
}

@media (min-width: 480px) {
    .project-information-pd-3 .content h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.project-information-pd-3 .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 20px;
}

.project-information-pd-3 .btn {
    min-width: 150px;
    height: 55px;
    background: #1787FC;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 40px;
}

.project-information-pd-3 .project-details {
    margin-top: 38px;
}

.project-information-pd-3 .project-details ul {
    margin-bottom: -15px;
}

.project-information-pd-3 .project-details ul li {
    margin-bottom: 15px;
}

.project-information-pd-3 .project-details ul li strong {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
}

.project-information-pd-3 .project-details ul li span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-left: 15px;
}

.project-brief-area .product-image img {
    -o-object-fit: fill;
    object-fit: fill;
}

@media (min-width: 992px) {
    .project-brief-area .product-image img {
        height: 240px;
    }
}

@media (min-width: 1200px) {
    .project-brief-area .product-image img {
        height: 300px;
    }
}

@media (min-width: 1600px) {
    .project-brief-area .product-image img {
        height: 380px;
    }
}

@media (min-width: 768px) {
    .project-brief-area .col-md-8 {
        margin-top: 25px;
    }
}

@media (min-width: 992px) {
    .project-brief-area .col-md-8 {
        margin-top: 0;
    }
}

.project-brief-area .col-md-6 {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .project-brief-area .col-md-6 {
        margin-top: 0;
    }
}

.project-brief-area {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .project-brief-area {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .project-brief-area {
        padding-top: 120px;
    }
}

.project-brief-area .content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 22px;
}

.project-brief-area .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.project-brief-area .project-brief-area-items {
    padding-bottom: 45px;
}

.client-feed-back-port-3 .heading {
    padding: 40px 0px;
}

@media (min-width: 768px) {
    .client-feed-back-port-3 .heading {
        padding: 60px 0px;
    }
}

.client-feed-back-port-3 .heading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
}

@media (min-width: 480px) {
    .client-feed-back-port-3 .heading h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.client-feed-back-port-3 .content-l5-3-content {
    background: linear-gradient(270.32deg, #047AF5 0.34%, rgba(23, 135, 252, 0.6) 99.79%);
}

.similar-project-area {
    background: #F9FAFB;
    padding-top: 50px;
    padding-bottom: 35px;
}

.similar-project-area .section-heading-14 {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .similar-project-area .section-heading-14 {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .similar-project-area .section-heading-14 {
        margin-bottom: 80px;
    }
}

.similar-project-area .col-lg-4 {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .similar-project-area {
        padding-top: 70px;
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .similar-project-area {
        padding-top: 120px;
        padding-bottom: 105px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Portfolio-Details-4
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-details-area-4 {
    background: #F9FAFB;
    position: relative;
    padding-top: 130px;
    padding-bottom: 50px;
    position: relative;
}

@media (min-width: 480px) {
    .portfolio-details-area-4 {
        padding-top: 140px;
    }
}

@media (min-width: 768px) {
    .portfolio-details-area-4 {
        padding-top: 160px;
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-4 {
        padding-top: 210px;
        padding-bottom: 120px;
    }
}

.portfolio-details-area-4-bg {
    background: #FFFFFF;
    border-radius: 15px;
    padding-top: 50px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .portfolio-details-area-4-bg {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-4-bg {
        padding-top: 100px;
        padding-bottom: 90px;
    }
}

.portfolio-details-area-4-bg .heading {
    margin-bottom: 40px;
}

@media (min-width: 768px) {
    .portfolio-details-area-4-bg .heading {
        margin-bottom: 50px;
    }
}

@media (min-width: 992px) {
    .portfolio-details-area-4-bg .heading {
        margin-bottom: 60px;
    }
}

.portfolio-details-area-4-bg .heading h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 20px;
}

@media (min-width: 480px) {
    .portfolio-details-area-4-bg .heading h2 {
        font-size: 42px;
        line-height: 50px;
    }
}

.portfolio-details-area-4-bg .heading p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/* .portfolio-det-area-4-bg-image {
    position: relative;
    padding-top: 115px;
    padding-bottom: 115px;
    background: url(../image/portfolio/portfolio-bg-4.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
} */

@media (min-width: 768px) {
    .portfolio-det-area-4-bg-image {
        padding-top: 170px;
        padding-bottom: 170px;
    }
}

@media (min-width: 992px) {
    .portfolio-det-area-4-bg-image {
        padding-top: 250px;
        padding-bottom: 250px;
    }
}

.project-details-item-4-items {
    margin-top: 70px;
}

.project-details-item-4-items .col-lg-3 {
    margin-bottom: 30px;
}

.project-details-item-4-items .content h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 13px;
}

.project-details-item-4-items .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.project-details-item-4-items .btn {
    min-width: 150px;
    height: 55px;
    background: #1787FC;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 25px;
}

@media (min-width: 768px) {
    .project-details-item-4-items .btn {
        margin-top: 30px;
    }
}

@media (min-width: 992px) {
    .project-details-item-4-items .btn {
        margin-top: 40px;
    }
}

.process-area-pro-det-4 {
    background: #F9FAFB;
    position: relative;
    z-index: 0;
    padding-bottom: 55px;
}

@media (min-width: 768px) {
    .process-area-pro-det-4 {
        padding-bottom: 75px;
    }
}

@media (min-width: 992px) {
    .process-area-pro-det-4 {
        padding-bottom: 125px;
    }
}

.process-area-pro-det-4 .content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #1D263A;
    margin-bottom: 22px;
}

.process-area-pro-det-4 .content p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          About Us 
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-about-us-area {
    padding-top: 115px;
}

@media (min-width: 480px) {
    .inner-about-us-area {
        padding-top: 120px;
    }
}

@media (min-width: 768px) {
    .inner-about-us-area {
        padding-top: 135px;
    }
}

@media (min-width: 992px) {
    .inner-about-us-area {
        padding-top: 170px;
    }
}

.inner-about-bg-area {
    position: relative;
    margin-top: 65px;
    border-radius: 30px;
}

.inner-about-bg-area .bg-image img {
    border-radius: 30px 30px 0px 0px;
}

@media (min-width: 992px) {
    .inner-about-bg-area .bg-image img {
        border-radius: 30px;
    }
}

.inner-about-bg-area .content {
    background: #FF794D;
    border-radius: 0px 0px 30px 30px;
    padding: 32px 45px 18px 40px;
}

@media (min-width: 992px) {
    .inner-about-bg-area .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%;
        border-radius: 0px 30px 0px 30px;
    }
}

@media (min-width: 1200px) {
    .inner-about-bg-area .content {
        width: 66%;
    }
}

@media (min-width: 1600px) {
    .inner-about-bg-area .content {
        width: 57%;
    }
}

.inner-about-bg-area .content p {
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.inner-ab-bg-bottom-content {
    margin-top: 60px;
    margin-bottom: -30px;
}

.inner-ab-bg-bottom-content .col-md-10 {
    margin-bottom: 30px;
}

.inner-ab-bg-bottom-content .icon-content-area .icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    text-align: center;
    background: rgba(214, 227, 235, 0.4);
    display: inline-table;
    margin-bottom: 30px;
}

.inner-ab-bg-bottom-content .icon-content-area .icon img {
    width: 32px;
    height: 32px;
}

.inner-ab-bg-bottom-content .icon-content-area .icon.icon-2 {
    background: rgba(250, 229, 218, 0.4);
}

@media (min-width: 768px) {
    .inner-ab-bg-bottom-content .icon-content-area .icon {
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .inner-ab-bg-bottom-content .icon-content-area .content {
        padding-right: 45px;
    }
}

@media (min-width: 1600px) {
    .inner-ab-bg-bottom-content .icon-content-area .content {
        padding-right: 135px;
    }
}

@media (min-width: 768px) {
    .inner-ab-bg-bottom-content .icon-content-area .content {
        margin-left: 35px;
    }
}

.inner-ab-bg-bottom-content .icon-content-area .content p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Counter area 
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.counter-area-inner-about-us {
    padding-top: 50px;
}

@media (min-width: 768px) {
    .counter-area-inner-about-us {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .counter-area-inner-about-us {
        padding-top: 110px;
    }
}

.counter-area-inner-about-us .counter-area-l8-items {
    background: #1787FC;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Team area 
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-area-team {
    background: #F9FAFB;
    padding-top: 50px;
}

@media (min-width: 768px) {
    .about-area-team {
        padding-top: 70px;
    }
}

@media (min-width: 992px) {
    .about-area-team {
        padding-top: 120px;
    }
}

.about-area-team-items {
    margin-top: 35px;
    margin-bottom: -25px;
}

@media (min-width: 768px) {
    .about-area-team-items {
        margin-top: 45px;
    }
}

@media (min-width: 992px) {
    .about-area-team-items {
        margin-top: 55px;
    }
}

.about-area-team-items .col-xl-3 {
    margin-bottom: 25px;
}

.about-area-team-items .team-card {
    position: relative;
}

.about-area-team-items .team-card .image img {
    border-radius: 15px;
}

.about-area-team-items .team-card .content {
    transition: all 0.4s ease-in-out;
    background: #FFFFFF;
    border-radius: 15px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - 30px);
    transform: translate(-50%, 100%);
    padding: 20px 20px 20px 20px;
}

.about-area-team-items .team-card .content h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 0;
}

.about-area-team-items .team-card .content span {
    display: inline-block;
    font-size: 13px;
    line-height: 22px;
    color: #1D263A;
    opacity: 0.6;
}

.about-area-team-items .team-card .content .social-icons {
    margin-top: 12px;
}

.about-area-team-items .team-card .content .social-icons ul {
    margin: 0px -8px;
}

.about-area-team-items .team-card .content .social-icons ul li {
    margin: 0px 8px;
}

.about-area-team-items .team-card .content .social-icons ul li i {
    font-size: 20px;
    color: #1D263A;
}

.about-area-team-items .team-card .content .social-icons ul li:hover i {
    color: #1787FC;
}

.about-area-team-items .team-card:hover .content {
    transform: translate(-50%, -11%);
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Career
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-career-banner {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 60px;
    position: relative;
}

@media (min-width: 480px) {
    .inner-career-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-career-banner {
        padding-top: 152px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-career-banner {
        padding-top: 200px;
        padding-bottom: 115px;
    }
}

.footer-middle-carrer {
    border-top: 2px dashed rgba(29, 36, 58, 0.17);
    margin-bottom: 60px;
}

@media (min-width: 768px) {
    .footer-middle-carrer {
        margin-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .footer-middle-carrer {
        margin-bottom: 100px;
    }
}

.inner-feature-area-2 {
    padding-bottom: 140px;
}

@media (min-width: 768px) {
    .inner-feature-area-2 {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .inner-feature-area-2 {
        padding-bottom: 110px;
    }
}

.inner-feature-area-2 .inner-feature-area-2-items {
    margin-bottom: -30px;
}

@media (min-width: 768px) {
    .inner-feature-area-2 .inner-feature-area-2-items {
        margin-bottom: -40px;
    }
}

@media (min-width: 992px) {
    .inner-feature-area-2 .inner-feature-area-2-items {
        margin-bottom: -55px;
    }
}

.inner-feature-area-2 .inner-feature-area-2-items .col-xl-4 {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .inner-feature-area-2 .inner-feature-area-2-items .col-xl-4 {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .inner-feature-area-2 .inner-feature-area-2-items .col-xl-4 {
        margin-bottom: 55px;
    }
}

.inner-feature-area-2 .widget .widget-icon {
    margin-bottom: 30px;
}

@media (min-width: 480px) {
    .inner-feature-area-2 .widget .widget-icon {
        margin-bottom: 0;
    }
}

.inner-feature-area-2 .widget .widget-icon i {
    margin-right: 35px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    color: #F04037;
    background-color: rgba(255, 75, 54, 0.15);
    font-size: 24px;
}

.inner-feature-area-2 .widget .widget-icon-2 i {
    color: #413E65 !important;
    background-color: rgba(65, 62, 101, 0.15) !important;
}

.inner-feature-area-2 .widget .widget-icon-3 i {
    color: #8E59FF !important;
    background-color: rgba(142, 89, 255, 0.15) !important;
}

.inner-feature-area-2 .widget .widget-icon-4 i {
    color: #F653A2 !important;
    background-color: rgba(246, 83, 162, 0.15) !important;
}

.inner-feature-area-2 .widget .widget-icon-5 i {
    color: #00BCD4 !important;
    background-color: rgba(0, 188, 212, 0.15) !important;
}

.inner-feature-area-2 .widget .widget-icon-6 i {
    background-color: rgba(129, 178, 20, 0.15) !important;
    color: #81B214 !important;
}

.inner-feature-area-2 .widget .widget-text {
    margin-top: -5px;
}

.inner-feature-area-2 .widget .widget-text h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #1D263A;
    margin-bottom: 18px;
}

.inner-feature-area-2 .widget .widget-text p {
    font-size: 15px;
    line-height: 28px;
    color: #1D263A;
    opacity: 0.8;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       Career Details
      <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-carrer-job-details {
    margin-top: 30px;
}

.inner-carrer-job-details ul {
    margin: 0px -15px -20px -15px;
}

.inner-carrer-job-details ul li {
    margin: 0px 15px 20px 15px;
}

.inner-carrer-job-details ul li i {
    font-size: 20px;
    color: #FF794D;
    margin-right: 12px;
}

.inner-carrer-job-details ul li span {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    display: inline-block;
}

.footer-border-cd {
    border-bottom: 1px solid rgba(29, 36, 58, 0.13);
}

.inner-career-details-area {
    padding-top: 25px;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .inner-career-details-area {
        padding-top: 45px;
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    .inner-career-details-area {
        padding-top: 65px;
        padding-bottom: 100px;
    }
}

.inner-career-details-area h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-bottom: 18px;
    margin-top: 35px;
}

.inner-career-details-area p {
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
    margin-bottom: 20px;
}

.inner-career-details-area .skills-qualification {
    margin-bottom: 50px;
}

.inner-career-details-area .skills-qualification ul {
    margin-bottom: -15px;
}

.inner-career-details-area .skills-qualification ul li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 30px;
    color: #1D263A;
    opacity: 0.8;
}

.inner-career-details-area .skills-qualification ul li i {
    font-size: 9px;
    color: #19191B;
    margin-right: 15px;
}

.inner-career-details-area .cd-apply-btn .btn {
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
    background: #FF794D;
    border-radius: 10px;
    min-width: 233px;
    height: 60px;
    margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      FAQ main Page
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.faq-main-banner {
    padding: 98px 0px 40px 0px;
}

@media (min-width: 480px) {
    .faq-main-banner {
        padding: 115px 0px 40px 0px;
    }
}

@media (min-width: 768px) {
    .faq-main-banner {
        padding: 130px 0px 60px 0px;
    }
}

@media (min-width: 992px) {
    .faq-main-banner {
        padding: 163px 0px 80px 0px;
    }
}

.faq-main-banner h2 {
    color: #0D152E;
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -1px;
    line-height: normal;
}

@media (min-width: 576px) {
    .faq-main-banner h2 {
        font-size: 40px;
    }
}

@media (min-width: 768px) {
    .faq-main-banner h2 {
        font-size: 48px;
    }
}

@media (min-width: 992px) {
    .faq-main-banner h2 {
        font-size: 60px;
    }
}

.faq-main-area {
    padding-bottom: 25px;
}

@media (min-width: 768px) {
    .faq-main-area {
        padding-bottom: 15px;
    }
}

@media (min-width: 992px) {
    .faq-main-area {
        padding-bottom: 45px;
    }
}

.faq-main-tab-area ul li a {
    text-decoration: none;
    color: #0D152E;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
    display: inline-block;
    margin-bottom: 23px;
}

.faq-main-tab-area ul li.active a {
    color: #1787FC;
    font-weight: 700;
}

.faq-single-all-items {
    margin-bottom: -60px;
    padding-top: 10px;
}

@media (min-width: 768px) {
    .faq-single-all-items {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .faq-single-all-items {
        padding-top: 0;
    }
}

.faq-main-area-single {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .faq-main-area-single {
        margin-bottom: 40px;
    }
}

@media (min-width: 992px) {
    .faq-main-area-single {
        margin-bottom: 60px;
    }
}

.faq-main-area-single h2 {
    color: #0D152E;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    line-height: normal;
    margin-bottom: 30px;
}

.faq-main-area-single .accordion li {
    position: relative;
    list-style: none;
    border-radius: 5px;
    border: 1px solid #EAECED;
    padding: 15px 20px 15px 15px;
    margin-bottom: 10px;
}

.faq-main-area-single .accordion li.active {
    background: #f8fafc;
}

.faq-main-area-single .accordion li p {
    display: none;
    padding: 20px 15px 0px 0px;
    color: #687497;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: 28px;
    margin-bottom: 0;
}

.faq-main-area-single .accordion a {
    width: 100%;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #0D152E;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    line-height: normal;
    position: relative;
    padding-right: 20px;
}

.faq-main-area-single .accordion li .active {
    color: #0D152E !important;
}

.faq-main-area-single .accordion .active:after {
    border-right: 2px solid #1787FC !important;
    border-bottom: 2px solid #1787FC !important;
}

.faq-main-area-single .accordion a:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid #1787FC;
    border-bottom: 2px solid #1787FC;
    position: absolute;
    right: 0px;
    content: " ";
    top: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
}

.faq-main-area-single .accordion a.active:after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transition: all 0.2s ease-in-out;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Terms & Conditions main Page
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-terms-banner {
    background: #F9FAFB;
    padding-top: 123px;
    padding-bottom: 10px;
    position: relative;
}

@media (min-width: 480px) {
    .inner-terms-banner {
        padding-top: 130px;
    }
}

@media (min-width: 768px) {
    .inner-terms-banner {
        padding-top: 152px;
        padding-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .inner-terms-banner {
        padding-top: 200px;
        padding-bottom: 60px;
    }
}

.terms-condition-area {
    background: #F9FAFB;
    padding-bottom: 35px;
}

@media (min-width: 768px) {
    .terms-condition-area {
        padding-bottom: 55px;
    }
}

@media (min-width: 992px) {
    .terms-condition-area {
        padding-bottom: 115px;
    }
}

.terms-condition-area p {
    font-size: 17px;
    line-height: 30px;
    color: #696871;
}

.terms-condition-area h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #1D263A;
    margin-top: 35px;
    margin-bottom: 17px;
}

.terms-condition-area .skills-qualification {
    margin-bottom: 50px;
}

.terms-condition-area .skills-qualification ul {
    margin-top: 35px;
    margin-bottom: -18px;
}

.terms-condition-area .skills-qualification ul li {
    margin-bottom: 18px;
    display: flex;
    font-size: 17px;
    line-height: 30px;
    color: #696871;
}

.terms-condition-area .skills-qualification ul li i {
    font-size: 9px;
    color: #19191B;
    margin-right: 15px;
}

.terms-condition-area .skills-qualification ul li span {
    display: inline-block;
    margin-top: -8px;
}

/* ---------------------------------
  -------> THEME CUSTOM STYLES 
  ---------------------------------
  */
.background-property {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.focus-reset:focus {
    box-shadow: none;
    outline: none;
}

.z-index-1 {
    z-index: 1;
}

@media (min-width: 1600px) {
    .off-right-xxl-1 {
        margin-right: 80px;
    }
}

@media (min-width: 480px) {
    .d-xs-flex {
        display: flex;
    }
}

@media (min-width: 480px) {
    .text-xs-start {
        text-align: start !important;
    }
}

@media (min-width: 480px) {
    .text-xs-center {
        text-align: center;
    }
}

.btn-style-01:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 0px;
    background: #213053;
    transition: all .5s;
    z-index: -1;
    opacity: 0;
}

.btn-style-01:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-01:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

/*Button Hover style 02*/
.btn-style-02:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 20px;
    background: #213053;
    transition: all .5s;
    opacity: 0;
    z-index: -1;
}

.btn-style-02:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-02:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

/*Button Hover style 03*/
.btn-style-03:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 10px;
    background: #213053;
    transition: all .5s;
    z-index: -1;
    opacity: 0;
}

.btn-style-03:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-03:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

/*Button Hover style 04*/
.btn-style-04:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 0px;
    background: #213053;
    transition: all .5s;
    z-index: -1;
    opacity: 0;
}

.btn-style-04:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-04:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

/*Button Hover style 05*/
.btn-style-05:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 3px;
    background: #213053;
    transition: all .5s;
    z-index: -1;
    opacity: 0;
}

.btn-style-05:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-05:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

/*Button Hover style 06*/
.btn-style-06 a span {
    transition: all 0.4s ease-in-out;
}

.btn-style-06 a:hover span {
    display: inline-block;
    margin-left: 5px;
}

/*Button Hover style 07*/
.btn-style-07:before {
    width: 0%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 3px;
    background: #2D947A;
    transition: all .5s;
    z-index: -1;
    opacity: 0;
}

.btn-style-07:hover {
    background: none !important;
    box-shadow: none !important;
}

.btn-style-07:hover:before {
    box-shadow: 0px;
    color: #fff;
    opacity: 1;
    width: 100%;
}

img.vertical-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

img.horizontal-move {
    -webkit-animation: route 2s infinite alternate;
    animation: route 2s infinite alternate;
}

@-webkit-keyframes route {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-20px);
    }
}

@keyframes route {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-20px);
    }
}

img.spin {
    animation: rotate 15s linear infinite;
    -webkit-animation: rotate 15s linear infinite;
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        -o-transform: rotate(359deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        -o-transform: rotate(359deg);
    }
}

.swing {
    -webkit-animation-name: swing;
    animation-name: swing;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    transform-origin: 100% 0%;
}

@-webkit-keyframes swing {
    0% {
        transform: rotate(30deg);
    }

    50% {
        transform: rotate(-60deg);
    }

    100% {
        transform: rotate(30deg);
    }
}

@keyframes swing {
    0% {
        transform: rotate(30deg);
    }

    50% {
        transform: rotate(-60deg);
    }

    100% {
        transform: rotate(30deg);
    }
}

.swing-2 {
    -webkit-animation-name: swing;
    animation-name: swing;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    transform-origin: 100% 0%;
}

@keyframes swing {
    0% {
        transform: rotate(20deg);
    }

    50% {
        transform: rotate(-40deg);
    }

    100% {
        transform: rotate(20deg);
    }
}

/*Image Hover style 01*/
.image-hover-style-01 {
    border-radius: 10px;
}

.image-hover-style-01 img {
    transition: all 0.4s ease-in-out;
}

.image-hover-style-01 img:hover {
    transform: scale(1.2);
    transition: all 1s ease-out;
    filter: brightness(0.7);
}

/*# sourceMappingURL=maps/main.css.map */

