.swiper-slide {
  z-index: 1001;
}

.swiper-pagination-bullet-active {
  background: #FCA61F !important;
  opacity: 1 !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  background: gray;
  opacity: 0.2;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 12px;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 0px;
  left: auto;
}

.testimonislider_button-wrapper {
  position: absolute !important;
  right: 0% !important;
  top: 0% !important;
  z-index: 10 !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100px !important;
}


.testimonislider_button-next, .testimonislider_button-prev {
  color: white !important;
  border-radius: 50% !important;
  padding: 15px !important;
  margin-right: 10px !important;
  background-color: #FCA61F !important;
}

.ukmppdslider_button-next, .ukmppdslider_button-prev {
  position: absolute !important;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 35% !important;
  min-width: 100px;
  padding: 30px;
  margin-right: 10px;
  background-color: #FCA61F !important;
}

.ukmppdslider_button-next, .swiper-container-rtl .ukmppdslider_button-prev {
  right: 0%;
  left: auto;
}

.ukmppdslider_button-prev, .swiper-container-rtl .ukmppdslider_button-next {
  left: 0%;
  right: auto;
}

.rocketslider_button-wrapper {
  position: absolute !important;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.rocketslider_button-next, .preklinikslider_button-prev {
  color: black;
  border-radius: 15% !important;
  padding: 15px;
  margin-right: 10px;
}

.swiper-slide-thumb-active {
  /* height: 200px; */
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  /* transform-origin: top center; */
}

/* .overflowswiper-container {
  overflow: visible !important;
} */

.ukmppdthumbs {
  align-items: center;
  height: 150%;
}

.ukmppdthumbs-container {
  overflow: visible !important;
}

.preklinikslider_button-wrapper {
  position: absolute !important;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.preklinikslider_button-next, .preklinikslider_button-prev {
  color: white;
  border-radius: 15% !important;
  padding: 15px;
  margin-right: 10px;
  background-color: #FCA61F !important;
}

/* .testimonislider_button-next, .swiper-container-rtl .testimonislider_button-prev {
  right: 0%;
  left: auto;
}

.testimonislider_button-prev, .swiper-container-rtl .testimonislider_button-next {
  left: 85%;
  right: auto;
} */

/* .testimonislider_button-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
} */