@media (max-width: 480px) {
    .light-version-logo {
        width: 25vh;
        padding: 2vh;
    }
}

@media (max-width: 380px) {
    .light-version-logo {
        width: 20vh;
        padding: 1vh;
    }
}

/* @media (max-width: 768px) {
    .header-btns {
        width: 97px;
    }
}

@media (max-width: 480px) {
    .header-btns {
        width: 105px;
    }
}

@media (max-width: 380px) {
    .header-btns {
        width: 102px;
    }
} */

@media (max-width: 768px) {
    .signup {
        position: relative;
        font-size: 14px;
        top: 25px;
        margin-left: -70%;
        font-weight: 700;
        color: black;
    }
}

@media (max-width: 480px) {
    .signup {
        position: relative;
        font-size: 14px;
        top: 25px;
        margin-left: -74%;
        font-weight: 700;
        color: black;
    }
}

@media (max-width: 380px) {
    .signup {
        position: relative;
        font-size: 14px;
        top: 25px;
        margin-left: -75%;
        font-weight: 700;
        color: black;
    }
}
