/*!
=========================================================
* Material Tailwind Dashboard React - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-tailwind-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-tailwind-dashboard-react/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.line-divider {
  margin-top: 10px;
  font-family: 'Quicksand';
  color: #999;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}

.line-divider:before {
  content: "";
  display: block;
  border-top: solid 1px #999;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 10px;
  z-index: 1;
}

.line-divider .span-line-divider {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}
