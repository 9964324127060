.table-responsive {
    /* border: 1.5px solid #BDC1C9 !important; */
    border-top: 1.9px solid #BDC1C9 !important;
    border-left: 1.9px solid #BDC1C9 !important;
    border-right: 1.9px solid #BDC1C9 !important;
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* border-radius: 15px; */
}
.main-table {
    border-radius: 15px !important;
    /* border: 1px solid #BDC1C9 !important; */
}
.main-table__head {
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #6B7280;
    background-color: #f3f4f6;
    border: 1.5px solid #BDC1C9;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.main-table__headRow {
    padding: 15px !important;
}

.main-table__bodyRow {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #000000;
    padding: 15px !important;
}

.border-side__tbodyRow {
    border-left: 1.9px solid #BDC1C9 !important;
    border-right: 1.9px solid #BDC1C9 !important;
}

.paginationTools {
    font-size: 1.1rem;
    padding: 0px 12px 12px 12px;
    border-left: 1.9px solid #BDC1C9 !important;
    border-right: 1.9px solid #BDC1C9 !important;
    border-bottom: 1.9px solid #BDC1C9 !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #6B7280;
}